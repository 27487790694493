// manage-reporting-areas store

export default {
	state: {
		selectedVue: 'list',
		areaData: null,
		hasLinkableAreas: true,
		messages: {},
		pagination: {},
		tools: {}
	},

	getters: {
	    selectedVue(state) {
			return state.selectedVue;
	    },
	    areaData(state) {
			return state.areaData;
	    },
	    hasLinkableAreas(state) {
	    	return state.hasLinkableAreas;
	    },
	    messages(state) {
	    	return state.messages;
	    },
	    pagination(state) {
	    	return state.pagination;
	    },
	    tools(state) {
	    	return state.tools;
	    }
	},

	actions: {
	},

	mutations: {
		updateSelectedVue(state, payload) {
			state.selectedVue = payload.selectedVue,
			state.areaData = payload.areaData
		},
		updateAreaData(state, payload) {
			state.areaData = payload.areaData;
		},
		updateHasLinkableAreas(state, payload) {
			state.hasLinkableAreas = payload.hasLinkableAreas;
		},
		updateMessages(state, payload) {
			state.messages = payload.messages;
		},
		updateAlerts(state, payload) {
			if (typeof state.messages === 'undefined') {
				state.messages = {messages: { alerts: payload.alerts}}
				return;
			}
			state.messages.alerts = payload.alerts;
		},
		updatePagination(state, payload) {
			state.pagination = payload.pagination;
		},
		updateTools(state, payload) {
			state.tools = payload.tools;
		},
		updateAreaGroupCount(state, payload) {
			state.areaData.group_item_count = payload.count;
			return state.areaData.group_item_count;
		},
	}
}
