<template>
    <span>
        <div class="card card-settings" v-if="tokens.length > 0">
            <div class="card-header">Current API Tokens</div>

            <div class="table-responsive">
                <table class="table table-valign-middle mb-0">
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Created</th>
                            <th>Last Used</th>
                            <th>&nbsp;</th>
                        </tr>
                    </thead>

                    <tbody>
                    <tr v-for="token in tokens">
                        <!-- Name -->
                        <td>
                            <div>
                                {{ token.name }}
                            </div>
                        </td>

                        <!-- Created At -->
                        <td>
                            <div>
                                {{ $filters.datetime(token.created_at)}}
                            </div>
                        </td>

                        <!-- Last Used At -->
                        <td>
                            <div>
                                    <span v-if="token.last_used_at">
                                        {{ $filters.datetime(token.last_used_at) }}
                                    </span>

                                    <span v-else>
                                        Never
                                    </span>
                            </div>
                        </td>

                        <!-- Edit Button -->
                        <td class="td-fit">
                            <div class="text-right ">
                                <button class="btn btn-outline-primary" @click="editToken(token)">
                                    <i class="fa fa-cog"></i>
                                </button>

                                <button class="btn btn-outline-danger" @click="approveTokenDelete(token)">
                                    <i class="fa fa-trash"></i>
                                </button>
                            </div>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <!-- Update Token Modal -->
        <div class="modal" id="modal-update-token" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-md" v-if="updatingToken">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Edit Token ({{ updatingToken.name }})
                        </h5>
                    </div>

                    <div class="modal-body">
                        <!-- Update Token Form -->
                        <form role="form">
                            <!-- Token Name -->
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">Token Name</label>

                                <div class="col-md-6">
                                    <input type="text" class="form-control" name="tokenName" v-model="updateTokenForm.tokenName" :class="{'is-invalid': updateTokenForm.errors.has('name')}">

                                    <span class="invalid-feedback" v-show="updateTokenForm.errors.has('name')">
                                        {{ updateTokenForm.errors.get('name') }}
                                    </span>
                                </div>
                            </div>

                            <!-- Token Abilities -->
                            <div class="form-group row" v-if="availableAbilities.length > 0">
                                <label class="col-md-4 col-form-label text-md-right">Token Can</label>

                                <div class="col-md-6">
                                    <div v-for="ability in availableAbilities">
                                        <div class="checkbox">
                                            <label>
                                                <input type="checkbox"
                                                @click="toggleAbility(ability.value)"
                                                :checked="abilityIsAssigned(ability.value)">

                                                {{ ability.name }}
                                            </label>
                                        </div>
                                    </div>

                                    <span class="invalid-feedback" v-show="updateTokenForm.errors.has('abilities')">
                                        {{ updateTokenForm.errors.get('abilities') }}
                                    </span>
                                </div>
                            </div>
                        </form>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>

                        <button type="button" class="btn btn-primary" @click="updateToken" :disabled="updateTokenForm.busy">
                        Update
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Token Modal -->
        <div class="modal" id="modal-delete-token" tabindex="-1" role="dialog">
            <div class="modal-dialog" v-if="deletingToken">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            Delete Token ({{ deletingToken.name }})
                        </h5>
                    </div>

                    <div class="modal-body">
                        Are you sure you want to delete this token? If deleted, API requests that attempt to authenticate using this token will no longer be accepted.
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" data-dismiss="modal">No, Go Back</button>

                        <button type="button" class="btn btn-danger" @click="deleteToken" :disabled="deleteTokenForm.busy">
                        Yes, Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>
<script>
export default {
    props: {
      urbicsUser: { Object, required: true }
    },
    data: function() {
        return {
            updatingToken: null,
            deletingToken: null,

            updateTokenForm: new UrbicsForm({
                tokenName: '',
                abilities: []
            }),

            deleteTokenForm: new UrbicsForm({})
        }
    },

    mounted() {
        axios.get('settings/api/tokens')
        .then(response => {
            this.$store.commit('updateTokens', {tokens: response.data.tokens, abilities: response.data.abilities})
        })
        .catch(function (error) {
            if (process.env.NODE_ENV !== 'production') {            
                console.log(error.message)
            }
        });
    },

    computed: {
        tokens() {
            return this.$store.getters.userTokens
        },
        availableAbilities() {
            return this.$store.getters.userAbilities
        }
    },

    methods: {
        /**
         * Show the edit token modal.
         */
        editToken(token) {
            this.updatingToken = JSON.parse(JSON.stringify(token));

            this.initializeUpdateFormWith(this.updatingToken);

            $('#modal-update-token').modal('show');
        },


        /**
         * Initialize the edit form with the given token.
         */
        initializeUpdateFormWith(token) {
            this.updateTokenForm.tokenName = token.name;

            this.updateTokenForm.abilities = token.abilities;
        },


        /**
         * Update the token being edited.
         */
        updateToken() {
            axios.put(`/settings/api/token/${this.updatingToken.id}`, this.updateTokenForm)
            .then(() => {
                this.$store.dispatch('refreshTokens')
            })
            .catch(function (error) {
                if (process.env.NODE_ENV !== 'production') {            
                    console.log(error.message)
                }
            });

            $('#modal-update-token').modal('hide');
        },


        /**
         * Toggle the ability on the current token being edited.
         */
        toggleAbility(ability) {
            if (this.abilityIsAssigned(ability)) {
                this.updateTokenForm.abilities = _.reject(
                    this.updateTokenForm.abilities, a => a == ability
                );
            } else {
                this.updateTokenForm.abilities.push(ability);
            }
        },


        /**
         * Determine if the ability has been assigned to the token being edited.
         */
        abilityIsAssigned(ability) {
            return _.includes(this.updateTokenForm.abilities, ability);
        },


        /**
         * Get user confirmation that the token should be deleted.
         */
        approveTokenDelete(token) {
            this.deletingToken = token;

            $('#modal-delete-token').modal('show');
        },


        /**
         * Delete the specified token.
         */
        deleteToken() {
            axios.delete(`/settings/api/token/${this.deletingToken.id}`, this.deleteTokenForm)
            .then(() => {
                this.$store.dispatch('refreshTokens')
            })
            .catch(function (error) {
                if (process.env.NODE_ENV !== 'production') {            
                    console.log(error.message)
                }
            })
            $('#modal-delete-token').modal('hide')
        }
    }
}
</script>