<template>
    <div class="card card-settings">
        <div class="card-header">Security Settings</div>
        <div class="card-body">
            <div class="settings-container">
                <div class="row card-item" v-if=" hasStatusMessage ">
                    <div class="alert alert-success" role="alert">
                        {{ statusMessage }}
                    </div>
                </div>
                <div class="row justify-content-evenly card-item-group-header">
                    <div class="offset-1 col-2 text-md-left">Password Reset</div>
                </div>
                <div class="row justify-content-evenly card-item">
                    <div class="offset-1 col-3 text-md-right">
                        <button 
                            type="button" 
                            class="btn btn-outline-primary"
                            @click="sendResetPasswordEmail"
                        >Send Password Reset Link</button>
                    </div>
                    <div class="col-8 text-md-left">An email will be sent to {{ urbicsUser.userEmail }} with instructions on resetting your password.</div>
                </div>
                <div class="row justify-content-evenly card-item">
                    <div class="col-4 text-md-right">Contact Name</div>
                    <div class="col-4 text-md-left">{{ urbicsUser.userName }}</div>
                </div>
                <div class="row justify-content-evenly card-item">
                    <div class="col-4 text-md-right">Login ID</div>
                    <div class="col-4 text-md-left">{{ urbicsUser.loginID }}</div>
                </div>
                <div class="row justify-content-evenly card-item">
                    <div class="col-4 text-md-right">Organization</div>
                    <div class="col-4 text-md-left">{{ urbicsUser.organization }}</div>
                </div>
                <div class="row justify-content-evenly card-item">
                    <div class="col-4 text-md-right">Subscription Type</div>
                    <div class="col-4 text-md-left">{{ urbicsUser.subscriptionType }}</div>
                </div>
                <div class="row justify-content-evenly card-item">
                    <div class="col-4 text-md-right">Subscription Renews On</div>
                    <div class="col-4 text-md-left">{{ urbicsUser.renewalDate }}</div>
                </div>
                <div class="row justify-content-evenly card-item">
                    <div class="col-4 text-md-right">Subscriber Since</div>
                    <div class="col-4 text-md-left">{{ urbicsUser.startDate }}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            urbicsUser: { Object, required: true },
        },
        data: function() {
            return {
                statusMessage: '',
                form: new UrbicsForm({
                    current_password: '',
                    password: '',
                    password_confirmation: ''
                }),
            }
        },
        computed: {
            hasStatusMessage() {
                return this.statusMessage.length > 0;
            },
        },
        methods: {
            sendResetPasswordEmail() {
                var self = this;

                axios.post('/password/email', {
                    email: this.urbicsUser.userEmail,
                })
                .then(response => {
                    this.statusMessage = response.data.message;
                })
                .catch (response => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        this.errorMessage += ' The error message is: ' + response.data;
                    }
                    this.statusMessage = "Unable to send a password reset link to " 
                        +  this.urbicsUser.userEmail + ".";
                });
            },
            // update() {
            //     Urbics.post('/settings/update-password', this.form);
            // },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Profile component mounted.')
            }
        },
    }
</script>


