<template>
    <th class="center">
    <i name="idx[]" value="all" id="list_batch_toggle" aria-hidden="true" aria-label="Select all" v-on:click="toggleStatus" v-bind:class="[iconSize, iconName, iconColor]"></i>
    </th>
</template>
<script>
    export default {
        data() {
            return {
                toggle: false
            }
        },
        methods: {
            toggleStatus() {
                this.toggle = !this.toggle;
                this.$store.commit({
                    type: 'toggleSelectAll', 
                    checked: this.toggle
                });
            }
        },
        created() {
        },
        computed: {
            iconColor() {
                if(this.toggle == true) {
                    return 'text-primary';
                }
                else {
                    return 'text-muted';
                }

            },
            iconSize() {
                if(this.toggle == true) {
                    if (this.noChildrenChecked) {
                        return 'fal fa-lg';
                    }
                    return 'fas fa-lg';
                }
                else {
                    return 'fal fa-lg';
                }

            },
            iconName() {
                if(this.toggle == true) {
                    if (this.someChildrenChecked) {
                        return 'fa-minus-square';
                    } else if (this.noChildrenChecked) {
                        return 'fa-square';
                    } else {
                        return 'fa-check-square';
                    }
                }
                else {
                    if (this.someChildrenChecked) {
                        return 'fa-minus-square';
                    } else if (this.allChildrenChecked) {
                        return 'fa-check-square';
                    } else {
                        return 'fa-square';
                    }
                }
            },
            someChildrenChecked() {
                return this.$store.getters.someChildrenChecked;
            },
            allChildrenChecked() {
                return this.$store.getters.allChildrenChecked;
            },
            noChildrenChecked() {
                return this.$store.getters.noChildrenChecked;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('checkbox-toggle mounted.')
            }
        }
    }
</script>
