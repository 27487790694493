// alert-handler store
// Alert object structure:
// {alerts: {alertName: {status: 'status', message: 'message'}}}

export default {
	namespaced: false,
	state: {
		alerts: {},
	},

	getters: {
	    alerts(state) {
	    	return state.alerts;
	    },
	    hasAlerts(state) {
	    	return Object.keys(state.alerts).length > 0;
	    },
	    getStatusById: (state) => (id) => {
	    	if (typeof state.alerts[id] !== 'undefined') {
	    		if (typeof state.alerts[id].status !== 'undefined') {
	    			return state.alerts[id].status;
	    		}
	    		return null;
	    	}
	    	return null;
	    },
	    getFirstStatus(state) {
	    	if (Object.keys(state.alerts).length > 0) {
	    		return state.alerts[Object.keys(state.alerts)[0]].status
	    	}
	    	return null;
	    },
	    getAlertById: (state) => (id) => {
	    	if (typeof state.alerts[id] !== 'undefined') {
	    		if (typeof state.alerts[id].message !== 'undefined') {
	    			return state.alerts[id].message;
	    		}
	    		return null;
	    	}
	    	return null;
	    }
	},

	actions: {
	},

	mutations: {
		updateAlerts(state, payload) {
			if (typeof payload.message === 'undefined' 
				|| Object.keys(payload.message).length == 0) {
				return;
			}
			Object.assign(state.alerts, state.alerts, payload.message)
		},
	},
}
