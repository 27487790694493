<template>
    <div v-if="displayList.length > 0" class="row">
        <ul class="report-list list-unstyled col-12">
            <template v-for="(item, index) in displayList">
                <li v-if="item.type == 'county'" class="report-group report-item">{{ item.name }}</li>
                <li v-else-if="item.type == 'group'" class="report-sub-group report-item ml-2">{{ item.name }}</li>
                <li v-else class="report-item">
                    <a :href="url(item.id)">{{ item.name }}</a>
                </li>
            </template>
        </ul>
    </div>
</template>
<script>
    import {handleError} from '../../../mixins/handle-error'
    export default {
        name: 'RecentAreas',
        props: {
            user: { Object, required: true },
        },
        data: function() {
            return {
                displayList: [],
            }
        },
        created() {
        },
        watch: {
        },
        methods: {
            listRecentAreas() {
                axios.post('/quick/recent-areas', {
                    limit: 10,
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.displayAlert({
                            message: "Nothing was found.",
                            status: "info"
                        })
                        return;
                    } 
                    return this.formatDisplayList(response.data);
                })
                .catch(error => {
                    this.displayAlert({
                        message: error.message,
                    });
                });
            },
            formatDisplayList(rawData) {
                this.displayList = [];
                let county = 'XXX', type = 'YYY';
                for (let i = 0; i < rawData.length; i++) {
                    if ((rawData[i].county_name_full.length > 0) 
                            && (rawData[i].county_name_full != county)) {
                        this.displayList.push({
                            name: rawData[i].county_name_full,
                            type: 'county',
                            id: 0
                        });
                        county = rawData[i].county_name_full;
                    }
                    if ((rawData[i].group_name.length > 0) 
                            && (rawData[i].group_name != type)) {
                        this.displayList.push({
                            name: rawData[i].group_name, 
                            type: 'group',
                            id: 0
                        });
                        type = rawData[i].group_name;
                    }
                    this.displayList.push({
                        name: rawData[i].area_name_full,
                        type: 'area',
                        id: rawData[i].pareaid,
                    });
                }
            },            
            url(id) {
                return "/reports/base-page/?pareaid=" + id;
            },
            displayAlert(error) {
                this.alert = handleError({
                    status: (error.status ? error.status :'warning'), 
                    message: 'An error occurred. '
                        + (error.message ? error.message : ''), 
                    detail: ((error.response 
                            && error.response.error 
                            && error.response.error.message) 
                        ? error.response.error.message : ''),
                    key: (error.key ? error.key : 'urbicsError'),
                    response: error.response,
                    request: error.request,
                });
            },
        },
        computed: {
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
            this.listRecentAreas();
        }
    }
</script>


