<template>
    <div id="welcome" class="container-fluid intro pricing">
        <div class="row container-pricing pricing-cards">
            <div class="row">
                <div class="offset-3 col-6">
                    <div class="pricing-card shadow border-secondary h-100">
                        <div class="card-body">
                            <div class="top-half">
                                <h1>Purchase {{reportType}}</h1>
                                <h1>Reports For</h1>
                                <h1>{{fullAreaName}}</h1>
                                <div class="price-quote">
                                <h4>30-day Access: {{reportPrice}}</h4>
                                </div>
                            </div>
                            <div class="bottom-half billing-plan">
                                <ul class="feature-list fa-ul">
                                    <li>
                                        This provides access to the following primary reports for the area: 
                                    </li>
                                    <li>
                                        <ul id="report-prices" class="feature-list">
                                            <span v-for="item in availableReports">
                                                <li v-if="item.visible == 1">
                                                {{ item.name}}
                                                </li>
                                            </span>
                                        </ul>
                                    </li>
                                    <li>
                                        And you can drill down to these reports:
                                    </li>
                                    <li>
                                        <ul id="report-prices" class="feature-list">
                                            <span v-for="item in availableReports">
                                                <li v-if="item.visible == 0">
                                                {{ item.name}}
                                                </li>
                                            </span>
                                        </ul>
                                    </li>
                                    <li>
                                        All are available to download in PDF, Excel, text and JSON formats, and most provide access to up to ten years of historical data.
                                    </li>
                                </ul>
                            </div>
                            <div>
                                <h5>Enter your card details.</h5>
                                <h5>Your access will start now and will end in 30 days.</h5>
                                <p>Have a billing code or note for this purchase?</p>
                                <textarea 
                                    v-model="message" 
                                    class="col-12"
                                    placeholder="Information will be included on the invoice"
                                ></textarea>
                                <div class="row">
                                    <div class="col-12 submit-button">
                                    <button @click="submit" class="btn btn-success btn-lg"
                                        data-secret="intent.client_secret"
                                    >
                                        <span v-if="processing">
                                            <i class="fal fa-lg fa-spinner"></i>
                                        </span>
                                        <span v-else>Subscribe</span>
                                    </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>
<script>
    export default {
        props: {
            data: {Object, required: true},
        },
        data() {
            return {
                plan: this.data.plan,
                alerts: this.data.alerts,
                price: 0,
                price_id: '',
                products: this.data.products,
                reports: this.data.products.items,
                user: this.data.user,
                customer: this.data.customer,
                session: this.data.session,
                setup_intent: null,
                stripe_key: process.env.MIX_STRIPE_KEY,
                stripe: Stripe(process.env.MIX_STRIPE_KEY),
                processing: false,
                showPriceDetail: false,
                message: '',
            }
        },
        created() {
        },
        computed: {
            item() {
                return this.reports[this.plan];
            },
            reportType() {
                return this.item.display_name;
            },
            reportPrice() {
                this.price = this.item.price[this.plan].amount;
                this.price_id = this.item.price[this.plan].price_id;
                return this.formatCurrency(this.price);
            },
            reportPrices() {
                return this.products.items;
            },
            availableReports() {
                return this.item.price[this.plan].report_package;
            },
            areaID() {
                if (this.user.parentAreaId != 0) {
                    return this.user.parentAreaId;
                }
                return this.user.areaId;
            },
            fullAreaName() {
                if (this.user.parentAreaName.length == 0) {
                    return this.user.areaName + " (" + this.user.countyName + " County, " + this.user.stateAbbr + ")";
                }
                return this.user.parentAreaName + " (" + this.user.countyName + " County, " + this.user.stateAbbr + ")";
            }
        },
        methods: {
            formatCurrency($value) {
                return '$' + Number($value/100).toFixed(0).toLocaleString();
            },
            togglePriceDetail() {
                this.showPriceDetail = !this.showPriceDetail;
            },
            displayErrors(message) {
                let displayError = document.getElementById('stripe-element-errors');
                if (message) {
                    displayError.textContent = message;
                } else {
                    displayError.textContent = '';
                }
            },
            submit() {
                this.processing = true;
                this.stripe.redirectToCheckout({
                    sessionId: this.session.id
                }).then(function(result){})
            },
            tokenCreated (token) {
                this.processing = true;
                let result = this.stripe.createPaymentMethod({
                    type: 'card',
                    card: {token: token.id},
                    billing_details: {
                        name: this.customer.name,
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.displayErrors(result.error.message);
                    } else {
                        axios.post('/subscription/create', {
                            name: this.plan,
                            message: this.message,
                            areaText: this.fullAreaName,
                            areaID: this.areaID,
                            custID: this.customer.id,
                            paymentMethodId: result.paymentMethod.id,
                            priceId: this.price_id
                        })
                        .then(response => {
                            window.location.replace('/home');
                        })
                        .catch(error => {
                            if (error.response) {
                                // Request made and server responded
                                console.log(error.response.data);
                                console.log(error.response.status);
                                console.log(error.response.headers);
                            } else if (error.request) {
                                // The request was made but no response was received
                                console.log(error.request);
                            } else {
                                // Something happened in setting up the request that triggered an Error
                                console.log('Error', error.message);
                            }
                        });
                    }
                })
                this.processing = false;
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>