<template>
      <ul class="nav navbar-nav list-group-horizontal">
        <li class="dropdown" v-for="(tool, key) in tools.tools"> <span v-html="tool"></span></li>
      </ul>
</template>
<script>
    export default {
        props: {
            tools: Object,
        },
        data() {
            return {
            }
        },
        created() {
        },
        computed: {
            computedTemplate() {
                return null;
            },
        },
        methods: {
            methodTemplate() {
                return;
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('toolbar-tools component mounted.')
            }
        }
    }
</script>


