<template>
    <div class="row">
        <div class="col-md-12">
            <ul id="noDataFoundAlert" class="alert alert-info list-unstyled">
                <li>{{ message }}</li>
                <li :if="showLink"><a :href="linkPath">{{ linkText }}</a></li>
            </ul>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            data: Object,
        },
        data() {
            return {
                linkPath: this.data.linkPath,
                message: this.data.message,
                linkText: this.data.linkText,
            }
        },
        created() {
        },
        computed: {
            showLink() {
                return this.linkPath.length === false;
            },
        },
        methods: {
            methodTemplate() {
                return;
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('no-data-message component mounted.')
            }
        }
    }
</script>


