<template>
    <div class="card card-settings">
        <div class="card-header">Preferences</div>
        <div class="row justify-content-evenly card-item-group-header">
            <div class="col-4 text-md-center">Notification Preferences</div>
        </div>
        <div class="row justify-content-evenly card-item">
            <div class="col-8 text-md-center">
                <i 
                    class="card-item-icon far" 
                    v-bind:class="classNotifyAllPostings"
                    v-on:click="toggleNotifyAllPostings"
                ></i>Notify me when new data for any area in my portfolio is posted
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            urbicsUser: { Object, required: true },
        },
        data: function() {
            return {
                uncheckedIcon: "fa-square",
                checkedIcon: "fa-check-square",
                notifyAllPostings: false,
                notifyTaxPostings: false,
            }
        },
        computed: {
            classNotifyAllPostings() {
                return this.notifyAllPostings ? this.checkedIcon : this.uncheckedIcon;
            }
        },
        methods: {
            toggleNotifyAllPostings() {
                this.notifyAllPostings = !this.notifyAllPostings;
            },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Preferences component mounted.')
            }
        }
    }
</script>


