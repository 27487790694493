<template>
    <div>
        <div class="row">
            <div class="col-12 hier-list-h1">
                <span>{{ resultLabel }}</span>
            </div>
        </div>
        <div class="row">
            <template v-for="col in columnIndex">
                <ul class="hier-list col-4">
                    <template v-for="(item, index) in displayList[col]">
                        <li v-if="item.pareaid.length == 0" class="" :class="item.class">
                            {{ item.name }}
                        </li>
                        <li v-else class="" :class="item.class">
                            <a :href="url(item.pareaid)">{{ item.name }}</a>
                        </li>
                    </template>
                </ul>
            </template>
        </div>
    </div>
</template>
<script>
    import {handleError} from '../../../mixins/handle-error'
    import {formatAreaList} from '../../../mixins/format-area-list'
    export default {
        name: 'PortfolioAreas',
        props: {
            user: { Object, required: true },
        },
        data: function() {
            return {
                maxItems: 100,
                searchLimit: 100,
                displayList: [],
                displayColumns: 3,
                rawData: [],
            }
        },
        created() {
        },
        watch: {
        },
        methods: {
            listPortfolioAreas() {
                axios.post('/areas/portfolio-areas', {
                    limit: this.searchLimit,
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.displayAlert({
                            message: "Nothing was found.",
                            status: "info"
                        })
                        return;
                    } 
                    this.rawData = response.data;
                    this.displayList = formatAreaList(response.data, this.displayColumns);
                })
                .catch(error => {
                    this.displayAlert({
                        message: error.message,
                    });
                });
            },

            url(id) {
                return "/reports/base-page/?pareaid=" + id;
            },
            displayAlert(error) {
                this.alert = handleError({
                    status: (error.status ? error.status :'warning'), 
                    message: 'An error occurred. '
                        + (error.message ? error.message : ''), 
                    detail: ((error.response 
                            && error.response.error 
                            && error.response.error.message) 
                        ? error.response.error.message : ''),
                    key: (error.key ? error.key : 'urbicsError'),
                    response: error.response,
                    request: error.request,
                });
            },
        },
        computed: {
            resultLabel() {
                if (this.rawData.length == this.searchLimit) {
                    return "Portfolio Areas (limited to " + this.searchLimit + " records):";
                }
                return "Portfolio Areas:";
            },
            columnIndex() {
                let cols = [];
                for (let i = 0; i < this.displayColumns; i++) {
                    cols.push(i);
                }
                return cols;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Portfolio-area component mounted.')
            }
            this.listPortfolioAreas();
        }
    }
</script>


