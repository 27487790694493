<template>
    <div class="section-content">
        <div class="row section-content report-table">
            <div class="col-md-12 table-responsive">
                <table class="table report-table">
                    <thead>
                        <tr>
                            <th class="width-45">Linkable Parcels</th>
                            <th class="width-10 no-bottom-line"><br/></th>
                            <th class="width-45">Your Area</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="top-align">
                                <table class="table nested-table report-table">
                                    <thead>
                                        <tr>
                                            <th class="width-40 text-center">Parcels</th>
                                            <th class="width-20 text-center">Linked APNs</th>
                                            <th class="width-20 text-center">Link/Unlink</th>
                                            <th class="width-20 text-center">Detail</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                        <td colspan="4" class="pad-left report-sub-group">
                                            APN Books
                                        </td>
                                    </tr>
                                    <template v-for="books, bookIndex in apnBooks">
                                        <tr v-if="bookIndex == openedBook || openedBook == -1">
                                            <td class="pad-left-more">
                                                {{ apnBooks[bookIndex].apn_book }}
                                            </td>
                                            <td class="pad-left-more">
                                                {{ apnBookDisplay(bookIndex) }}
                                            </td>
                                            <td class="text-center">
                                                <a 
                                                    href="javascript:void(0)" 
                                                    class="pad-left text-center"
                                                    aria-label="unlink"
                                                >
                                                    <i 
                                                        v-if="books.linked_apn_count == books.apn_count"
                                                        id="unlinkApnBook" 
                                                        class="fal fa-lg fa-unlink text-danger"
                                                        title="Unlink"
                                                        v-on:click="() => unlinkApnBook(bookIndex)"
                                                    ></i>
                                                    <i 
                                                        v-else
                                                        id="linkApnBook" 
                                                        class="fal fa-lg fa-link text-success"
                                                        title="Link"
                                                        v-on:click="() => linkApnBook(bookIndex)"
                                                    ></i>
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                <a 
                                                    href="javascript:void(0)" 
                                                    class="pad-left text-center" 
                                                    aria-label="Open or Close"
                                                >
                                                    <i 
                                                        v-if="openedBook == bookIndex"
                                                        id="closeApnBook" 
                                                        class="fal fa-2x fa-angle-double-up text-primary"
                                                        title="Close"
                                                        v-on:click="() => closeApnBook(bookIndex)"
                                                    ></i>
                                                    <i 
                                                        v-else
                                                        id="openApnBook" 
                                                        class="fal fa-2x fa-angle-double-down text-primary"
                                                        title="Open"
                                                        v-on:click="() => openApnBook(bookIndex)"
                                                    ></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <template v-if="bookIndex == openedBook">
                                            <tr>
                                                <td colspan="4" class="pad-left-more report-sub-group">
                                                    APN Pages
                                                </td>
                                            </tr>
                                            <template v-for="pages, pageIndex in apnPages">
                                                <tr v-if="pageIndex == openedPage || openedPage == -1">
                                                    <td class="pad-left-more">
                                                        {{ apnPages[pageIndex].apn_page }}
                                                    </td>
                                                    <td class="pad-left-more">
                                                        {{ apnPageDisplay(pageIndex) }}
                                                    </td>
                                                    <td class="text-center">
                                                        <a 
                                                            href="javascript:void(0)" 
                                                            class="pad-left text-center"
                                                            aria-label="unlink"
                                                        >
                                                            <i 
                                                                v-if="pages.linked_apn_count == pages.apn_count"
                                                                id="unlinkApnPage" 
                                                                class="fal fa-lg fa-unlink text-danger"
                                                                title="Unlink"
                                                                v-on:click="() => unlinkApnPage(pageIndex)"
                                                            ></i>
                                                            <i 
                                                                v-else
                                                                id="linkApnPage" 
                                                                class="fal fa-lg fa-link text-success"
                                                                title="Link"
                                                                v-on:click="() => linkApnPage(pageIndex)"
                                                            ></i>
                                                        </a>
                                                    </td>
                                                    <td class="text-center">
                                                        <a 
                                                            href="javascript:void(0)" 
                                                            class="pad-left text-center" 
                                                            aria-label="Open or Close"
                                                        >
                                                            <i 
                                                                v-if="openedPage == pageIndex"
                                                                id="closeApnPage" 
                                                                class="fal fa-2x fa-angle-double-up text-primary"
                                                                title="Close"
                                                                v-on:click="() => closeApnPage(pageIndex)"
                                                            ></i>
                                                            <i 
                                                                v-else
                                                                id="openApnPage" 
                                                                class="fal fa-2x fa-angle-double-down text-primary"
                                                                title="Open"
                                                                v-on:click="() => openApnPage(pageIndex)"
                                                            ></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <template v-if="pageIndex == openedPage">
                                                    <tr>
                                                        <td colspan="4" class="pad-left-more report-sub-group">
                                                            Parcel Numbers
                                                        </td>
                                                    </tr>
                                                    <template v-for="apns, apnIndex in apnDetail">
                                                        <tr v-if="apns.linked_apn == 0">
                                                            <td class="pad-left-triple">
                                                                {{ apnDetail[apnIndex].apn }}
                                                            </td>
                                                            <td>
                                                            </td>
                                                            <td class="text-center">
                                                                <a 
                                                                    href="javascript:void(0)" 
                                                                    class="pad-left text-center"
                                                                    aria-label="unlink"
                                                                >
                                                                    <i 
                                                                        v-if="apns.linked_apn == 1"
                                                                        id="unlinkApn" 
                                                                        class="fal fa-lg fa-unlink text-danger"
                                                                        title="Unlink"
                                                                        v-on:click="() => unlinkApn(apnIndex)"
                                                                    ></i>
                                                                    <i 
                                                                        v-else
                                                                        id="linkApn" 
                                                                        class="fal fa-lg fa-link text-success"
                                                                        title="Link"
                                                                        v-on:click="() => linkApn(apnIndex)"
                                                                    ></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </table>
                            </td>
                            <td>
                            </td>
                            <td class="top-align">
                                <table class="table report-table">
                                    <thead>
                                        <tr>
                                            <th class="width-40 text-center">Parcels</th>
                                            <th class="width-20 text-center">Linked APNs</th>
                                            <th class="width-20 text-center">Link/Unlink</th>
                                            <th class="width-20 text-center">Detail</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                        <td colspan="4" class="pad-left report-sub-group">
                                            APN Books
                                        </td>
                                    </tr>
                                    <template v-for="books, bookIndex in apnBooks">
                                        <tr v-if="bookIndex == openedBook || (openedBook == -1 && books.linked_apn_count > 0)">
                                            <td class="pad-left-more">
                                                {{ apnBooks[bookIndex].apn_book }}
                                            </td>
                                            <td class="pad-left-more">
                                                {{ apnBookDisplay(bookIndex) }}
                                            </td>
                                            <td class="text-center">
                                                <a 
                                                    href="javascript:void(0)" 
                                                    class="pad-left text-center"
                                                    aria-label="unlink"
                                                >
                                                    <i 
                                                        v-if="books.linked_apn_count == books.apn_count"
                                                        id="unlinkApnBook" 
                                                        class="fal fa-lg fa-unlink text-danger"
                                                        title="Unlink"
                                                        v-on:click="() => unlinkApnBook(bookIndex)"
                                                    ></i>
                                                    <i 
                                                        v-else
                                                        id="linkApnBook" 
                                                        class="fal fa-lg fa-link text-success"
                                                        title="Link"
                                                        v-on:click="() => linkApnBook(bookIndex)"
                                                    ></i>
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                <a 
                                                    href="javascript:void(0)" 
                                                    class="pad-left text-center" 
                                                    aria-label="Open or Close"
                                                >
                                                    <i 
                                                        v-if="openedBook == bookIndex"
                                                        id="closeApnBook" 
                                                        class="fal fa-2x fa-angle-double-up text-primary"
                                                        title="Close"
                                                        v-on:click="() => closeApnBook(bookIndex)"
                                                    ></i>
                                                    <i 
                                                        v-else
                                                        id="openApnBook" 
                                                        class="fal fa-2x fa-angle-double-down text-primary"
                                                        title="Open"
                                                        v-on:click="() => openApnBook(bookIndex)"
                                                    ></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <template v-if="bookIndex == openedBook">
                                            <tr>
                                                <td colspan="4" class="pad-left-more report-sub-group">
                                                    <span v-if="openedPage == -1 && apnBooks[bookIndex].linked_apn_count == 0">
                                                        (No Linked APN Pages)
                                                    </span>
                                                    <span v-else>
                                                        APN Pages
                                                    </span>
                                                </td>
                                            </tr>
                                            <template v-for="pages, pageIndex in apnPages">
                                                <tr v-if="pageIndex == openedPage || (openedPage == -1 && pages.linked_apn_count > 0)">
                                                    <td class="pad-left-more">
                                                        {{ apnPages[pageIndex].apn_page }}
                                                    </td>
                                                    <td class="pad-left-more">
                                                        {{ apnPageDisplay(pageIndex) }}
                                                    </td>
                                                    <td class="text-center">
                                                        <a 
                                                            href="javascript:void(0)" 
                                                            class="pad-left text-center"
                                                            aria-label="unlink"
                                                        >
                                                            <i 
                                                                v-if="pages.linked_apn_count != 0"
                                                                id="unlinkApnPage" 
                                                                class="fal fa-lg fa-unlink text-danger"
                                                                title="Unlink"
                                                                v-on:click="() => unlinkApnPage(pageIndex)"
                                                            ></i>
                                                            <i 
                                                                v-else
                                                                id="linkApnPage" 
                                                                class="fal fa-lg fa-link text-success"
                                                                title="Link"
                                                                v-on:click="() => linkApnPage(pageIndex)"
                                                            ></i>
                                                        </a>
                                                    </td>
                                                    <td class="text-center">
                                                        <a 
                                                            href="javascript:void(0)" 
                                                            class="pad-left text-center" 
                                                            aria-label="Open or Close"
                                                        >
                                                            <i 
                                                                v-if="openedPage == pageIndex"
                                                                id="closeApnPage" 
                                                                class="fal fa-2x fa-angle-double-up text-primary"
                                                                title="Close"
                                                                v-on:click="() => closeApnPage(pageIndex)"
                                                            ></i>
                                                            <i 
                                                                v-else
                                                                id="openApnPage" 
                                                                class="fal fa-2x fa-angle-double-down text-primary"
                                                                title="Open"
                                                                v-on:click="() => openApnPage(pageIndex)"
                                                            ></i>
                                                        </a>
                                                    </td>
                                                </tr>
                                                <template v-if="pageIndex == openedPage">
                                                    <tr>
                                                        <td colspan="4" class="pad-left-more report-sub-group">
                                                            <span v-if="apnPages[pageIndex].linked_apn_count == 0">
                                                                (No Linked Parcel Numbers)
                                                            </span>
                                                            <span v-else>
                                                                Parcel Numbers
                                                            </span>
                                                        </td>
                                                    </tr>
                                                    <template v-for="apns, apnIndex in apnDetail">
                                                        <tr v-if="apns.linked_apn == 1">
                                                            <td class="pad-left-triple">
                                                                {{ apnDetail[apnIndex].apn }}
                                                            </td>
                                                            <td>
                                                            </td>
                                                            <td class="text-center">
                                                                <a 
                                                                    href="javascript:void(0)" 
                                                                    class="pad-left text-center"
                                                                    aria-label="unlink"
                                                                >
                                                                    <i 
                                                                        v-if="apns.linked_apn == 1"
                                                                        id="unlinkApn" 
                                                                        class="fal fa-lg fa-unlink text-danger"
                                                                        title="Unlink"
                                                                        v-on:click="() => unlinkApn(apnIndex)"
                                                                    ></i>
                                                                    <i 
                                                                        v-else
                                                                        id="linkApn" 
                                                                        class="fal fa-lg fa-link text-success"
                                                                        title="Link"
                                                                        v-on:click="() => linkApn(apnIndex)"
                                                                    ></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                    </template>
                                                </template>
                                            </template>
                                        </template>
                                    </template>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../../mixins/handle-error'
    export default {
        name: 'EditApnArea',
        props: {
            user: Object,
            data: Object,
        },
        data() {
            return {
                alert: Object(),
                messages: this.data.messages,
                pagination: this.data.pagination,
                errorMessage: '',
                tools: this.data.tools,
                apnBooks: [],
                apnPages: [],
                apnDetail: [],
                openedBook: -1,
                openedPage: -1,
            }
        },
        created() {
            this.listApnBooks();
        },
        computed: {
            parentArea() {
                return this.$store.getters.areaData;
            },
        },
        methods: {
            listApnBooks() {
                axios.get('/areas/list-apn-books', {
                    params: {
                        areaId: this.parentArea.id,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.apnBooks = [];
                    }
                    else {
                        this.apnBooks = response.data;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            listApnPages() {
                axios.get('/areas/list-apn-pages', {
                    params: {
                        areaId: this.parentArea.id,
                        apnBook: this.apnBooks[this.openedBook].apn_book,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.apnPages = [];
                    }
                    else {
                        this.apnPages = response.data;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            listApnDetail() {
                axios.get('/areas/list-apn-detail', {
                    params: {
                        areaId: this.parentArea.id,
                        apnBook: this.apnBooks[this.openedBook].apn_book,
                        apnPage: this.apnPages[this.openedPage].apn_page,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.apnDetail = [];
                    }
                    else {
                        this.apnDetail = response.data;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            apnBookDisplay(index)
            {
                return this.apnBooks[index].linked_apn_count
                + " of " + this.apnBooks[index].apn_count;
            },
            apnPageDisplay(index)
            {
                return this.apnPages[index].linked_apn_count
                + " of " + this.apnPages[index].apn_count;
            },
            openApnBook(index)
            {
                this.apnPages = [];
                this.openedBook = index;
                this.listApnPages();
            },
            closeApnBook(index)
            {
                this.openedBook = -1;
                this.apnPages = [];
            },
            linkApnBook(index)
            {
                this.linkUnlinkApnBook(index, 'link');
                if (index == this.openedBook) {
                    this.listApnPages();
                }
            },                
            unlinkApnBook(index)
            {
                this.linkUnlinkApnBook(index, 'unlink');
                if (index == this.openedBook) {
                    this.listApnPages();
                }
            },                
            linkUnlinkApnBook(index, action) {
                axios.get('/areas/' + action + '-apn-book', {
                    params: {
                        areaId: this.parentArea.id,
                        apnBook: this.apnBooks[index].apn_book,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.apnBooks = [];
                    }
                    else {
                        this.apnBooks = response.data.books;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            openApnPage(index)
            {
                this.openedPage = index;
                this.listApnDetail();
            },
            closeApnPage(index)
            {
                this.openedPage = -1;
                this.apnDetail = [];
            },
            linkApnPage(index)
            {
                this.linkUnlinkApnPage(index, 'link');
                if (index == this.openedPage) {
                    this.listApnDetail();
                }
            },                
            unlinkApnPage(index)
            {
                this.linkUnlinkApnPage(index, 'unlink');
                if (index == this.openedPage) {
                    this.listApnDetail();
                }
            },                
            linkUnlinkApnPage(index, action) {
                axios.get('/areas/' + action + '-apn-page', {
                    params: {
                        areaId: this.parentArea.id,
                        apnBook: this.apnBooks[this.openedBook].apn_book,
                        apnPage: this.apnPages[index].apn_page,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.apnPages = [];
                    }
                    else {
                        this.apnBooks = response.data.books;
                        this.apnPages = response.data.pages;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            linkApn(index)
            {
                this.linkUnlinkApn(index, 'link');
                this.listApnDetail();
            },                
            unlinkApn(index)
            {
                this.linkUnlinkApn(index, 'unlink');
                this.listApnDetail();
            },                
            linkUnlinkApn(index, action) {
                axios.get('/areas/' + action + '-apn', {
                    params: {
                        areaId: this.parentArea.id,
                        apnBook: this.apnBooks[this.openedBook].apn_book,
                        apnPage: this.apnPages[this.openedPage].apn_page,
                        apn: this.apnDetail[index].apn,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.apnDetail = [];
                    }
                    else {
                        this.apnBooks = response.data.books;
                        this.apnPages = response.data.pages;
                        this.apnDetail = response.data.apns;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('edit-apn-area component mounted.')
            }
        }
    }
</script>
