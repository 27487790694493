<template>
    <table class="table report-table">
        <thead>
            <tr>
                <th width="50%"><h4>Features</h4></th>
                <th width="25%"><h4>Professional</h4></th>
                <th width="25%"><h4>Enterprise</h4></th>
            </tr>
        </thead>
        <tbody>
            <template v-for="feature in featureList">
	            <tr>
	                <td class="pad-left pad-top-more report-group">
	                    <h5>
                        <i class="pad-right" :class="setIcon(feature.icon)"></i>
	                        {{ feature.header }}
	                    </h5>
	                </td>
	                <td></td>
	                <td></td>
	            </tr>
	            <tr v-for="item in feature.items">
	                <td class="text-left">
	                    <i class="fal fa-angle-right text-success pad-left-double"></i>
	                    <span class="pad-left">
                        {{item.text}}
                    	</span>
	                </td>
	                <td class="center">
	                    <i v-if="item.pro_icon !== undefined"
	                     :class="setIcon(item.pro_icon)"
	                     aria-label="checked"
	                     ></i>
                         <span v-if="item.pro_text !== undefined" class="fa-lg text-success">
                            {{ item.pro_text }}
                        </span>
                         <span v-if="item.pro_price !== undefined" class="fa-lg text-success">
                            {{ formatCurrency(item.pro_price) }}
                        </span>
	                </td>
	                <td class="center">
	                    <i v-if="item.ent_icon !== undefined"
	                     :class="setIcon(item.ent_icon)"
	                     aria-label="checked"
	                     ></i>
                         <span v-if="item.ent_text !== undefined" class="fa-lg text-success">
                            {{ item.ent_text }}
                        </span>
                         <span v-if="item.ent_price !== undefined" class="fa-lg text-success">
                            {{ formatCurrency(item.ent_price) }}
                        </span>
	                </td>
	            </tr>
	        </template>
            <tr>
                <td class="pad-left pad-top-more report-group">
                    <h5>
                        <i class=" pad-right" :class="setIcon(reports.icon)"></i>
                        {{ reports.header }}
                    </h5>
                </td>
                <td class="pad-top-more center">
                    <span class="fa-lg text-success">
                        {{ formatCurrency(reports.area_price) }} per area<br/>
                        ({{reports.area_length}}-{{reports.area_interval}} access)
                    </span>
                </td>
                <td class="pad-top-more center">
                    <i v-if="reports.ent_icon !== undefined"
                     :class="setIcon(reports.ent_icon)"
                     aria-label="checked"
                     ></i>
                </td>
            </tr>
            <tr v-for="item in reports.items">
                <td class="text-left">
                    <i class="fal fa-angle-right text-success pad-left-double"></i>
                    <span class="pad-left">
                    {{item.text}}
                    </span>
                </td>
                <td class="center">
                    <i v-if="item.pro_icon !== undefined"
                     :class="setIcon(item.pro_icon)"
                     aria-label="checked"
                     ></i>
                     <span v-if="item.pro_text !== undefined" class="fa-lg text-success">
                        {{ item.pro_text }}
                    </span>
                </td>
                <td class="center">
                    <i v-if="item.ent_icon !== undefined"
                     :class="setIcon(item.ent_icon)"
                     aria-label="checked"
                     ></i>
                </td>
            </tr>            
            <template v-for="(group, index) in reports.packages">
                <tr>
                    <td class="text-left pad-top pad-left-double">
                        <h6 class="pad-left-double">
                        {{group.name}}
                        <span class="pad-left-double">
                            <a href="javascript:void(0)" 
                            @click="toggleDetail(index)">
                                <i :class="showDetailIcon(index)"
                                    aria-label="close"
                                ></i>
                            </a>
                        </span>
                        </h6>
                    </td>
                    <td class="pad-top center">
                        <i v-if="group.pro_icon !== undefined"
                         :class="setIcon(group.pro_icon)"
                         aria-label="checked"
                         ></i>
                    </td>
                    <td class="pad-top center">
                        <i v-if="group.ent_icon !== undefined"
                         :class="setIcon(group.ent_icon)"
                         aria-label="checked"
                         ></i>
                    </td>
                </tr>
                <template v-if="showDetail[index]">
                <tr v-for="item in group.items">
                    <td class="text-left pad-left-more">
                    	<span class="pad-left">
                        <i class="fal fa-angle-right text-success pad-right"></i>
                        <span>
                        {{item.text}}
                    	</span>
                    	</span>
                    </td>
                    <td class="center">
                        <i v-if="item.pro_icon !== undefined"
                         :class="setIcon(item.pro_icon)"
                         aria-label="checked"
                         ></i>
                    </td>
                    <td class="center">
                        <i v-if="item.pro_icon !== undefined"
                         :class="setIcon(item.pro_icon)"
                         aria-label="checked"
                         ></i>
                    </td>
                </tr>
                </template>
            </template>
            <tr v-if="isProductionServer">
                <td class=""></td>
                <td class="center pad-top-more pad-left pad-right ">
                    <a :href="mailToSales('pro')" name="btn_pro" class="btn btn-success btn-lg btn-block">Contact Sales</a>                    
                </td>
                <td class="center pad-top-more pad-left pad-right ">
                    <a :href="mailToSales('ent')" name="btn_ent" class="btn btn-success btn-lg btn-block">Contact Sales</a>                    
                </td>
            </tr>
            <tr v-else>
                <td class=""></td>
                <td class="center pad-top-more pad-left pad-right ">
                    <a :href="nextPage('pro')" name="btn_pro" class="btn btn-success btn-lg btn-block">Subscribe</a>                    
                </td>
                <td class="center pad-top-more pad-left pad-right ">
                    <a :href="nextPage('ent')" name="btn_ent" class="btn btn-success btn-lg btn-block">Subscribe</a>                    
                </td>
            </tr>
            <tr>
                <td class="last-row"></td>
                <td colspan="2" class="center pad-top-more pad-left pad-right last-row">
                    <a href="/register?plan=trial" name="btn_trial" class="btn btn-outline-secondary btn-lg btn-block">Free Trial</a>                    
                </td>
            </tr>

        </tbody>
    </table>
</template>
<script>
    export default {
        name: 'PricingTablePerReport',
        props: {
            data: {Object, required: true},
        },
        data() {
            return {
                plans: this.data.features.plans,
                areas: this.data.features.areas,
                tools: this.data.features.tools,
                security: this.data.features.security,
                reports: this.data.features.reports,
                showDetail: {
                    av_reports: false,
                    lu_reports: false,
                    tax_reports: false,
                    own_reports: false,
                    apl_reports: false,
                },
            }
        },
        computed: {
        	featureList() {
        		return {plans: this.plans, areas: this.areas, tools: this.tools}
        	},
        	reportsList() {
        		return {reports: this.reports}
        	},
            isProductionServer() {
                return (process.env.NODE_ENV == 'production');
            },
        },
        methods: {
            iconAngleUp() {
                return "text-success fal fa-lg fa-angle-up";
            },
            iconAngleDown() {
                return "text-success fal fa-lg fa-angle-down";
            },
            showDetailIcon(report) {
                if (this.showDetail[report] === true) {
                   return this.iconAngleUp(); 
                }
                return this.iconAngleDown();
            },
            setIcon(icon) {
                return 'text-success fal fa-lg fa-' + icon;
            },
            formatCurrency(value) {
                // return '$' + Number(value/100).toFixed(0).toLocaleString('en-US');
                return '$' + Intl.NumberFormat('en-US').format(Number(value/100));
            },
            toggleDetail(report) {
                this.showDetail[report] = !this.showDetail[report];
            },
            nextPage(type) {
                let path = '';
                if (this.data.user.accountName == null) {
                    path = '/register';
                } else {
                    path = '/billing';
                }
                if (type == 'ent') {
                    path += '?plan=enterprise';
                } else {
                    path += '?plan=professional';
                }
                return path;
            },
            mailToSales(type) {
                let plan = (type == 'ent' ? 'Enterprise' : 'Professional');
                return "mailto:sales@urbics.com?"
                    + "subject=" + plan + " Plan Subscription&" 
                    + "body=Hello%0D%0A"
                    + "I'd like to subscribe to the " + plan + " Plan.%0D%0A"
                    + "I understand this is a beta site for which "
                    + "features, pricing, and other attributes are subject to change.";

                return val;
            },
        },
        created() {
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('pricing-table-per-report component mounted.')
            }            
        }
    }
</script>
