// apiTokens store

export default {
	state: {
		tokens: [],
		abilities: [],
	},

	getters: {
	    userTokens(state) {
			return state.tokens;
	    },
	    userAbilities(state) {
			return state.abilities;
	    },
	},

	actions: {
		refreshTokens (context) {
            axios.get('settings/api/tokens')
            .then(response => {
                context.commit('updateTokens', {tokens: response.data.tokens, abilities: response.data.abilities});
            })
            .catch(function (error) {
                if (process.env.NODE_ENV !== 'production') {            
                    console.log(error.message)
                }
            })
        }
	},

	mutations: {
		updateTokens(state, payload) {
			state.tokens = payload.tokens
			state.abilities = payload.abilities
		},
	},
}
