<template>
    <div class="card card-settings">
        <div class="card-header">
            <div class="row">
                <div class="col-9">Notifications</div>
                <div class="col-3 text-md-right">
                    <button 
                        type="button" 
                        class="btn"
                        @click="toggleShowSettings"
                    >Notification Settings <i v-bind:class="showSettingsIcon" class="pad-left"></i></button>
                </div>
            </div>
            <div v-if="showSettings" class="card-controls">
                <div class="row justify-content-evenly card-item">
                    <div class="col-12 text-md-right">Notify by email
                        <span class="pad-left">
                        <i v-bind:class="sendEmailIcon" v-on:click="toggleSendEmail"></i>
                        </span>
                    </div>
                </div>
                <div class="row justify-content-evenly card-item">
                    <div class="col-12 text-md-right">Notify when new data is posted
                        <span class="pad-left">
                        <i v-bind:class="notifyNewPostsIcon" v-on:click="toggleNotifyNewPosts"></i>
                        </span>
                    </div>
                </div>
                <div v-if="isSiteAdmin">
                    <div class="row justify-content-evenly ">
                        <div class="offset-9 col-3 text-md-center card-controls-group-header">Administrator Settings</div>
                    </div>
                    <div class="row justify-content-evenly card-item">
                        <div class="col-12 text-md-right">Notify of all postings
                            <span class="pad-left">
                            <i v-bind:class="notifyAllPostsIcon" v-on:click="toggleNotifyAllPosts"></i>
                            </span>
                        </div>
                    </div>
                    <div class="row justify-content-evenly card-item">
                        <div class="col-12 text-md-right">Notify staging server postings
                            <span class="pad-left">
                            <i v-bind:class="notifyStagingPostsIcon" v-on:click="toggleNotifyStagingPosts"></i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <div class="row card-item"  v-if="fetchingNotifications">
                <div class="col-12 alert alert-info">
                    <i class="fa fa-spinner fa-spin"></i> Loading Notifications
                </div>
            </div>
            <div class="row card-item" v-if=" ! fetchingNotifications && hasUnreadNotifications && showAll == false">
                <div class="col-12 alert alert-info">Displaying your unread notifications.
                    <button 
                        type="button" 
                        class="btn btn-link padding-left: 1rem;"
                        @click="toggleUnread"
                    >Show all notifications for the past month</button>
                </div>
            </div>
            <div class="row card-item" v-if=" ! fetchingNotifications && ! hasUnreadNotifications && hasReadNotifications && showAll == false">
                <div class="col-12 alert alert-info">You have no unread notifications. 
                    <button 
                        type="button" 
                        class="btn btn-link padding-left: 1rem;"
                        @click="toggleUnread"
                    >Show all notifications for the past month</button>
                </div>
            </div>
            <div class="row card-item" v-if=" ! fetchingNotifications && ! hasReadNotifications && ! hasUnreadNotifications">
                <div class="col-12 alert alert-info">You have no notifications.</div>
            </div>
            <div class="settings-container" v-if="showingNotifications && hasNotifications">
                <div class="row justify-content-evenly card-column-header">
                    <div class="col-2 text-md-center">Date</div>
                    <div class="col-10 text-md-center">Message</div>
                </div>
                <div class="notification" v-for="notification in notifications">
                    <div class="row justify-content-evenly card-item">
                        <div class="col-2 text-md-center">
                             {{ $filters.datetimeShort(notification.data.created_at) }}
                        </div>
                        <div class="col-10 text-md-left">
                            <span v-html = notification.data.body></span>
                            <a :href="notification.data.action_url" v-if="notification.data.action_text">
                                 (See Detail)
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: {
      urbicsUser: { Object, required: true }
    },
    data: function() {
      return {
          displayNotifications: true,
          fetchingNotifications: false,
          allNotificationsCount: this.urbicsUser.notificationsCount,
          unreadNotificationsCount: this.urbicsUser.unreadNotificationsCount,
          notifications: [],
          showAll: false,
          showSettings: false,
          notifySettings: [],
          fetchingNotifySettings: false,
      }
    },
    created() {
    },
    computed: {
        showingNotifications() {
            return this.displayNotifications;
        },
        loadingNotifications() {
            return this.fetchingNotifications;
        },
        showNotifications() {
            return true;
        },
        hasUnreadNotifications() {        
            return this.unreadNotificationsCount != 0;
        },
        hasReadNotifications() {        
            return this.allNotificationsCount > this.unreadNotificationsCount;
        },
        hasNotifications() {
            return this.notifications.length != 0;
        },
        isSiteAdmin() {
            return this.urbicsUser.isAdmin == 1;

        },
        showSettingsIcon() {
            return (this.showSettings ? "far fa-chevron-up" : "far fa-chevron-down");
        },
        sendEmail() {
            return (this.notifySettings.length != 0 
                    && (typeof this.notifySettings['via_email'] !== 'undefined')
                    && (this.notifySettings['via_email'] == 'yes'));
        },
        sendEmailIcon() {
            return (this.fetchingNotifySettings ? "fal fa-spinner fa-spin fa-lg"
                    : (this.sendEmail ? "fal fa-toggle-on fa-lg text-success" 
                    : "fal fa-toggle-off fa-lg text-success"));
        },
        notifyNewPosts() {
            return (this.notifySettings.length != 0 
                    && (typeof this.notifySettings['notify_file_posts'] !== 'undefined')
                    && this.notifySettings['notify_file_posts'] == 'new_data');
        },
        notifyNewPostsIcon() {
            return (this.fetchingNotifySettings ? "fal fa-spinner fa-spin fa-lg"
                    : (this.notifyNewPosts ? "fal fa-toggle-on fa-lg text-success" 
                    : "fal fa-toggle-off fa-lg text-success"));
        },
        notifyAllPosts() {
            return (this.notifySettings.length != 0 
                    && (typeof this.notifySettings['notify_file_posts'] !== 'undefined')
                    && this.notifySettings['notify_file_posts'] == 'all');
        },
        notifyAllPostsIcon() {
            return (this.fetchingNotifySettings ? "fal fa-spinner fa-spin fa-lg"
                    : (this.notifyAllPosts ? "fal fa-toggle-on fa-lg text-success" 
                    : "fal fa-toggle-off fa-lg text-success"));
        },
        notifyStagingPosts() {
            return (this.notifySettings.length != 0 
                    && (typeof this.notifySettings['notify_staging_server'] !== 'undefined')
                    && this.notifySettings['notify_staging_server'] == 'yes');
        },
        notifyStagingPostsIcon() {
            return (this.fetchingNotifySettings ? "fal fa-spinner fa-spin fa-lg"
                    : (this.notifyStagingPosts ? "fal fa-toggle-on fa-lg text-success" 
                    : "fal fa-toggle-off fa-lg text-success"));
        },
    },
    watch: {
        showSettings: function(newValue, oldValue) {
            if (newValue && this.notifySettings.length == 0) {
                this.getNotifySettings();
            }
        },
    },
    methods: {
        toggleUnread() {
            this.showAll = !this.showAll;
            this.getAllNotifications();
        },
        toggleShowSettings() {
            this.showSettings = !this.showSettings;
        },
        toggleSendEmail() {
            this.notifySettings['via_email'] = (this.notifySettings['via_email'] == "yes" ? "no" : "yes");
            this.updateNotifySettings();
        },
        toggleNotifyNewPosts() {
            this.notifySettings['notify_file_posts'] = (
                (this.notifySettings['notify_file_posts'] == "never" 
                || this.notifySettings['notify_file_posts'] == "all") ? "new_data" : "never"
            );
            this.updateNotifySettings();
        },
        toggleNotifyAllPosts() {
            this.notifySettings['notify_file_posts'] = (
                (this.notifySettings['notify_file_posts'] == "never" 
                || this.notifySettings['notify_file_posts'] == "new_data") ? "all" : "never"
            );
            this.updateNotifySettings();
        },
        toggleNotifyStagingPosts() {
            this.notifySettings['notify_staging_server'] = (
                this.notifySettings['notify_staging_server'] == "no" ? "yes" : "no"
            );
            this.updateNotifySettings();
        },
        getUnreadNotifications() {
            var self = this;

            this.fetchingNotifications = true;

            axios.post('/notifications/recent', {
                userId: this.urbicsUser.userId,
            })
            .then(response => {
                this.notifications = response.data;

                this.fetchingNotifications = false;
            })
            .catch (response => {
                this.errorMessage = 'An error occurred while processing this page.';
                if (process.env.NODE_ENV !== 'production') {
                    this.errorMessage += ' The error message is: ' + response.data;
                }
            });
        },

        getAllNotifications() {
            var self = this;

            this.fetchingNotifications = true;

            axios.post('/notifications/all', {
                userId: this.urbicsUser.userId,
            })
            .then(response => {
                this.notifications = response.data;

                this.fetchingNotifications = false;
            })
            .catch (response => {
                this.errorMessage = 'An error occurred while processing this page.';
                if (process.env.NODE_ENV !== 'production') {
                    this.errorMessage += ' The error message is: ' + response.data;
                }
            });
        },
        markAsRead() {
            axios.put('/notifications/read', {
                userId: this.urbicsUser.userId,
            })
            .then(response => {
            })
            .catch (response => {
                this.errorMessage = 'An error occurred while processing this page.';
                if (process.env.NODE_ENV !== 'production') {
                    this.errorMessage += ' The error message is: ' + response.data;
                }
            });
        },
        getNotifySettings() {
            this.fetchingNotifySettings = true;

            axios.post('/notifications/settings', {
                userId: this.urbicsUser.userId,
            })
            .then(response => {
                this.notifySettings = response.data;

                this.fetchingNotifySettings = false;
            })
            .catch (response => {
                this.errorMessage = 'An error occurred while processing this page.';
                if (process.env.NODE_ENV !== 'production') {
                    this.errorMessage += ' The error message is: ' + response.data;
                }
            });
        },

        updateNotifySettings() {
            this.fetchingNotifySettings = true;

            axios.post('/notifications/settings/update', {
                userId: this.urbicsUser.userId,
                settings: this.notifySettings
            })
            .then(response => {
                this.notifySettings = response.data;

                this.fetchingNotifySettings = false;
            })
            .catch (response => {
                this.errorMessage = 'An error occurred while processing this page.';
                if (process.env.NODE_ENV !== 'production') {
                    this.errorMessage += ' The error message is: ' + response.data;
                }
            });
        },
    },
    mounted() {
        this.getUnreadNotifications();
        this.markAsRead();
        if (process.env.NODE_ENV !== 'production') {            
            console.log('Notifications omponent mounted.')
        };
    },
  }
</script>
