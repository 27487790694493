<template>
 	<div id="mapContainer" :style="mapDimensions"></div>
</template>
<script>
	import mapboxgl from "mapbox-gl";
    export default {
    	name: "MapboxTest",
        props: {
            data: Object,
        },
        data() {
            return {
            }
        },
        created() {
        },
        computed: {
        	mapDimensions() {
        		return "width: "+this.data.width+"; height: "+this.data.height+";";
        	}
        },
        methods: {
            methodTemplate() {
                return;
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
        	mapboxgl.accessToken = this.data.mapboxToken;
			var map = new mapboxgl.Map({
			    container: "mapContainer",
				style: 'mapbox://styles/mapbox/light-v9',
				zoom: this.data.zoom,
				center: [this.data.centerLat, this.data.centerLon],
				showTileBoundaries: true
		    });        	

			map.on('load', function () {
	        	var url = 'https://tiles.regrid.com';
				// Create a parcel layer
				self = this;
				var parcelCreate = $.ajax({
					url: url + '/api/v1/parcels?format=mvt&token=XenMeKyz5T4B5gsigwkhNC3y3KtBvinnEZkLwUuLwWdj251mv_ysrXqLP7x_n-Qw',
					type: 'GET',
					contentType: 'application/json',
					dataType: 'json'
				});
				parcelCreate.fail(function(jqXHR, textStatus, errorThrown) {
			        if (process.env.NODE_ENV !== 'production') {            
						console.log('Error getting parcel layer', jqXHR, textStatus, errorThrown);
					}
				});

				$.when(parcelCreate).then(setup);

				function setup(layerData) {
					var data = layerData;
			        if (process.env.NODE_ENV !== 'production') {            
						console.log('Got parcel layer', layerData);
					}

					// Register the parcel source using the tile URL we just got
					map.addSource(data.id, {
						type: 'vector',
						tiles: data.tiles
					});

					// Add basic parcel outlines
					map.addLayer({
						'id': 'parcels',
						'type': 'line',
						'source': data.id,
						'source-layer': data.id,
						'minzoom': 13,
						'maxzoom': 20,
						layout: {
							visibility: 'visible'
						},
						'paint': {
							'line-color': '#649d8d'
						}
					});
				};
			});
	        if (process.env.NODE_ENV !== 'production') {            
	            console.log('MapboxTest component mounted.')
	        }
        }
    }
</script>
