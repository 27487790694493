<template>
    <div class="tabs-component-panel">
        <div class="toolbar">
            <alert 
              :alerts="alert"
            ></alert>
        </div>
        <div class="row section-title">
            <div class="col-md-12">All Property Owner Alias Names</div>
        </div>
        <div class="row section-content report-table">
            <div class="col-md-12 table-responsive">
                    <table class="table report-table">
                        <thead>
                            <tr>
                                <th class="width-40">Alias Name</th>
                                <th class="width-20">Number of Linked Names</th>
                                <th class="width-20">Delete Alias Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                                v-for="alias, aliasIndex in aliasNames" 
                                v-bind:key="alias.alias_id"
                            >
                                <td v-if="editingID != aliasIndex" class="pad-left">{{alias.alias_name}}
                                    <a 
                                        href="javascript:void(0)" 
                                        class="pad-left" 
                                        aria-label="Edit Name"
                                    >
                                        <i 
                                            id="editAliasName" 
                                            class="fal fa-lg fa-edit text-success"
                                            v-on:click="() => editAliasName(aliasIndex)"
                                        ></i>
                                    </a>
                                </td>
                                <td v-else class="pad-left">                                
                                    <input 
                                        type="text" 
                                        id="editAliasName"
                                        v-model="aliasName"
                                        class="col-md-6"
                                        v-bind:class="{'is-invalid': hasEditAliasNameError}"
                                    >
                                    <a 
                                        href="javascript:void(0)" 
                                        class="col-md-1 text-center align-bottom"
                                        aria-label="Save"
                                    >
                                        <i 
                                            id="saveEditedAliasName" 
                                            class="fal fa-2x fa-check text-success"
                                            title="Save"
                                            v-on:click="() => saveEditedAliasName()"
                                        ></i>
                                    </a>
                                    <a 
                                        href="javascript:void(0)" 
                                        class="col-md-1 text-center align-bottom" 
                                        aria-label="Cancel"
                                    >
                                        <i 
                                            id="cancelEditAliasName" 
                                            class="fal fa-2x fa-times text-secondary"
                                            title="Cancel"
                                            v-on:click="() => cancelEditAliasName()"
                                        ></i>
                                    </a>
                                    <span role="alert" class="invalid-feedback"><strong>{{ editAliasNameError }}</strong></span>
                                </td>
                                <td class="text-center">{{alias.owner_count}}</td>

                                <td v-if="aliasIndex == markedForDeletion" class="text-center">
                                    <span>Are you sure?
                                    <a href="javascript:void(0)" class="pad-left" aria-label="Yes">
                                        <i 
                                            id="confirmDeletionIcon" 
                                            class="fal fa-lg fa-thumbs-up text-primary"
                                            v-on:click="deleteAliasName(aliasIndex)"
                                        ></i>
                                    </a>
                                    <a href="javascript:void(0)" class="pad-left"  aria-label="No">
                                        <i 
                                            id="cancelDeletionIcon" 
                                            class="fal fa-lg fa-thumbs-down text-danger"
                                            v-on:click="markedForDeletion = -1"
                                        ></i>
                                    </a>
                                    </span>
                                </td>
                                <td v-else-if="aliasIndex == deletingID" class="text-center">
                                    <a href="javascript:void(0)" class="align-right disabled" >
                                        <i 
                                            id="deleteAreaIcon" 
                                            class="fal fa-lg fa-circle-notch fa-spin text-secondary"
                                        ></i>
                                    </a>
                                </td>
                                <td v-else class="text-center">
                                    <a href="javascript:void(0)" class="align-right" >
                                        <i 
                                            id="deleteAreaIcon" 
                                            class="fal fa-lg fa-times-circle text-danger"
                                            v-on:click="markedForDeletion = aliasIndex"
                                        ></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../../mixins/handle-error'
    export default {
        name: 'ManageAllOwnerAliases',
        props: {
            user: Object,
            data: Object,
        },
        data() {
            return {
                alert: Object(),
                messages: this.data.messages,
                pagination: this.data.pagination,
                tools: this.data.tools,
                aliasNames: [],
                aliasCount: 0,
                errorMessage: '',
                ownerCount: 0,
                ownerNames: [],
                markedForDeletion: -1,
                deletingID: -1,
                editingID: -1,
                editingOwnerAlias: [],
                showAliasNameSelector: false,
                formattedAliasNamesList: [],
                formattedOwnerNamesList: [],
                linkOwnerID: 0,
                minSearchLength: 3,
                maxAliases: 50,
                maxOwners: 1000,
                aliasFilter: '',
                ownerFilter: '',
                noDataMessage: {message: '', linktext: '', linkPath: ''},
                showLinkedOnly: false,
                editingAliasName: false,
                editAliasNameError: '',
                aliasName: '',
            }
        },
        created() {
            this.refreshData();
            },
        computed: { 
            linkOwner() {
                this.showAliasNameSelector = !this.showAliasNameSelector;
            },
            getToggleIcon() {
                if (this.showLinkedOnly) {
                    return "fa-toggle-on ";
                }
                return "fa-toggle-off ";
            },
            reportHasNoContent() {
                if (this.ownerNames.length == 0) {
                    this.data.messages.noData.display = true;
                    return true;
                }
                return false;
            },
            displayOwners() {
                if (this.filteredOwnerNames.length > 0) {
                    return this.formatOwnerNamesList;
                }
                if (this.ownerFilter.length > 0) {
                    this.formattedOwnerNamesList = [];
                    this.formattedOwnerNamesList.push({
                        original_owner_name: this.ownerFilter.toUpperCase(),
                        owner_id: 0, 
                        alias_name: '',
                        alias_id: 0, 
                        message: 'No names were found ',
                    });
                    this.ownerCount = 1;

                    return this.formattedOwnerNamesList;
                }
                return '';
            },
            filteredOwnerNames() {
                const filtered = [];
                const regOption = new RegExp(this.ownerFilter, 'ig');
                for (const option of this.ownerNames) {
                    if (this.ownerFilter.length < 1 || option.original_owner_name.match(regOption)) {
                        if (filtered.length < this.maxOwners) filtered.push(option);
                    }
                }
                return filtered;
            },
            formatOwnerNamesList() {
                var j = 0;
                this.formattedOwnerNamesList = [];
                this.ownerCount = 0;
                for (var i = 0; i < this.filteredOwnerNames.length; i++) {
                    var item = this.filteredOwnerNames[i];
                    if (this.showLinkedOnly && !item.alias_id) {
                        continue;
                    }
                    this.formattedOwnerNamesList.push({
                        owner_name: item.original_owner_name,
                        owner_id: item.original_owner_id, 
                        alias_name: item.alias_name,
                        alias_id: item.alias_id, 
                    });
                    ++this.ownerCount;
                }
                return this.formattedOwnerNamesList
            },
            displayAliases() {
                if (this.filteredAliasNames.length > 0) {
                    return this.formatAliasNamesList;
                }
                if (this.aliasFilter.length > 0) {
                    this.formattedAliasNamesList = [];
                    this.formattedAliasNamesList.push({
                        alias_name: this.aliasFilter.toUpperCase(),
                        alias_id: 0, 
                        class: 'report-sub-item', 
                        message: 'Add this alias: ',
                        handler: 'linkToNewAlias',
                        data: {
                            userId:  this.user.userId,
                            aliasName: this.aliasFilter.toUpperCase(),
                            ownerId: this.linkOwnerID
                        },
                    });
                    this.aliasCount = 1;

                    return this.formattedAliasNamesList;
                }
                return '';
            },
            filteredAliasNames() {
                const filtered = [];
                const regOption = new RegExp(this.aliasFilter, 'ig');
                for (const option of this.aliasNames) {
                    if (this.aliasFilter.length < 1 || option.alias_name.match(regOption)) {
                        if (filtered.length < this.maxAliases) filtered.push(option);
                    }
                }
                return filtered;
            },
            formatAliasNamesList() {
                var j = 0;
                this.formattedAliasNamesList = [];
                this.aliasCount = 0;
                for (var i = 0; i < this.filteredAliasNames.length; i++) {
                    var item = this.filteredAliasNames[i];
                    this.formattedAliasNamesList.push({
                        alias_name: item.alias_name,
                        alias_id: item.alias_id, 
                        class: 'report-sub-item', 
                        message: '',
                    });
                    ++this.aliasCount;
                }
                return this.formattedAliasNamesList
            },
            hasEditAliasNameError() {
                return this.editAliasNameError.length > 0;
            },
        },
        methods: {
            clearDeletion() {
                this.markedForDeletion = -1;
                this.deletingID = -1;
            },
            clearAliasFilter() {
                this.linkOwnerID = 0;
                this.aliasFilter = '';
            },
            clearOwnerFilter() {
                this.ownerFilter = '';
            },
            addAliasLink(index) {
                if (this.formattedAliasNamesList[index] === undefined) {
                    return;
                }
                axios.get('/reports/manage-name-consolidation/update', {
                    params: {
                        aliasId: this.formattedAliasNamesList[index].alias_id,
                        ownerId: this.linkOwnerID,
                        aliasName: this.formattedAliasNamesList[index].alias_name,
                    }
                })
                .then(response => {
                    if (response.data.status_code == 200) {
                        this.refreshData();
                        return;
                    }
                    this.alert = displayAlert(response.data.status_message);
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                })
                .then(() => {
                    this.linkOwnerID = 0;
                    this.clearAliasFilter
                });
                return;
            },
            removeAliasLink(index) {
                if (this.displayOwners[index] === undefined) {
                    return;
                }
                axios.get('/reports/manage-name-consolidation/delete', {
                    params: {
                        aliasId: this.displayOwners[index].alias_id,
                        ownerId: this.displayOwners[index].owner_id,
                        aliasName: this.displayOwners[index].alias_name,
                    }
                })
                .then(response => {
                    if (response.data.status_code == 200) {
                        this.refreshData();
                        return;
                    }
                    this.alert = displayAlert(response.data.status_message);
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });

                return;
            },
            deleteAliasName(index) {
                this.deletingID = index;
                if (this.aliasNames[index] === undefined) {
                    return;
                }
                axios.get('/reports/manage-name-consolidation/delete', {
                    params: {
                        aliasId: this.aliasNames[index].alias_id,
                    }
                })
                .then(response => {
                    if (response.data.status_code == 200) {
                        this.clearDeletion();
                        this.refreshData();
                        return;
                    }
                    this.alert = displayAlert(response.data.status_message);
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                })
                .then (() => {
                    this.clearDeletion();
                });
                return;
            },
            refreshData() {
                // this.refreshOwnerNameList();
                this.refreshAliasNameCounts();
            },
            refreshAliasNameCounts() {
                axios.get('/reports/manage-name-consolidation/list')
                .then(response => {
                    if (response.data.length == 0) {
                        this.aliasNames = [];
                    }
                    else {
                        this.aliasNames = response.data;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            editAliasName(index) {
                this.editingID = index;
                this.editingOwnerAlias = this.aliasNames[index];
                this.aliasName = this.editingOwnerAlias.alias_name;

                return;
            },
            saveEditedAliasName() {
                this.editAliasNameError = '';
                if (this.aliasName == this.editingOwnerAlias.alias_name) {
                    this.cancelEditAliasName();
                    return;
                }
                if (this.aliasName.length < 5) {
                    this.editAliasNameError = 'The new name must be at least four characters long';
                    return;
                }
                
                this.updateAliasName();
            },
            cancelEditAliasName()
            {
                this.aliasName == '';
                this.editAliasNameError = '';
                this.editingAliasName = false;
                this.editingID = -1;
                this.editingOwnerAlias = [];
            },
            updateAliasName() {
                let url = '/reports/manage-name-consolidation/edit';
                axios.get(url, {
                    params: {
                        aliasId: this.editingOwnerAlias.alias_id,
                        aliasName: this.aliasName,
                    }
                })
                .then(response => {
                    if (response.data.status_code != 200) {
                        this.editAliasNameError = response.data.status_message;
                        return;
                    }
                    else {
                        this.cancelEditAliasName();
                        this.refreshData();
                    }
                })
                .catch (error => {
                    this.cancelEditAliasName();
                    this.alert = displayAlert(error.message);
                });
            },            
        },
        watch: {
            aliasNames: function(newValue, oldValue) {
                if (this.data.messages.noData !== undefined) {
                    this.data.messages.noData.display = (newValue.length == 0);                
                }
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>