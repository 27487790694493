<template>
    <div>
        <div class="content-title row">
            <div class="offset-1 col-md-10 title-text">
                <toolbar 
                  :messages="propMessages"
                  :pagination="propPagination"
                  :tools="propTools"
                ></toolbar>
            </div>
        </div>
        <div class="base-page-content">
            <div class="row section-content report-cards justify-content-around">
                <div class="col-md-5 report-card shadow border-secondary">
                    <div class="card-body">
                        <div class="section-title">Select a City, County or District</div>
                        <area-selection-tools :user="user"></area-selection-tools>
                    </div>
                </div>
                <div class="col-md-5 report-card shadow border-secondary">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 section-title">View Reports for the Current Area</div>
                        </div>
                        <div class="row section-content">
                            <div class="col-md-6">
                                <ul class="report-list list-unstyled">
                                    <li v-for="col in col1Output" :class="col.data_class">
                                        <span v-if="col.has_label == true" class="item-label">
                                            {{ col.label }}
                                        </span>
                                        <span v-if="col.has_link == true">
                                            <a 
                                                :href="col.link_href" 
                                                :target="col.link_target" 
                                                :rel="col.link_rel"
                                            >
                                                <i v-if="col.has_icon == true" 
                                                    :class="col.icon"  
                                                    aria-hidden="true" 
                                                    aria-label="col.label"
                                                ></i>
                                                {{ col.value }}
                                            </a>
                                        </span>
                                        <span v-else>{{ col.value }}</span>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <ul class="report-list list-unstyled">
                                    <li v-for="col in col2Output" :class="col.data_class">
                                        <span v-if="col.has_label == true" class="item-label">
                                            {{ col.label }}
                                        </span>
                                        <span v-if="col.has_link == true">
                                            <a 
                                                :href="col.link_href" 
                                                :target="col.link_target" 
                                                :rel="col.link_rel"
                                            >
                                                <i v-if="col.has_icon == true" 
                                                    :class="col.icon"  
                                                    aria-hidden="true" 
                                                    aria-label="col.label"
                                                ></i>
                                                {{ col.value }}
                                            </a>
                                        </span>
                                        <span v-else>{{ col.value }}</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            user: Object,
            data: Object,
        },
        data() {
            return {
                propMessages: this.data.messages,
                propPagination: this.data.pagination,
                propTools: this.data.tools,
                messages: this.$store.getters.messages,
                pagination: this.$store.getters.pagination,
                tools: this.$store.getters.tools,
            }
        },
        created() {
            this.$store.commit('updateMessages', {messages: this.formatPropMessages});            
            this.$store.commit('updatePagination', {pagination: this.data.propPagination});           
            this.$store.commit('updateTools', {tools: this.data.propTools});            
        },
        computed: {
            formatPropMessages() {
                let output = [];
                if (this.propMessages.alerts === 'undefined'
                    || this.propMessages.alerts == null) {
                    return output;
                }
                Object.entries(this.propMessages.alerts).forEach(entry => {
                    output.push({status: entry[0], message: entry[1]});
                });
                return output;
            },
            reportHasNoContent() {
                if (this.output.length == 0) {
                    this.data.messages.noData.display = true;
                    return true;
                }
                return false;
            },
            reportColumns() {
                return this.dataElement('columns', 1);
            },
            col1Output() {
                return this.output.filter( function(item) {
                    return item.column == 1;
                })

            },
            col2Output() {
                return this.output.filter( function(item) {
                    return item.column == 2;
                })

            },
            col3Output() {
                return this.output.filter( function(item) {
                    return item.column == 3;
                })

            },
            output() {
                if (typeof this.data.output.data === 'undefined'
                    || this.data.output.data === null
                    || this.data.output.data.length == 0) {
                    return [];
                }
                let items = [];
                let metaKeys = [
                    'hide_label', 'label', 'icon_class', 'data_class', 'link_target', 
                    'link_rel', 'column'
                ];
                this.data.output.data.forEach((item) => { 
                    item.data.forEach((col) => {
                        let obj = {
                            column: 1,
                            data_class: '',
                            has_label: false,
                            has_icon: false,
                            has_link: false,
                            hide_label: true,
                            icon_class: '',
                            label: '',
                            link_href: '',
                            link_rel: '',
                            link_target: '', 
                            related: '',
                            type: '',
                            value: '',
                        };
                        for (const [key, value] of Object.entries(col.data)) {
                            Object.assign(obj, {type: key, value: value});
                        }
                        for (const [key, value] of Object.entries(col.meta)) {
                            if (Object.keys(obj).includes(key) == true) {
                                Object.assign(obj, {[key]: value});
                            }
                        }
                        for (const [key, value] of Object.entries(col.links)) {
                            if (Object.keys(obj).includes(key) == true) {
                                Object.assign(obj, {[key]: value});
                            }
                        }
                        Object.assign(obj, {
                            link_href: obj.related != null ? obj.related : '',
                            has_icon: obj.icon_class.length > 0,
                            has_link: obj.related != null ? obj.related.length > 0 : false,
                            has_label: (obj.label.length > 0 && obj.hide_label != true),
                        })

                        items.push(obj);
                    })
                });
                return items;
            },
        },
        methods: {
            dataHasElement(el) {
                return typeof this.data[el] !== 'undefined';
            },
            dataElement(el, defVal = false) {
                return this.dataHasElement(el) ? this.data[el] : defVal;
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('base-page component mounted.')
            }
        }
    }
</script>


