<template>
    <span>
        <div class="card card-settings">
            <div class="card-header">Urbics API</div>
            <div class="card-body">
                <p>In addition to viewing Urbics reports in a browser and downloading them as spreadsheet, text, PDF or JSON files, reports are also available in JSON or XML formats through API calls.  An API token with the ability to view reports is required.  You can create one below: name it something that tells you what it's for, and check the "Report Access via API" box.  A pop-up window will appear to display the token.  <strong>This is the only time you will see it</strong>, so be sure to make a copy and save it someplace safe.  An encrypted version of the token is saved on our servers so Urbics can identify you when you make an API call.  You can make as many tokens as you like, and delete any you aren't using.
                </p>
                <p>For details on making API calls using a token, visit our support page: 
                    <a href="https://urbics.zendesk.com/hc/en-us/articles/360058320012" target="_blank">How to retrieve a report using an API call</a>
                </p>
            </div>
        </div>


        <div class="card card-settings">
            <div class="card-header">Create Tokens</div>
                <div class="card-body">
                    <form role="form">
                        <!-- Token Name -->
                        <div class="form-group row">
                            <label class="col-md-4 col-form-label text-md-right">Token Name</label>

                            <div class="col-md-6">
                                <input type="text" class="form-control" name="name" v-model="form.tokenName"  :class="{'is-invalid': form.errors.has('name')}">

                                <span class="invalid-feedback" v-show="form.errors.has('name')">
                                    @{{ form.errors.get('name') }}
                                </span>
                            </div>
                        </div>
                        <!-- Token Abilities -->
                        <div class="form-group row" v-if="availableAbilities.length > 0">
                            <label class="col-md-4 col-form-label text-md-right">Token Can</label>

                            <div class="col-md-6">
                                <div class="mb-2">
                                    <button class="btn btn-default" @click.prevent="assignAllAbilities" v-if=" ! allAbilitiesAssigned">
                                        <i class="fa fa-btn fa-check"></i> Assign All Abilities
                                    </button>

                                    <button class="btn btn-default" @click.prevent="removeAllAbilities" v-if="allAbilitiesAssigned">
                                        <i class="fa fa-btn fa-times"></i> Remove All Abilities
                                    </button>
                                </div>

                                <div v-for="ability in availableAbilities">
                                    <div class="checkbox">
                                        <label>
                                            <input type="checkbox"
                                                @click="toggleAbility(ability.value)"
                                                :class="{'is-invalid': form.errors.has('abilities')}"
                                                :checked="abilityIsAssigned(ability.value)">

                                                {{ ability.name }}
                                        </label>
                                    </div>
                                </div>

                                <span class="invalid-feedback" v-show="form.errors.has('abilities')">
                                    @{{ form.errors.get('abilities') }}
                                </span>
                            </div>
                        </div>

                        <!-- Create Button -->
                        <div class="form-group row mb-0">
                            <div class="offset-md-4 col-md-6">
                                <button type="submit" class="btn btn-primary"
                                        @click.prevent="create"
                                        :disabled="form.busy">

                                    Create
                                </button>
                            </div>
                        </div>
                    </form>
            </div>
        </div>
        <tokens :urbicsUser="urbicsUser"></tokens>

        <!-- Show Token Modal -->
        <div class="modal" id="modal-show-token" tabindex="-1" role="dialog">
            <div class="modal-dialog modal-lg" v-if="showingToken">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">
                            API Token
                        </h5>
                    </div>

                    <div class="modal-body">
                        <div class="alert alert-warning">
                            Here is your new API token.
                             <strong>This is the only time the token will ever be displayed, so be sure not to lose it!</strong>
                            You may revoke the token at any time from your API settings.
                        </div>

                        <textarea id="api-token" class="form-control"
                                  @click="selectToken"
                                  rows="5">{{ showingToken }}</textarea>
                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click="selectToken">
                        <span v-if="copyCommandSupported">Copy To Clipboard</span>
                        <span v-else>Select All</span>
                        </button>
                        <button type="button" class="btn btn-default" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>

<script>
  export default {
    props: {
      urbicsUser: { Object, required: true }
    },
    data() {
        return {
            showingToken: null,
            allAbilitiesAssigned: false,
            form: new UrbicsForm({
                tokenName: '',
                abilities: []
            }),
        }
    },

    computed: {
        copyCommandSupported() {
            return document.queryCommandSupported('copy');
        },
        availableAbilities() {
            return this.$store.getters.userAbilities
        }
    },



    watch: {
        /**
         * Watch the available abilities for changes.
         */
        availableAbilities() {
            if (this.availableAbilities.length > 0) {
                this.assignDefaultAbilities();
            }
        }
    },


    methods: {
        /**
         * Assign all of the default abilities.
         */
        assignDefaultAbilities() {
            var defaults = _.filter(this.availableAbilities, a => a.default);

            this.form.abilities = _.map(defaults, 'value');
        },


        /**
         * Enable all the available abilities for the given token.
         */
        assignAllAbilities() {
            this.allAbilitiesAssigned = true;

            this.form.abilities = _.map(this.availableAbilities, 'value');
        },


        /**
         * Remove all of the abilities from the token.
         */
        removeAllAbilities() {
            this.allAbilitiesAssigned = false;

            this.form.abilities = [];
        },


        /**
         * Toggle the given ability in the list of assigned abilities.
         */
        toggleAbility(ability) {
            if (this.abilityIsAssigned(ability)) {
                this.form.abilities = _.reject(this.form.abilities, a => a == ability);
            } else {
                this.form.abilities.push(ability);
            }
        },


        /**
         * Determine if the given ability has been assigned to the token.
         */
        abilityIsAssigned(ability) {
            return _.includes(this.form.abilities, ability);
        },


        /**
         * Create a new API token.
         */
        create() {
            axios.post('/settings/api/token', this.form)
            .then(response => {

                this.showToken(response.data.token);

                this.resetForm();

                this.updateTokens();
            })
            .catch(function (error) {
                if (process.env.NODE_ENV !== 'production') {            
                    console.log(error.response)
                }
            })
        },


        /**
         * Display the token to the user.
         */
        showToken(token) {
            this.showingToken = token;
            $('#modal-show-token').modal('show');
        },


        /**
         * Select the token and copy to Clipboard.
         */
        selectToken() {
            $('#api-token').select();

            if (this.copyCommandSupported) {
                document.execCommand("copy");
            }
        },


        /**
         * Reset the token form back to its default state.
         */
        resetForm() {
            this.form.tokenName = '';

            this.assignDefaultAbilities();

            this.allAbilitiesAssigned = false;
        },

        updateTokens() {
            axios.get('settings/api/tokens')
            .then(response => {
                this.$store.commit('updateTokens', {tokens: response.data.tokens, abilities: response.data.abilities});
            })
            .catch(function (error) {
                if (process.env.NODE_ENV !== 'production') {            
                    console.log(error.message)
                }
            })
        }
    }
};
</script>