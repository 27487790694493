<template>
    <div class="row">
        <div class="offset-2 col-8">
            <div class="pricing-card shadow border-secondary h-100">
                <div class="card-body">
                    <div class="top-half">
                        <h1>{{reportType}} Reports</h1>
                        <h1>For</h1>
                        <h1>{{areaName}}</h1>
                        <h1>{{countyName}}</h1>
                        <div class="price-quote">
                            <div class="row price-block mb-0">
                                <div class="col-5 offset-2 text-left">
                                    <h4>{{areaReportsLength}}-{{areaReportsInterval}} Access</h4>
                                </div>
                                <div class="col-3 text-right">
                                    <h4>{{areaReportsPrice}}</h4>
                                </div>
                            </div>
                            <div v-if="hasStatusMessage" class="row price-block mt-0">
                                <div id="title" class="col-10 offset-1 text-left">
                                    <status-alert :statusAlerts="statusMessage"></status-alert>
                                </div>
                            </div>

                            <div class="row price-block mt-0">
                                <div class="col-10 offset-1 text-left">
                                    <h6>
                                    All {{reportType1}} reports will be available to you for this area for {{areaReportsLength}} {{areaReportsInterval}}.</h6>
                                    <h6 v-if="hasParentArea">You will also have access to {{reportType2}} reports for the parent area and other sub-areas belonging to this area.</h6>
                                    <h6 v-if="hasSubAreas">You will also have access to {{reportType2}} reports for the sub-areas of this area.</h6>
                                </div>
                            </div>
<!-- 
                            <div class="col-10 offset-1 text-left">
                                <h5>Purchase {{otherReportType}} reports instead? 
                                    <button @click="toggleReportType" 
                                        class="btn btn-outline-secondary btn-sm ml-3 pb-0 pt-0"
                                    >Yes</button>                    
                                </h5>
                            </div>

 -->                        </div>
                    </div>
                    <div class="bottom-half billing-plan row">
                        <div id="cc-instructions">
                            <status-alert 
                                v-if="hasAlert" 
                                :statusAlerts="alert" 
                                :standalone=true  
                            ></status-alert>
                            <h5>Please enter your payment details. Your card will be charged {{areaReportsPrice}}.</h5>
                            <h5>Access will expire at the end of {{areaReportsLength}} {{areaReportsInterval}}.  You may renew it at any time prior to the end of the period.</h5>
                            <h5 class="pad-top">Have a billing code or note to identify this purchase? Enter it below.</h5>
                            <textarea 
                                v-model="billingMessage" 
                                class="col-12"
                                placeholder="Information will appear on the invoice"
                            ></textarea>
                            <div id="payment-form" :data-secret="clientSecret">
                                <div class="row mb-4">
                                    <div id="payment-element" class="offset-2 col-8">
                                    <!--Stripe.js injects the Payment Element-->
                                    </div>
                                </div>
                                <div class="row">
                                    <button 
                                        @click="processCard" 
                                        class="btn btn-success btn-lg offset-2 col-8"
                                        id="submit"
                                    >
                                        <span v-if="processing">
                                            <i class="fal fa-lg fa-spinner"></i>
                                        </span>
                                        <span v-else>Purchase</span>
                                    </button>
                                </div>
                            </div>  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../mixins/handle-error'
    import { loadStripe } from '@stripe/stripe-js';
    export default {
        name: 'BillingAreaReports',
        props: {
            data: {Object, required: true},
        },
        components: {
            loadStripe,
        },
        data() {
            return {
                alert: this.data.alerts !== 'undefined' ? this.data.alerts : Object,
                clientSecret: this.data.intent.client_secret,
                customer: this.data.customer,
                billingMessage: '',
                paymentMethodId: '',
                processing: false,
                slug_request: this.data.slug_request,
                statusMessage: {status: {message: this.data.status}},
                stripe: Stripe(process.env.MIX_STRIPE_KEY),
                user: this.data.user,
                showAllAccess: false,
            }
        },
        created() {
        },
        computed: {
            elements() {
                return this.stripe.elements({clientSecret: this.clientSecret});
            },
            hasAlert() {
                return Object.keys(this.alert).length > 0;
            },
            hasStatusMessage() {
                return this.statusMessage.status.message.length > 0;
            },
            price() {
                if (this.showAllAccess) {
                    return this.data.products.items.area_reports.price;
                }
                return this.data.price;
            },
            otherPrice() {
                if (this.showAllAccess) {
                    return this.data.price;
                }
                return this.data.products.items.area_reports.price;
            },
            reportType() {
                return this.price.name;
            },
            reportType1() {
                if (this.showAllAccess) {
                    return '';
                }
                return this.reportType.toLowerCase();
            },
            reportType2() {
                return this.reportType.toLowerCase()
            },
            otherReportType() {
                return this.otherPrice.name.toLowerCase();
            },
            priceLookupKey() {
                return this.price.lookup_key;
            },
            areaReportsPriceId() {
                return this.price.stripe_id;
            },
            areaReportsPrice() {
                return this.formatCurrency(this.price.amount);
            },
            areaReportsLength() {
                return this.price.interval_count;
            },
            areaReportsInterval() {
                if (this.areaReportsLength == 1) {
                    return this.price.interval;
                }
                return this.price.interval + 's';
            },
            hasParentArea() {
                return this.user.parentAreaId != 0;
            },
            hasSubAreas() {
                return this.user.hasSubAreas;
            },
            areaID() {
                if (this.user.parentAreaId != 0) {
                    return this.user.parentAreaId;
                }
                return this.user.areaId;
            },
            areaName() {
                if (this.user.parentAreaName.length == 0) {
                    return this.user.areaName;
                }
                return this.user.parentAreaName;
            },
            fullAreaName() {
                return this.areaName + " " + this.countyName;
            },
            countyName() {
                return "(" + this.user.countyName + " County, " + this.user.stateAbbr + ")";
            }
        },
        methods: {
            formatCurrency(value) {
                return '$' + Number(value/100).toFixed(0).toLocaleString();
            },
            toggleReportType() {
                this.showAllAccess = ! this.showAllAccess;
            },
            initialize() {
                const paymentElementOptions = {
                    layout: "tabs",
                    wallets: {
                        applePay: 'never',
                        googlePay: 'never',
                    }
                };

                this.paymentElement = this.elements.create("payment", paymentElementOptions);
                this.paymentElement.mount("#payment-element");            
            },
            redirectToPage(slug) {
                let url = (slug == 'home' ? '/home' : '/reports/' + slug);
                window.location.replace(url);
            },
            cancel() {
                this.redirectToPage('home');
            },
            processCard() {
                if (this.processing) {
                    return;
                }
                this.processing = true;
                this.stripe.confirmSetup({
                    elements: this.elements, 
                        confirmParams: {
                            return_url: window.location.href,
                        },
                        redirect: 'if_required'
                })
                .then(result => {
                    if (result.error) {
                        this.alert = displayAlert(result.error);
                        return;
                    }
                    if (result.setupIntent && result.setupIntent.status =='succeeded') {
                        this.paymentMethodId = result.setupIntent.payment_method;
                        this.createSubscription();
                    }
                })
                .catch(error => {
                    this.processing = false;
                    this.alert = displayAlert(error);
                });

                this.processing = false;
            },
            createSubscription() {
                this.processing = true;
                axios.post('/subscription/create', {
                    lookupKey: this.priceLookupKey,
                    custId: this.customer.id,
                    paymentMethodId: this.paymentMethodId,
                    priceId: this.areaReportsPriceId,
                    planDesc: this.reportType,
                    message: this.billingMessage,
                    areaText: this.fullAreaName,
                    areaId: this.areaID,
                })
                .then(response => {
                    this.processing = false;
                    if (response.data.status_code == 200) {
                        this.redirectToPage(this.slug_request);
                        return;
                    }
                    this.alert = displayAlert({
                        message: response.data.status_message,
                    });
                })
                .catch(error => {
                    this.processing = false;
                    this.alert = displayAlert({
                        message: error.message,
                    });
                });
            },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('billing-area-reports component mounted.')
            }
            this.initialize();
        }
    }
</script>