<template>
    <span>
        <div class="card card-settings">
            <div class="card-header">
                <div class="row">
                    <div class="col-6">Account Subscriptions</div>
                    <div v-if="isAccountAdmin" class="col-6 pad-right">
                        <a 
                            type="button" 
                            class="btn btn-primary"
                            :href="stripeSessionUrl"
                            target="_blank"
                        >
                            <span v-if="processing">
                                <i class="fal fa-lg fa-spinner"></i>
                            </span>
                            <span v-else>Manage Subscriptions</span>
                        </a>
                    </div>
                    <div v-else  class="col-6 pad-right"></div>
                </div>
            </div>
            <div class="card-body-with-table">
                <div class="row section-content">
                    <div class="col-12">
                        <div v-if="! isAccountAdmin">
                            <h5 class="pad-left" v-html="activePlan.account_contact_referral"></h5>
                        </div>
                        <div v-else >
                            <toolbar-alert 
                                v-if="hasAlert" 
                                :messages="alert" 
                                :standalone=true 
                            ></toolbar-alert>                        
                            <table class="table report-table with-bottom-border">
                                <thead>
                                    <tr>
                                        <th class="width-50">Description</th>
                                        <th class="width-25">Status</th>
                                        <th class="width-25"></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-if="! hasSubs">
                                        <td colspan="3" class="report-group">
                                            (No subscriptions were found)
                                        </td>
                                    </tr>
                                    <template v-if="hasSubs">
                                    <template v-for="(subs, subKey) in activeSubs">
                                    <tr>
                                        <td colspan="3" 
                                            class="report-group"
                                            :class="{'first': subKey === Object.keys(activeSubs)[0]}"
                                            >
                                            {{subs.header}}
                                        </td>
                                    </tr>
                                    <tr v-if="! hasSubType(subKey)">
                                        <td colspan="3" class="report-sub-group">
                                            (No subscriptions were found)
                                        </td>
                                    </tr>
                                    <template v-if="subKey == 'activePlans'" v-for="(item, itemKey) in subs.items">
                                        <tr>
                                            <td class="pad-left">
                                                {{item.description}} for {{item.quantity}} subscriber(s)
                                            </td>
                                            <td class="text-left">
                                                <span v-if="item.cancel_at_period_end">
                                                    <span v-if="subKey == 'activeAreas'">
                                                        {{item.interval_count}}-{{item.interval}} Access 
                                                    </span>
                                                    Ends
                                                </span>
                                                <span v-else>Renews</span>
                                                {{item.current_period_end}}
                                            </td>
                                            <td class="text-left"></td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="report-sub-group">
                                                Assigned to Subscription
                                            </td>
                                        </tr>
                                        <tr v-if="! hasActiveUsers(itemKey)">
                                            <td colspan="3" class="report-sub-group">
                                                (None)
                                            </td>
                                        </tr>
                                        <template v-for="(user, userKey) in activeUsersForSub(itemKey)">
                                            <tr>
                                                <td class="pad-left">
                                                    <span v-if="user.id == urbicsUser.userId">
                                                        {{userKey + 1}}. You
                                                    </span>
                                                    <span v-else>
                                                        {{userKey + 1}}. {{user.name}} ({{user.email}})
                                                    </span>
                                                </td>
                                                <td class="text-center">
                                                    <button v-if="user.id != urbicsUser.userId"
                                                        type="button" 
                                                        class="btn text-center btn-outline-primary width-md"
                                                        :disabled="user.id == urbicsUser.userId"
                                                        @click="toggleActiveFlag(itemKey, userKey, user.id, 'deactivate')"
                                                    >
                                                        <span>Deactivate</span>
                                                    </button>
                                               </td>
                                                <td></td>
                                            </tr>
                                        </template>
                                        <template v-if="! userLimitReached(itemKey)">
                                        <tr>
                                            <td colspan="3" class="report-item-group">
                                                Invite a New Subscriber:
                                            </td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="text-left">
                                                <div class="row">
                                                    <div class="offset-1 col-4">
                                                        <input 
                                                            type="text" 
                                                            id="newUserName"
                                                            v-model="newUser.name"
                                                            class="form-control"
                                                            placeholder="(Name)"
                                                            v-bind:class="{'is-invalid': newUser.nameError.length > 0}"
                                                        >
                                                        <span role="alert" class="invalid-feedback">
                                                            <strong>{{newUser.nameError}}</strong>
                                                        </span>
                                                    </div>
                                                    <div class="col-4">
                                                        <input 
                                                            type="text" 
                                                            id="newUserEmail"
                                                            v-model="newUser.email"
                                                            class="form-control"
                                                            placeholder="(Email)"
                                                            v-bind:class="{'is-invalid': newUser.emailError.length > 0}"
                                                        >
                                                        <span role="alert" class="invalid-feedback">
                                                            <strong>{{newUser.emailError}}</strong>
                                                        </span>
                                                    </div>
                                                    <div class="col-3">
                                                        <button
                                                            type="button" 
                                                            class="btn text-center btn-outline-primary width-md"
                                                            :disabled="invitingUser"
                                                            @click="inviteUser(itemKey)"
                                                        >
                                                        <span  v-if="invitingUser">
                                                            <i 
                                                                id="deleteUserIcon" 
                                                                class="fal fa-lg fa-circle-notch fa-spin text-secondary"
                                                            ></i>
                                                        </span>
                                                        <span v-else>Invite</span>
                                                        </button>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        </template>
                                        <tr>
                                            <td colspan="3" class="report-sub-group">
                                                Deactivated
                                            </td>
                                        </tr>
                                        <tr v-if="deactivatedUsers.items.length == 0">
                                            <td colspan="3" class="report-sub-group">
                                                (None)
                                            </td>
                                        </tr>
                                        <template v-for="(user, userKey) in listDeactivatedUsers">
                                            <tr>
                                                <td class="pad-left">
                                                    {{userKey + 1}}. {{user.name}} ({{user.email}})
                                                </td>
                                                <td class="text-center">
                                                    <button
                                                        type="button" 
                                                        class="btn text-center btn-outline-primary width-md"
                                                        :disabled="userLimitReached(itemKey)"
                                                        @click="toggleActiveFlag(itemKey, userKey, user.id, 'activate')"
                                                    >
                                                        <span>Reactivate</span>
                                                    </button>
                                               </td>
                                                <td v-if="user.id == userMarkedForDeletion" class="text-center">
                                                    <span>Are you sure?
                                                        <button
                                                            type="button"
                                                            class="btn text-center"
                                                        >
                                                        <i 
                                                            id="confirmDeletionIcon" 
                                                            class="fal fa-lg fa-thumbs-up text-primary"
                                                            v-on:click="userDeletionConfirmed(user.id)"
                                                        ></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            class="btn text-center"
                                                        >
                                                        <i 
                                                            id="cancelDeletionIcon" 
                                                            class="fal fa-lg fa-thumbs-down text-danger"
                                                            v-on:click="userMarkedForDeletion = 0"
                                                        ></i>
                                                        </button>
                                                    </span>
                                                </td>
                                                <td v-else-if="user.id == deletingUserId" class="text-center">
                                                    <button
                                                        type="button"
                                                        disabled
                                                        class="btn text-center btn-outline-danger width-md"
                                                    >
                                                    <i 
                                                        id="deleteUserIcon" 
                                                        class="fal fa-lg fa-circle-notch fa-spin text-secondary"
                                                    ></i>
                                                    </button>
                                                </td>
                                                <td v-else class="text-center">
                                                    <button
                                                        type="button"
                                                        class="btn text-center btn-outline-danger width-md"
                                                        @click="userMarkedForDeletion = user.id"
                                                    >Delete
                                                    </button>
                                                </td>
                                            </tr>
                                        </template>
                                    </template>
                                    <template v-if="subKey == 'activeAreas'" v-for="(item, itemKey) in subs.items">
                                        <tr>
                                            <td class="pad-left">
                                                {{item.description}}
                                            </td>
                                            <td class="text-left">
                                                <span v-if="item.cancel_at_period_end">
                                                    <span v-if="subKey == 'activeAreas'">
                                                        {{item.interval_count}}-{{item.interval}} Access 
                                                    </span>
                                                    Ends
                                                </span>
                                                <span v-else>Renews</span>
                                                {{item.current_period_end}}
                                            </td>
                                            <td class="text-left"></td>
                                        </tr>

                                    </template>
                                    </template>
                                    </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>
<script>
    import {handleError} from '../../../mixins/handle-error'
    export default {
        props: {
            urbicsUser: { Object, required: true },
        },
        data: function() {
            return {
                alert: Object(),
                hasAlert: false,
                hasSubs: false,
                processing: false,
                stripeSessionUrl: '',
                subs: Object(),
                subUsers: Object(),
                userMarkedForDeletion: 0,
                deletingUserId: 0,
                invitingUser: false,
                newUser: {
                    id: 0,
                    name: '',
                    email: '',
                    nameError: '',
                    emailError: '',
                },
            }
        },
        created() {
            this.alert = Object();
            this.createCustomerPortalSession();
            this.subscriptionsAndUsers();
        },
        watch: {
            alert (newAlert, oldAlert) {
                this.hasAlert = Object.keys(newAlert).length > 0;
            },
        },
        methods: {
            hasSubType(key) {
                return Object.keys(this.activeSubs[key].items).length > 0;
            },
            isCanceled(subKey, itemKey) {
                return this.activeSubs[subKey].items[itemKey].canceled;
            },
            update() {
                Urbics.post('/settings/update-password', this.form);
            },
            createCustomerPortalSession() {
                this.processing = true;
                axios.get('/billing-portal')
                .then(response => {
                    this.processing = false;
                    this.stripeSessionUrl = response.data;
                })
                .catch(error => {
                    this.processing = false;
                    this.alert = handleError({
                        status: 'warning', 
                        message: 'Unable to link to the billing portal at the moment.  Refresh this page and try again', 
                        detail: error.message, 
                        key: 'stripeError'
                    });
                });
            },
            subscriptionsAndUsers() {
                this.hasSubs = false
                axios.get('/subscription/detail-with-users')
                .then(response => {
                    if (response.data.length == 0) {
                        this.subs = {};
                        this.subUsers = {};
                    }
                    else {
                        this.subs = response.data.subs;
                        this.subUsers = response.data.subUsers;
                        this.hasSubs =  Object.keys(this.subs).length > 0;
                    }
                })
                .catch (error => {
                    this.alert = handleError({
                        status: 'warning', 
                        message: 'Unable to retrieve subscription information. ', 
                        detail: error.message, 
                        key: 'stripeError'
                    });
                });
            },
            toggleActiveFlag(subId, userKey, userId, status) {
                axios.post('/account/' + status + '-user', {
                    id: userId,
                    subId: subId,
                    userId: userId
                })
                .then(response => {
                    if (response.data.status_code) {
                        this.alert = handleError({
                            status: 'warning', 
                            message: 'Unable to create a new subscriber. ',
                            detail: response.data.status_message, 
                            key: 'urbicsError'
                        })
                        this.subscriptionsAndUsers();
                    } else {
                        this.subs = response.data.subs;
                        this.subUsers = response.data.subUsers;
                        this.hasSubs =  Object.keys(this.subs).length > 0;
                    }
                })
                .catch (error => {
                    this.alert = handleError({
                        status: 'warning', 
                        message: 'Unable to ' + status + ' this subscriber. ', 
                        detail: error.message, 
                        key: 'stripeError'
                    });
                });
            },            
            updateSub(subId, updates) {
                let type = (this.subs.active.plan[subId] ? 'plan' : 'area');
                Object.keys(updates).forEach(key => {
                    this.subs.active[type][subId][key] = updates[key];
                })
            },
            userLimitReached(subId) {
                if (! this.hasActiveUsers(subId)) {
                    return false;
                }
                let type = (this.subs.active.plan[subId] ? 'plan' : 'area');

                return (
                    this.subs.active[type][subId].currently_subscribed >=
                    this.subs.active[type][subId].quantity 
                );
            },
            hasActiveUsers(subId) {
                if (! this.activeUsers.items
                    || ! this.activeUsers.items[subId]) {
                    return false;
                }
                return Object.keys(this.activeUsers.items[subId]).length > 0;
            },
            activeUsersForSub(subId) {
                if (! this.activeUsers.items
                    || ! this.activeUsers.items[subId]) {
                    return {};
                }
                return this.activeUsers.items[subId];
            },
            clearNewUser() {
                this.newUser.id = 0;
                this.newUser.name = '';
                this.newUser.email = '';
                this.newUser.nameError = '';
                this.newUser.emailError = '';
            },
            inviteUser(subId) {
                this.newUser.nameError = '';
                this.newUser.emailError = '';
                if (this.newUser.name.length < 6) {
                    this.newUser.nameError = 'Must be at least six characters long';
                    return;
                }
                for (const [typeKey, type] of Object.entries(this.subUsers)) {
                    for (const [subKey, sub] of Object.entries(type)) {
                        for (const [userKey, user] of Object.entries(sub)) {
                            if (this.newUser.id != user.id) {
                                if (this.newUser.name == user.name) {
                                    this.newUser.nameError = 'This name is already in use.';
                                    return;
                                }
                                if (this.newUser.email == user.email) {
                                    this.newUser.emailError = 'This email is already in use.';
                                    return;
                                }
                            }

                        }
                    }
                }
                let regEx = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
                if (! regEx.test(this.newUser.email)) {
                    this.newUser.emailError = 'Must be a valid email address.';
                    return;
                }
                this.inviteNewUser(subId);
                this.clearNewUser();
                // this.subscriptionsAndUsers();
            },
            inviteNewUser(subId) {
                this.invitingUser = true;
                console.log(subId);
                axios.post('/account/invite-account-user', {
                    accountId: this.urbicsUser.accountId,
                    name: this.newUser.name,
                    email: this.newUser.email,
                    subId: subId
                })
                .then(response => {
                    if (response.data.status_code) {
                        this.alert = handleError({
                            status: 'warning', 
                            message: 'Unable to create a new subscriber. ',
                            detail: response.data.status_message, 
                            key: 'urbicsError'
                        })
                        this.subscriptionsAndUsers();
                    } else {
                        this.subs = response.data.subs;
                        this.subUsers = response.data.subUsers;
                        this.hasSubs =  Object.keys(this.subs).length > 0;
                    }
                    this.invitingUser = false;
                })
                .catch (error => {
                    this.alert = handleError({
                        status: 'warning', 
                        message: 'Unable to create a new subscriber. ', 
                        detail: error.message, 
                        key: 'urbicsError'
                    });
                    this.invitingUser = false;
                });
            },
            userDeletionConfirmed(userId) {
                this.deleteUser(userId);
            },
            deleteUser(userId) {
                this.deletingUserId = userId;
                this.userMarkedForDeletion = 0;
                axios.post('/account/delete-account-user', {
                    userId: userId
                })
                .then(response => {
                    this.deletingUserId = 0;
                    if (response.data.status_code) {
                        this.alert = handleError({
                            status: 'warning', 
                            message: 'Unable to create a new subscriber. ',
                            detail: response.data.status_message, 
                            key: 'urbicsError'
                        })
                        this.subscriptionsAndUsers();
                    } else {
                        this.subs = response.data.subs;
                        this.subUsers = response.data.subUsers;
                        this.hasSubs =  Object.keys(this.subs).length > 0;
                    }
                })
                .catch (error => {
                    this.deletingUserId = 0;
                    this.alert = handleError({
                        status: 'warning', 
                        message: 'Unable to delete this subscriber. ', 
                        detail: error.message, 
                        key: 'urbicsError'
                    });
                });
            },
            eventListener() {
                Echo.private('subscriptions.' + this.urbicsUser.accountId)
                    .listen('SubscriptionUpdated', (e) => {
                        this.updateSub(
                            e.subscription.stripe_id, {
                                cancel_at_period_end: e.subscription.ends_at != null,
                                status: e.subscription.stripe_status,
                                ends_at: e.subscription.ends_at,
                                name: e.subscription.name,
                                quantity: e.subscription.quantity,
                        });
                    });
            }
        },
        computed: {
            activePlan() {
                if (this.subs.active === undefined) {
                    return {};
                }
                return this.subs.active.plan[Object.keys(this.subs.active.plan)[0]];
            },
            isAccountAdmin() {
                return this.urbicsUser.isAccountAdmin;
            },
            activeSubs() {
                let plans = {
                    'activePlans': {
                        'header': 'Current Site Subscriptions',
                        'items': this.subs.active.plan,
                    }
                }
                let areas = {
                    'activeAreas': {
                        'header': 'Current Area Access Subscriptions',
                        'items': this.subs.active.area,
                    }
                }
                if (this.hasProfessionalPlan) {
                    return Object.assign(plans, areas);
                }

                return plans;
            },
            activeUsers() {
                return {
                    'items': this.subUsers.activated,
                }
            },
            deactivatedUsers() {
                return {
                    'items': this.subUsers.deactivated,
                }
            },
            listDeactivatedUsers() {
                return this.deactivatedUsers.items.unsubscribed;
            },
            hasProfessionalPlan() {
                for (const [key, value] of Object.entries(this.subs.active.plan)) {
                    if (value.name.startsWith('pro')) {
                        return true;
                    }
                }
                return false;
            }
        },
        mounted() {
            this.eventListener();
            if (process.env.NODE_ENV !== 'production') {
                console.log('subscriptions-professional component mounted.')
            }
        },
    }
</script>


