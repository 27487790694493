<template>
    <div class="row">
        <div class="offset-2 col-8">
            <div class="pricing-card shadow border-secondary h-100">
                <div class="card-body">
                    <div class="top-half">
                        <h1>Professional Plans</h1>
                        <div class="price-quote">
                            <div v-for="(item, index) in planList" class="row price-block">
                                <div class="col-1 offset-2">
                                    <a href="javascript:void(0)">
                                        <i class="text-center" :class="checkPlan(index)"
                                            v-on:click="selectPlan(index)"
                                            area-label="close"
                                        ></i>
                                    </a>
                                </div>
                                <div class="col-4 text-left">
                                    <h4>{{item.term}}</h4>
                                </div>
                                <div class="col-3 text-right">
                                    <h4>{{item.price}}</h4>
                                </div>
                            </div>
                            <div class="row price-block">
                                <div class="col-12 text-left">
                                    <h6>Analytic reports are an additional {{areaReportsPrice}} per area for {{areaReportsLength}} {{areaReportsInterval}}s with a paid subscription.</h6>
                                    <h6>{{trialLength}}-day free trials are available to first-time subscribers and include sample analytic reports<span v-if="isOnTrial"> (you are currently on a free trial)</span>.
                                    </h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-half billing-plan row">
                        <div v-if="planSelected == 'trial'" class="col-12" id="cc-instructions">
                            <button @click="startTrial" class="btn btn-success btn-lg btn-block">
                                <span v-if="processing == true">
                                    <i class="fal fa-lg fa-spinner"></i>
                                </span>
                                <span v-else>Start a Free Trial</span>
                            </button>
                        </div>
                        <div v-else class="col-12" id="cc-instructions">
                            <toolbar-alert 
                                v-if="hasAlert" 
                                :messages="alert" 
                                :standalone=true  
                            ></toolbar-alert>
                            <h5>Please enter your payment details. Your card will be charged {{selectedPrice}}.</h5>
                            <h5>The subscription will renew {{selectedPeriod.toLowerCase()}}. You may cancel the renewal at any time.
                            </h5>
                            <stripe-element-card
                                ref="cardElement"
                                :pk="stripe_key"
                                :testMode="useTestMode"
                                @token="tokenCreated"
                            ></stripe-element-card>
                            <div class="row">
                                <div class="col-12">
                                <button @click="startPlan" class="btn btn-success btn-lg btn-block"
                                    data-secret="intent.client_secret"
                                >
                                    <span v-if="processing == true">
                                        <i class="fal fa-lg fa-spinner"></i>
                                    </span>
                                    <span v-else>Subscribe</span>
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {handleError} from '../../mixins/handle-error'
    // import { StripeElementCard } from '@vue-stripe/vue-stripe';
    import { loadStripe } from '@stripe/stripe-js';
    export default {
        props: {
            data: {Object, required: true},
        },
        components: {
            loadStripe,
        },
        data() {
            return {
                alert: Object(),
                customer: this.data.customer,
                errorMsg: '',
                intent: this.data.intent,
                paymentMethodId: '',
                period: this.data.plan.endsWith('year') ? 'Yearly' : 'Quarterly',
                plan: this.data.plan,
                planSelected: 'yearly',
                plan_desc: this.data.products.subscriptions[this.data.plan],
                price_id: '',
                processing: false,
                products: this.data.products,
                setup_intent: null,
                showPriceDetail: false,
                slug_request: this.data.slug_request,
                stripe: Stripe(process.env.MIX_STRIPE_KEY),
                stripe_key: process.env.MIX_STRIPE_KEY,
                user: this.data.user,
            }
        },
        created() {
        },
        watch: {
        },
        computed: {
            hasAlert() {
                return Object.keys(this.alert).length > 0;
            },
            yearlyPlan() {
                return this.products.subscriptions.pro_plan_year;
            },
            quarterlyPlan() {
                return this.products.subscriptions.pro_plan_quarter;
            },
            trialPlan() {
                return this.products.subscriptions.pro_plan_trial;
            },
            areaReports() {
                return this.products.items.area_reports;
            },
            planList() {
                let plans = {
                    yearly: {
                        price: this.yearlyPrice, 
                        term: 'Yearly', 
                        price_id: this.yearlyPriceId,
                        name: this.yearlyPlan.display_name,
                        lookup_key: 'pro_plan_year',
                    },
                    quarterly: {
                        price: this.quarterlyPrice, 
                        term: 'Quarterly', 
                        price_id: this.quarterlyPriceId,
                        name: this.quarterlyPlan.display_name,
                        lookup_key: 'pro_plan_quarter',
                    },
                    trial: {
                        price: this.trialPrice, 
                        term: 'Free Trial', 
                        price_id: this.trialPriceId,
                        name: this.trialPlan.display_name,
                        lookup_key: 'pro_plan_trial',
                    },
                };
                if (this.hideFreeTrial) {
                    delete plans.trial;
                }

                return plans;
            },
            yearlyPrice() {
                return this.formatCurrency(this.yearlyPlan.price.amount);
            },
            quarterlyPrice() {
                return this.formatCurrency(this.quarterlyPlan.price.amount);
            },
            trialPrice() {
                return this.formatCurrency(this.trialPlan.price.amount);
            },
            yearlyPriceId() {
                return this.yearlyPlan.price.stripe_id;
            },
            quarterlyPriceId() {
                return this.quarterlyPlan.price.stripe_id;
            },
            trialPriceId() {
                return this.trialPlan.price.stripe_id;
            },
            areaReportsPrice() {
                return this.formatCurrency(this.areaReports.price.amount);
            },
            areaReportsLength() {
                return this.areaReports.price.interval_count;
            },
            areaReportsInterval() {
                return this.areaReports.price.interval;
            },
            trialLength() {
                return this.user.trialLength;
            },
            selectedPrice() {
                return this.planList[this.planSelected].price;
            },
            selectedPriceId() {
                return this.planList[this.planSelected].price_id;
            },
            selectedPeriod() {
                return this.planList[this.planSelected].term;
            },
            selectedName() {
                return this.planList[this.planSelected].name;
            },
            selectedLookupKey() {
                return this.planList[this.planSelected].lookup_key;
            },
            hideFreeTrial() {
                return (this.user.hasTrialed);
            },
            isOnTrial() {
                return (this.user.isOnTrial);
            },
            reportPrices() {
                return this.products.items;
            },
            useTestMode() {
                return this.data.environment == 'local';
            }
        },
        methods: {
            displayAlert(error) {
                this.alert = handleError({
                    status: (error.status ? error.status :'warning'), 
                    message: 'Unable to create a new area subscription. '
                        + (error.message ? error.message : ''), 
                    detail: ((error.response 
                            && error.response.error 
                            && error.response.error.message) 
                        ? error.response.error.message : ''),
                    key: (error.key ? error.key : 'urbicsError'),
                    response: error.response,
                    request: error.request,
                });
            },
            formatCurrency(value) {
                return '$' + Intl.NumberFormat('en-US').format(Number(value/100));
            },
            selectPlan(plan)
            {
                this.planSelected = plan;
            },
            checkPlan(plan) {
                if (this.planSelected === plan) {
                   return this.setCheckIcon('check-circle'); 
                }
                return this.setCheckIcon('circle');
            },
            setCheckIcon(icon) {
                return 'text-success fal fa-lg fa-' + icon;
            },
            redirectToPage(slug) {
                let url = (slug == 'home' ? '/home' : '/reports' + slug);
                let err = (this.errorMsg != '' ? '?error=' + this.errorMsg : '');
                window.location.replace(url + err);
            },
            startTrial() {
                this.createSubscription();
            },
            startPlan() {
                this.processing = true;
                this.$refs.cardElement.submit();
            },
            tokenCreated (token) {
                this.processing = true;
                let result = this.stripe.createPaymentMethod({
                    type: 'card',
                    card: {token: token.id},
                    billing_details: {
                        name: this.customer.name,
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.processing = false;
                        this.displayAlert({
                            message: result.error.message,
                        });
                    } else {
                        this.paymentMethodId = result.paymentMethod.id;
                        this.createSubscription();
                    }
                })
                .catch(error => {
                    this.processing = false;
                    this.displayAlert({
                        message: error.message,
                    });
                })
            },
            createSubscription() {
                this.processing = true;
                axios.post('/subscription/create', {
                    lookupKey: this.selectedLookupKey,
                    custId: this.customer.id,
                    paymentMethodId: this.paymentMethodId,
                    priceId: this.selectedPriceId,
                    planDesc: this.selectedPlanName,
                })
                .then(response => {
                    this.processing = false;
                    if (response.data.status_code == 200) {
                        this.redirectToPage('home');
                        return;
                    }
                    this.displayAlert({
                        message: response.data.status_message,
                    });
                })
                .catch(error => {
                    this.processing = false;
                    this.displayAlert({
                        message: error.message,
                    });
                });
            },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>