export function countDisplayLines(data) {
    let groupID = 0,
        items = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i].group_id != groupID) {
            groupID = data[i].group_id;
            items++;
        }
        items++;
    }

    return items;
}

export function formatReportList(rawData) {
    let displayList = [[],[]];
    let itemCount = countDisplayLines(rawData);
    let maxCols = 2;
    let colMax = itemCount / maxCols;
    let group = '';
    for (let i = 0, j = 0, k = 0; i < rawData.length; i++) {
        // Report group name
        let first = (k == 0 ? ' first ' : '');
        if ((rawData[i].report_group_name.length > 0) 
            && (rawData[i].report_group_name == group)
            && (k == 0) 
            && (j > 0)
        ) {
            displayList[j].push({
                name: rawData[i].report_group_name + " (Cont.)",
                class: "report-group " + first,
                slug: '',
                reportId: '',
                latestData: rawData[i].latest_data,
                accessExpires: rawData[i].access_expires,
                subscriptionKey: rawData[i].subscription_key,
                isTabbed: rawData[i].is_tabbed,
                isTab: rawData[i].is_tab,
            });
            k++;
        }
        if ((rawData[i].report_group_name.length > 0) 
                && (rawData[i].report_group_name != group)) {
            displayList[j].push({
                name: rawData[i].report_group_name,
                class: "report-group " + first,
                slug: '',
                reportId: '',
                latestData: rawData[i].latest_data,
                accessExpires: rawData[i].access_expires,
                subscriptionKey: rawData[i].subscription_key,
                isTabbed: rawData[i].is_tabbed,
                isTab: rawData[i].is_tab,
            });
            k++;
            group = rawData[i].report_group_name;
        }

        displayList[j].push({
            name: rawData[i].report_name,
            class: 'report-item',
            slug: rawData[i].slug_name,
            reportId: rawData[i].report_id,
            latestData: rawData[i].latest_data,
            accessExpires: rawData[i].access_expires,
            subscriptionKey: rawData[i].subscription_key,
            isTabbed: rawData[i].is_tabbed,
            isTab: rawData[i].is_tab,
        });
        ++k;
        if (k + 1 >= colMax && j < (maxCols - 1)) {
            j = j + 1; 
            k = 0;
        }
    }
    return displayList;
}