import 'es6-promise/auto';
import axios from "axios";
import Vue from 'vue'
import Vuex from 'vuex'
import batchCheckbox from './modules/batch-checkbox'
import apiTokens from './modules/api-tokens'
import manageReportingAreas from './modules/manage-reporting-areas'
import alertHandler from './modules/alert-handler'
import subscriptionInfo from './modules/subscription-info'
/*
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    batchCheckbox: batchCheckbox,
    apiTokens: apiTokens,
    manageReportingAreas: manageReportingAreas,
    alertHandler: alertHandler,
    subscriptionInfo: subscriptionInfo,
  },
  strict: process.env.NODE_ENV !== 'production'
})
*/
import { createStore } from "vuex";

export const store = createStore({
    state: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
      batchCheckbox: batchCheckbox,
      apiTokens: apiTokens,
      manageReportingAreas: manageReportingAreas,
      alertHandler: alertHandler,
      subscriptionInfo: subscriptionInfo,
    },
    strict: process.env.NODE_ENV !== 'production'
})