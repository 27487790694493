<template>
    <div class="col-md-12 report-card shadow border-secondary">
	    <div class="card-body">
            <div class="section-title">
            	Reports
        	</div>
            <div class="sub-section-title text-center mb">
                Viewing reports for:<br/>
                <span v-if="user.parentAreaName.length > 0">
                    {{ user.parentAreaName }}<br/>
                </span>
                {{ user.areaName }} ({{ user.fullCountyName }})
            </div>
	        <div class="row section-content">
	            <div v-for="pos in [0, 1]" class="col-md-6">
	                <ul class="report-list list-unstyled list-group">
	                    <li v-for="(col, index) in displayList[pos]"
                            :key="index" 
                            class="" 
                            :class="col.class">
                                <span v-if="isGroupHeader(col)">{{ col.name }}</span>
                                <span v-else-if="isFreeReport(col)">
                                    <i 
                                    class="fas fa-check-circle text-info pad-right"
                                    aria-hidden="true" aria-label="Free"
                                    ></i>
                                    <a :href="url(col.slug)">
                                        {{ col.name }}
                                    </a>
                                </span>
                                <span v-else-if="isSubscribed(col)">
                                    <i 
                                    class="fas fa-check-circle text-success pad-right" 
                                    aria-hidden="true" aria-label="Free"
                                    ></i>
                                    <a :href="url(col.slug)">
                                        {{ col.name }}
                                    </a>
                                </span>
                                <span v-else>
                                    <input 
                                        class="form-check-inline pad-right"
                                        type="checkbox"
                                        :value="col.reportId"
                                        :id="col.reportId"
                                        :aria_label="col.name"
                                        v-model="selectedReports">
                                    {{ col.name }}
                                </span>
	                    </li>
	                </ul>
	            </div>
	        </div>
            <div class="row section-content">
                <div class="offset-4 col-4 text-center" >
                    <button 
                        type="button" 
                        class="btn btn-md "
                        :class="buttonState"
                        @click="togglePayment()"
                        :disabled="! hasSelections"
                    >
                    Purchase Reports
                    </button>
                </div>
            </div>
	    </div>
	</div>
</template>
<script>
    import {handleError} from '../../../mixins/handle-error'
    import {formatReportList} from '../../../mixins/format-report-list'
    export default {
        name: 'ReportDownloadSelector',
        props: {
            user: Object
        },
        data: function() {
            return {
                showPaymentWidget: false,
                selectedReports: [],
                displayList: [],
                rawData: [],
            }
        },
        computed: {
            buttonState() {
                if (this.hasSelections == true) {
                    return 'btn-primary';    
                }
                return 'btn-outline-secondary disabled';
            },
            hasSelections() {
                return this.selectedReports.length != 0;

            },
        },
        methods: {
            colOutput(col) {
                return this.output.filter( function(item) {
                    return item.column == col;
                })
            },
            isGroupHeader(col) {
                return col.reportId == '';
            },
            isFreeReport(col) {
                if (col.subscriptionKey !== null) {
                    return col.subscriptionKey.slice(-4) == 'free';
                }
                // Allows access for admins to non-subscription admin reports.
                return (this.user.isAdmin == 1) ;
            },
            isSubscribed(col) {
                return col.accessExpires !== null;
            },
            togglePayment() {
                this.showPaymentWidget = ! this.showPaymentWidget;
            },
            loadData() {
                axios.post('/reports/list-reports', {
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.clearAll();
                        return;
                    } 
                    this.rawData = response.data;
                    this.displayList = formatReportList(response.data);
                })
                .catch(error => {
                    handleError(error);
                });
            },
            url(slug) {
                return "/reports/" + slug;
            }
        },
        created() {
        	console.log('report-selector-paid created');
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('report-selector-paid component mounted.')
            }
            this.loadData();
        }
    }

</script>