<template>
    <div class="text-md-center">
        <em>Include this area in my portfolio</em>
        <a class="pad-left align-middle">
        <i id="addAreaToggleIcon" 
            v-bind:class="addAreaIcon" 
            v-on:click="toggleAddArea"
        ></i>
        </a>
    </div>
</template>
<script>
    export default {
        props: {
            fullAreaId: String,
            isAreaInPortfolio: Boolean
        },
        data() {
            return {
                addingArea: false,
                hasArea: this.isAreaInPortfolio,
            }
        },
        created() {
        },
        computed: {
            addAreaIcon() {
                return (this.addingArea ? "fal fa-spinner fa-spin fa-lg"
                        : (this.hasArea ? "fal fa-toggle-on fa-lg text-success" 
                        : "fal fa-toggle-off fa-lg text-success"));
            },
        },
        methods: {
            toggleAddArea() {
                const url = '/areas/' + (this.hasArea ? 'remove-from' : 'add-to') + '-portfolio/' + this.fullAreaId;
                var self = this;
                axios.post(url)
                .then(response => {
                    self.hasArea = (!self.hasArea);
                })
                .catch (response => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        this.errorMessage += ' The error message is: ' + response.data;
                    }
                })
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('add-portfolio-area-toggle component mounted.')
            }
        }
    }
</script>


