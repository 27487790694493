<template>
    <div class="page-content">
        <div class="row" v-if="errors">
            <div class="col-md-12">
                <ul class="alert list-unstyled" v-bind:class="errors.type">
                    <li v-for="item in errors.description">{{ item.message }}</li>
                </ul>
            </div>
        </div>
        <div class="row page-title">
            <div class="col-md-12 title-text">Edit Area Attributes Associated With This CDIAC Report</div>
        </div>
        <div class="row list-table">
            <div class="col-md-6">
                <ul class="report-list list-unstyled">
                    <li class="padLeft first">
                        <div class="form-group">
                            <span>Linked Urbics Area: {{ areaName }}</span>
                        </div>
                    </li>
                    <li class="padLeft">
                        <div class="form-group dropdown">
                            <label for="areaSelect" class="control-label">Find an Area:</label>
                            <v-select 
                                id="areaSelect" 
                                class="vs-quick-search"
                                name="areaSelect" 
                                v-model="currentArea"
                                label="area_name_full"
                                :options="areaList"
                                :value="areaList.area_id"
                            ></v-select>
                        </div>
                    </li>
                </ul>
            </div>
            <div class="col-md-6">
                <ul class="report-list list-unstyled">
                    <li class="padLeft first">
                        <div class="form-group">
                            <label for="current_cusip" class="control-label">CUSIP: </label>
                            <span id="current_cusip" class="padLeft">
                                <input v-model="cusipID" placeholder="Enter CUSIP">
                            </span>
                        </div>
                    </li>
                    <li class="padLeft first">
                        <div class="form-group">
                            <label for="current_emma" class="control-label">EMMA: </label>
                            <span id="current_emma" class="padLeft">
                                <input v-model="emmaID" placeholder="Enter EMMA ID">
                            </span>
                        </div>
                    </li>
                </ul>
            </div>
        </div>
        <div class="row list-footer form-group">
            <div class="card col-md-2 offset-md-2 text-center shadow-none bg-transparent">
                <button 
                    v-on:click="saveData" 
                    class="btn btn-success"
                    :disabled="!canSaveData"
                >Save</button>
            </div>
            <div class="card col-md-2 offset-md-2 text-center shadow-none bg-transparent">
                <button 
                    v-on:click="unlinkArea" 
                    class="btn btn-success"
                    :disabled="!canUnlinkArea"
                >Unlink</button>
            </div>
            <div class="card col-md-2 offset-md-2 text-center shadow-none bg-transparent">
                <a v-bind:href="cancelURL" class="btn btn-info">Cancel</a>
            </div>
        </div>
        <div class="row list-table">
            <div class="col-md-6 table-responsive">
                <table class="table report-table" cellspacing="0" cellpadding="0" width="100%">
                    <thead>
                        <tr>
                            <th colspan="2" class="column-heading">Issuer Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in cdiacDisplayItems">
                            <td :class="item.labelClass">{{ item.label }}</td>
                            <td :class="item.valueClass" v-if="item.isLink"><a :href="item.url" :target="item.linkTarget" :rel="item.linkRel">{{ item.linkText }}</a></td>
                            <td :class="item.valueClass" v-else>{{ item.value }}</td>
                        </tr>
                    </tbody>
                </table>
                <table class="table report-table" cellspacing="0" cellpadding="0" width="100%">
                    <thead>
                        <tr>
                            <th colspan="2" class="column-heading">Annual Debt Transparency Report</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td class="item-label padLeft">[To be added]</td>
                            <td class="padRight"></td>
                        </tr>

<!--                         <tr v-for="item in cdiacAdtrAuthDisplayItems">
                            <td :class="item.labelClass">{{ item.label }}</td>
                            <td :class="item.valueClass" v-if="item.isLink"><a :href="item.url" :target="item.linkTarget" :rel="item.linkRel">{{ item.linkText }}</a></td>
                            <td :class="item.valueClass" v-else>{{ item.value }}</td>
                        </tr>
 -->                    </tbody>
                </table>
                <table class="table report-table" cellspacing="0" cellpadding="0" width="100%">
                    <thead>
                        <tr>
                            <th colspan="2" class="column-heading">Mello Roos Yearly Fiscal Statut Report</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr >
                            <td class="item-label padLeft">[To be added]</td>
                            <td class="padRight"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-6 table-responsive">
                <table class="table report-table" cellspacing="0" cellpadding="0" width="100%">
                    <thead>
                        <tr>
                            <th colspan="2" class="column-heading">Urbics Area Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="item in urbicsDisplayItems">
                            <td :class="item.labelClass">{{ item.label }}</td>
                            <td :class="item.valueClass">{{ item.value }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: ['data', 'cdiac', 'config'],
        data: function() {
            return {
                areaID: 0,
                areaList: [],
                areaName: 'This CDIAC record is not linked to an area.',
                areaMetrics: [],
                cancelURL: '',
                cdiacDisplayItems: [],
                cdiacRecord: [],
                cdiacAdtrAuthDisplayItems: [],
                cdiacAdtrFundBalancesDisplayItems: [],
                cdiacAdtrProceedsSpentDisplayItems: [],
                countyID: 0,
                currentArea: [],
                cusipID: '',
                emmaID: '',
                errors: [],
                saveURL: '',
                searchLimit: 1000,
                typeID: 0,
                urbicsDisplayItems: [],
            }
        },
        created() {
            this.initialize();
// http://urbicsv2.test/admin/cdiac-data/edit/1984-0134            
        },
        watch: {
            currentArea: function() {
                if (this.currentArea === undefined ) {
                    this.currentArea = [];
                    this.areaName = 'This CDIAC record is not linked to an area.';
                    this.areaID = 0;
                } else if (this.currentArea === null ) {
                    this.currentArea = [];
                    this.areaName = 'This CDIAC record is not linked to an area.';
                    this.areaID = 0;
                } else if (this.currentArea.area_id === null ) {
                    this.areaName = 'This CDIAC record is not linked to an area.';
                    this.areaID = 0;
                } else {
                    this.areaName = this.currentArea.area_name_full;
                    this.areaID = this.currentArea.area_id;
                }
            },
            areaID: function(newAreaID, oldAreaID) {
                this.getAreaMetrics();
            }
        },
        methods: {
            initialize() {
                this.cdiacRecord = this.cdiac[0];
                this.areaName = this.cdiacRecord.urbics_area_name;
                if (this.cdiacRecord.urbics_area_id > 0) {
                    this.areaID = this.cdiacRecord.urbics_area_id ;
                }
                this.currentArea = {
                    area_id: this.cdiacRecord.urbics_area_id, 
                    area_name_full: this.cdiacRecord.urbics_area_name, 
                };
                this.countyID = this.cdiacRecord.urbics_county_id;
                this.typeID = this.cdiacRecord.urbics_type_id;
                this.cdiacID = this.cdiacRecord.cdiac_id;
                this.cusipID = this.cdiacRecord.cusip_id;
                this.emmaID = this.cdiacRecord.emma_id;
                this.cdiacDisplayItems = this.cdiacItems;
                if (this.config.form_routes.submit.length > 0) {
                    this.saveURL = '/' + this.config.form_routes.submit + '/' + this.config.model_id;
                } else {
                    this.saveURL = '';
                }
                if (this.config.form_routes.cancel.length > 0) {
                    this.cancelURL = '/' + this.config.form_routes.cancel;
                } else {
                    this.cancelURL = '';
                }
                if (this.cdiacRecord.urbics_area_id > 0) {
                    this.getAreaMetrics();
                }
                this.getAreas();
            },
            getAreas() {
                var vm = this;
                axios.post('/dbd/cdiac-area-search', {
                    limit: this.searchLimit,
                    county_id: this.countyID,
                    type_id: this.typeID,
                })
                .then(response => {
                    if (response.data.length == 0) {
                        vm.areaList.push({
                            area_id: 0, 
                            area_name_full: "Nothing was found", 
                            class: 'report-sub-group disabled', url: '#'
                        });
                    }
                    else {
                        vm.areaList = response.data;
                    }
                })
                .catch (response => {
                    vm.errors.type = 'alert-warning';
                    vm.errors.description = [{message: 'An error occurred while processing this page.'}];
                    if (process.env.NODE_ENV !== 'production') {
                        vm.errors.description.push({message:' The error message is: ' + response.data});
                    }
                });
            },
            getAreaMetrics() {
                if (this.areaID == 0) {
                    this.areaMetrics = [];
                    this.urbicsDisplayItems =  this.urbicsItems;
                    return;
                }
                var vm = this;
                axios.post('/dbd/cdiac-area-metrics', {
                    area_id: this.areaID,
                    year: this.config.year,
                })
                .then(response => {
                    if (response.data[0].area_id == 0) {
                        vm.areaMetrics = {
                            label: 'No Urbics data Found', 
                            labelClass: 'item-label padLeft',
                            value: 0,
                            valueClass: '',
                        };
                    } else {
                        vm.areaMetrics = response.data;
                        vm.urbicsDisplayItems =  vm.urbicsItems;
                    }
                })
                .catch (response => {
                    vm.errors.type = 'alert-warning';
                    vm.errors.description = [{message: 'An error occurred while processing this page.'}];
                    if (process.env.NODE_ENV !== 'production') {
                        vm.errors.description.push({message:' The error message is: ' + response.data});
                    }
                });
            },
            saveData() {
                if (this.areaID == 0) {
                    return;
                }
                var vm = this;
                axios.post('/dbd/cdiac-area-linkage', {
                    area_id: this.areaID,
                    cdiac_id: this.cdiacRecord.cdiac_id,
                    cusip_id: this.cusipID,
                    emma_id: this.emmaID,
                })
                .then(response => {
                    if (response.data.length == 0) {
                        vm.errors.type = 'alert-info';
                        vm.errors.description = [{message: 'An error occurred while processing this page.'}];
                    } else {
                        if (response.data['status_code'] == 200) {
                            vm.errors.type = 'alert-info';
                            vm.errors.description = [{message: response.data['status_message']}];
                            vm.cdiacRecord.urbics_area_name = vm.areaName;
                            vm.cdiacRecord.urbics_area_id = vm.areaID;
                            vm.cdiacDisplayItems[0].value = vm.areaName;
                            vm.cdiacDisplayItems[1].value = vm.cusipID;
                            vm.cdiacDisplayItems[3].value = vm.emmaID;
                        }

                        if (response.data['status_code'] == 400) {
                            vm.errors.type = 'alert-warning';
                            vm.errors.description = [{message: response.data['status_message']}];
                        }
                    }
                })
                .catch (error => {
                    vm.errors.type = 'alert-warning';
                    vm.errors.description = [{message: 'An error occurred while processing this page.'}];
                    if ((typeof error.response === 'undefined') && (process.env.NODE_ENV !== 'production')) {
                        console.log(error);
                    } else if ((process.env.NODE_ENV !== 'production') && (error.response.status == 500)) {
                        vm.errors.description.push({message: error.response.data.message});
                        vm.errors.description.push({message: "In file " + error.response.data.file});
                        vm.errors.description.push({message: "At line " + error.response.data.line});
                    }
                });
            },
            unlinkArea() {
                if (!this.canUnlinkArea) {
                    return;
                }
                var vm = this;
                axios.post('/dbd/cdiac-area-linkage', {
                    cdiac_id: this.cdiacID,
                    area_id: null,
                    cusip_id: null,
                    emma_id: null,
                })
                .then(response => {
                    if (response.data.length == 0) {
                        vm.errors.type = 'alert-info';
                        vm.errors.description = [{message: 'An error occurred while processing this page.'}];
                    } else {
                        vm.errors.type = response.data['status_code'] == 200 ? 'alert-info' : 'alert-warning';
                        vm.errors.description = [{message: response.data['status_message']}];
                        vm.areaMetrics = [];
                        vm.urbicsDisplayItems =  vm.urbicsItems;
                        vm.areaName = null;
                        vm.areaID = 0;
                        vm.currentArea.area_id = 0;
                        vm.currentArea.area_name_full = null;
                        vm.cdiacRecord.urbics_area_name = null;
                        vm.cdiacRecord.urbics_area_id = 0;
                        vm.cdiacDisplayItems[0].value = null;
                        vm.cdiacDisplayItems[1].value = null;
                        vm.cdiacDisplayItems[3].value = null;
                    }
                })
                .catch (error => {
                    vm.errors.type = 'alert-warning';
                    vm.errors.defaultscription = [{message: 'An error occurred while processing this page.'}];
                    if ((typeof error.response === 'undefined') && (process.env.NODE_ENV !== 'production')) {
                        console.log(error);
                    } else if ((process.env.NODE_ENV !== 'production') && (error.response.status == 500)) {
                        vm.errors.description.push({message: error.response.data.message});
                        vm.errors.description.push({message: "In file " + error.response.data.file});
                        vm.errors.description.push({message: "At line " + error.response.data.line});
                    }
                });
            },
        },
        computed: {
            canSaveData() {
                return this.areaID != 0;
            },
            canUnlinkArea() {
                return this.cdiacRecord.urbics_area_id;
            },
            cdiacItems() {
                this.cdiacDisplayItems = [];
                if (this.config.output.data[0].length == 0) {
                    this.cdiacDisplayItems = [{
                        label: 'No CDIAC Items Found', 
                        labelClass: 'item-label padLeft',
                        value: '',
                        valueClass: '',
                        isLink: false,
                        url: '',
                        linkText: '',
                        linkTarget: '_blank',
                        linkRel: 'noopener noreferrer',
                    }];
                    return this.cdiacDisplayItems;
                }
                for (const item of this.config.output.data[0].data) {
                    if (item.meta.column == 1) {
                        this.cdiacDisplayItems.push({
                            label: item.meta.label,
                            labelClass: item.meta.label_class,
                            value: item.data[item.meta.name],
                            valueClass: item.meta.data_class, 
                            isLink: (item.links.related !== null),
                            url: item.links.related,
                            linkText: item.meta.link_text,
                            linkTarget: item.meta.link_target,
                            linkRel: item.meta.link_rel,
                        });
                    }
                }
                return this.cdiacDisplayItems;
            },         
            cdiacAdtrItems() {
                this.cdiacAdtrAuthDisplayItems = [];
                if ((this.config.output.data[0].length > 0) && (!('adtr_authorization' in this.config.output.raw_data[0]))) {
                    this.cdiacAdtrAuthDisplayItems = [{
                        label: 'No report was filed for this issuance.', 
                        labelClass: 'item-label padLeft',
                        value: '',
                        valueClass: '',
                        isLink: false,
                        url: '',
                        linkText: '',
                        linkTarget: '_blank',
                        linkRel: 'noopener noreferrer',
                    }];
                    return this.cdiacAdtrAuthDisplayItems;
                }
                for (const item of this.config.output.raw_data[0]['adtr_authorization']) {
                    var meta = this.config.output.field_attributes[item.name];
                    var link = this.config.output.data[0].data.links;
                    this.cdiacAdtrAuthDisplayItems.push({
                        label: meta.label,
                        labelClass: meta.label_class,
                        value: item.value,
                        valueClass: meta.data_class, 
                        isLink: (link.related !== null),
                        url: link.related,
                        linkText: meta.link_text,
                        linkTarget: meta.link_target,
                        linkRel: meta.link_rel,
                    });
                }
                return this.cdiacAdtrAuthDisplayItems;
            },
            urbicsItems() {
                this.urbicsDisplayItems = [];
                if (this.areaMetrics.length == 0) {
                    this.urbicsDisplayItems.push({
                        label: 'No Urbics data found', 
                        labelClass: 'item-label padLeft',
                        value: '',
                        valueClass: '',
                        isLink: false,
                        url: '' 
                    });
                    return this.urbicsDisplayItems;
                }
                for (const item of this.areaMetrics) {
                    this.urbicsDisplayItems.push(
                    {
                        label: 'Reference Area',
                        labelClass: 'item-label padLeft',
                        value: item.area_name,
                        valueClass: '',
                    },{
                        label: 'Special Tax Levy for FY ' + item.current_fy,
                        labelClass: 'item-label padLeft',
                        value: item.current_year_levy,
                        valueClass: '',
                    },{
                        label: 'Special Tax Levy for FY ' + item.prior_fy,
                        labelClass: 'item-label padLeft',
                        value: item.prior_year_levy,
                        valueClass: '',
                    },{
                        label: 'Parcel Count',
                        labelClass: 'item-label padLeft',
                        value: item.parcel_count,
                        valueClass: '',
                    },{
                        label: 'Land and Improvement AV for FY ' + item.current_fy,
                        labelClass: 'item-label padLeft',
                        value: item.current_land_impr_value,
                        valueClass: '',
                    },{
                        label: 'Land and Improvement AV for FY ' + item.prior_fy,
                        labelClass: 'item-label padLeft',
                        value: item.prior_land_impr_value,
                        valueClass: '',
                    });
                }
                return this.urbicsDisplayItems;
            },         
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>


