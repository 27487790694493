<template>
  <div>
    <div class="toolbar">
        <alert 
          :alerts="propMessages"
        ></alert>
    </div>
    <div class="base-page-content">
        <no-data-message v-if="reportHasNoContent" v-bind="noDataMessage"></no-data-message>
        <div class="row section-content report-cards justify-content-around">
            <area-selection-tools :user="user"></area-selection-tools>
        </div>
        <div v-if="showDevTestPage" class="row">
            <div class="offset-10 col-md-2">
                <span @click="toggleDownloadOnly">
                    <i class="pr-3" :class="downloadIcon"></i>Download Only (DEV SERVER).
                </span>
            </div>
        </div>

        <div class="row section-content report-cards justify-content-around">
            <template v-if="downloadOnly == true">
                <report-download-selector :user="user" :data="data"></report-download-selector>
            </template>
            <template v-else>
                <report-selector :user="user"></report-selector>
            </template>
        </div>
    </div>
  </div>
</template>
<script>
    import AreaSelectionTools from './area-selection-tools';
    export default {
        name: 'BasePage',
        props: {
            user: Object,
            data: Object,
        },
        components: {
            AreaSelectionTools
        },
        data: function() {
            return {
                downloadOnly: false,
                propMessages: this.data.messages,
                messages: this.$store.getters.messages,
            }
        },
        created() {
            this.$store.commit('updateMessages', {messages: this.formatPropMessages});            
        },
        computed: {
            formatPropMessages() {
                let output = [];
                if (this.propMessages.alerts === 'undefined'
                    || this.propMessages.alerts == null) {
                    return output;
                }
                Object.entries(this.propMessages.alerts).forEach(entry => {
                    output.push({status: entry[0], message: entry[1]});
                });
                return output;
            },
            reportHasNoContent() {
                if (typeof this.data.output.data === 'undefined'
                    || this.data.output.data.length == 0) {
                    this.data.messages.noData.display = true;
                    return true;
                }
                return false;
            },
            toggleDownloadOnly()
            {
                this.downloadOnly = !this.downloadOnly;
            },
            downloadIcon() {
                if (this.downloadOnly) {
                    return "fa-regular fa-lg fa-toggle-on text-success";
                }
                return "fa-regular fa-lg fa-toggle-off text-secondary";
            },
            showDevTestPage() {
                return process.env.NODE_ENV !== 'production';
            }

        },
        methods: {
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('base-page component mounted.')
            }
        }
    }
</script>


