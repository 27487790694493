<template>
    <span>
        <div class="card card-settings">
            <div class="card-header">Profile</div>
            <div class="card-body">
                <div class="settings-container">
                    <div class="row justify-content-evenly card-item">
                        <div class="col-4 text-md-right">Email Address</div>
                        <div class="col-4 text-md-left">{{ urbicsUser.userEmail }}</div>
                    </div>
                    <div class="row justify-content-evenly card-item">
                        <div class="col-4 text-md-right">Contact Name</div>
                        <div class="col-4 text-md-left">{{ urbicsUser.userName }}</div>
                    </div>
                    <div class="row justify-content-evenly card-item">
                        <div class="col-4 text-md-right">Organization</div>
                        <div class="col-4 text-md-left">{{ urbicsUser.organization }}</div>
                    </div>
                    <div class="row justify-content-evenly card-item">
                        <div class="col-4 text-md-right">Subscription Type</div>
                        <div class="col-4 text-md-left">{{ subType }}</div>
                    </div>
                    <div class="row justify-content-evenly card-item">
                        <div class="col-4 text-md-right">Subscription {{ renewsOrCancels }} On</div>
                        <div class="col-4 text-md-left">{{ renewsOrCancelsOn }}</div>
                    </div>
                    <div class="row justify-content-evenly card-item">
                        <div class="col-4 text-md-right">Subscriber Since</div>
                        <div class="col-4 text-md-left">{{ urbicsUser.startDate }}</div>
                    </div>
                </div>
            </div>
        </div>
    </span>
</template>
<script>
    export default {
        props: {
            urbicsUser: { Object, required: true },
        },
        data: function() {
            return {
            }
        },
        methods: {
            update() {
                Urbics.post('/settings/update-password', this.form);
            },
        },
        computed: {
            subType() {
                let type = this.urbicsUser.isOnEnterprisePlan
                    ? 'Enterprise'
                    : 'Professional';
                if (this.urbicsUser.isOnTrial) {
                    type = type + ' (Trial)';
                }
                return type;
            },
            renewsOrCancels() {
                if (this.urbicsUser.endsOn) {
                    return 'Ends';
                }
                return 'Renews';
            },
            renewsOrCancelsOn() {
                if (this.urbicsUser.endsOn) {
                    return this.urbicsUser.endsOn;
                }

                return this.urbicsUser.renewalDate;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {
                console.log('Profile component mounted.')
            }
        },
    }
</script>
