<template>
    <ul v-if="usesPagination" class="nav navbar-nav list-group-horizontal pagination pagination-sm">
        <li v-if="isFirstPage" class="page-item disabled"><span class="pager-link">
            <i class="fal fa-fast-backward fa-lg" aria-hidden="true"></i>
        </span></li>
        <li v-else class="page-item">
            <a class="pager-link" :href="firstPageUrl" rel="prev">
                <i class="fal fa-fast-backward fa-lg" aria-hidden="true"></i>
            </a>
        </li>
        <li v-if="isFirstPage" class="page-item disabled"><span class="pager-link">
            <i class="fal fa-step-backward fa-lg" aria-hidden="true"></i>
        </span></li>
        <li v-else class="page-item">
            <a class="pager-link" :href="previousPageUrl" rel="prev">
                <i class="fal fa-step-backward fa-lg" aria-hidden="true"></i>
            </a>
        </li>
        <li v-if="hasMorePages" class="page-item">
            <a class="pager-link" :href="nextPageUrl" rel="next">
                <i class="fal fa-step-forward fa-lg" aria-hidden="true"></i>
            </a>
        </li>
        <li v-else class="page-item disabled"><span class="pager-link">
            <i class="fal fa-step-forward fa-lg" aria-hidden="true"></i>
        </span></li>
        <li v-if="hasMorePages" class="page-item">
            <a class="pager-link last" :href="lastPageUrl" rel="next">
                <i class="fal fa-fast-forward fa-lg" aria-hidden="true"></i>
            </a>
        </li>
        <li v-else class="page-item disabled"><span class="pager-link last">
            <i class="fal fa-fast-forward fa-lg" aria-hidden="true"></i>
        </span></li>
    </ul>
</template>
<script>
    export default {
        props: {
            paginator: Object,
        },
        data() {
            return {
                pager: this.paginator.paginator,
            }
        },
        created() {
        },
        computed: {
            usesPagination() {
                return (this.paginator.paginate);
            },
            isFirstPage() {
                return (this.pager.current_page == 1);
            },
            firstPageUrl() {
                return this.pager.first_page_url;
            },
            previousPageUrl() {
                return this.pager.prev_page_url;
            },
            hasMorePages() {
                return (this.pager.last_page > this.pager.current_page);
            },
            nextPageUrl() {
                return this.pager.next_page_url;
            },
            lastPageUrl() {
                return this.pager.last_page_url;
            }
        },
        methods: {
            methodTemplate() {
                return;
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('toolbar-paginator omponent mounted.')
            }
        }
    }
</script>


