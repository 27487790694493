<template>
    <div class="tabs-component-panel">
        <div class="toolbar">
            <alert 
              :alerts="alert"
            ></alert>
        </div>
        <div class="row section-title">
            <div class="col-md-12">Property Owner Alias Groupings for This Area</div>
        </div>
        <div class="row section-content report-table">
            <div class="col-md-12 table-responsive">
                <table class="table report-table">
                    <thead>
                        <tr>
                            <th class="width-40">Name On Roll</th>
                            <th class="width-10">Linkage</th>
                            <th class="width-50">Linked to Alias</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="">
                                <input 
                                    type="text" 
                                    class="form-control" 
                                    id="ownerSelector" 
                                    v-model="ownerFilter"
                                    v-on:keyup.escape="clearOwnerFilter"
                                    placeholder="Filter names..."
                                >
                            </td>
                            <td>&nbsp;</td>
                            <td class="text-center">Show linked only 
                                <a class="pad-left align-middle">
                                    <i 
                                        id="toggleIcon"
                                        class="fal fa-lg text-success"
                                        :class="getToggleIcon"
                                        v-on:click.self="showLinkedOnly = !showLinkedOnly"
                                    ></i>
                                </a>

                            </td>
                        </tr>
                        <tr 
                            v-for="owner, ownerIndex in displayOwners" 
                            v-bind:key="owner.owner_id"
                        >
                            <td class="pad-left">{{owner.owner_name}}</td>
                            <td class="text-center">
                                <a v-if="owner.alias_id" class="align-middle" >
                                    <i v-if="processingName(ownerIndex)"
                                        id="linkAliasIcon" 
                                        class="fal fa-lg fa-spinner text-info"
                                    ></i>
                                    <i v-else
                                        id="linkAliasIcon" 
                                        class="fal fa-lg fa-unlink text-danger"
                                        v-on:click="() => removeAliasLink(ownerIndex)"
                                    ></i>
                                </a>
                                <a v-else class="align-middle">
                                    <i v-if="processingName(ownerIndex)"
                                        id="linkAliasIcon" 
                                        class="fal fa-lg fa-spinner text-info"
                                    ></i>
                                    <i v-else
                                        id="linkAliasIcon" 
                                        class="fal fa-lg fa-link text-success"
                                        v-on:click="linkOwnerID = owner.owner_id"
                                    ></i>
                                </a>
                            </td>
                            <td v-if="owner.alias_name" class="pad-left">
                                <span v-if="editingID == ownerIndex" class="">
                                    <input 
                                        type="text" 
                                        id="editAliasName"
                                        v-model="aliasName"
                                        class="col-md-6"
                                        v-bind:class="{'is-invalid': hasEditAliasNameError}"
                                    >
                                    <a 
                                        href="javascript:void(0)" 
                                        class="col-md-1 text-center align-bottom"
                                        aria-label="Save"
                                    >
                                        <i 
                                            id="saveEditedAliasName" 
                                            class="fal fa-2x fa-check text-success"
                                            title="Save"
                                            v-on:click="() => saveEditedAliasName()"
                                        ></i>
                                    </a>
                                    <a 
                                        href="javascript:void(0)" 
                                        class="col-md-1 text-center align-bottom" 
                                        aria-label="Cancel"
                                    >
                                        <i 
                                            id="cancelEditAliasName" 
                                            class="fal fa-2x fa-times text-secondary"
                                            title="Cancel"
                                            v-on:click="() => cancelEditAliasName()"
                                        ></i>
                                    </a>
                                    <span role="alert" class="invalid-feedback"><strong>{{ editAliasNameError }}</strong></span>                                    
                                </span>
                                <span v-else>
                                        {{ owner.alias_name}}
                                    <a 
                                        href="javascript:void(0)" 
                                        class="pad-left" 
                                        aria-label="Edit Name"
                                    >
                                        <i 
                                            id="editAliasName" 
                                            class="fal fa-lg fa-edit text-success"
                                            v-on:click="() => editAliasName(ownerIndex)"
                                        ></i>
                                    </a>
                                </span>

                            </td>
                            <td v-else-if="linkOwnerID == owner.owner_id">
                                <div class="form-group col-12 dropdown" 
                                    v-click-away="clearAliasFilter">
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        id="aliasSelector" 
                                        v-model="aliasFilter"
                                        v-on:keyup.escape="clearAliasFilter"
                                        placeholder="Filter...">

                                    <ul class="quick-search list-unstyled list-group dropdown-menu">
                                        <li class="list-group-item" 
                                            v-bind:class="alias.class" 
                                            v-for="(alias, index) in displayAliases" 
                                            v-bind:key="alias.alias_id"
                                        >
                                            <span v-if="alias.message.length > 0">
                                                {{alias.message}}
                                            </span>
                                            <a v-on:click="() => addAliasLink(index)">
                                                {{alias.alias_name}}
                                            </a>
                                        </li>
                                        <li v-if="aliasCount == maxAliases" class="list-group-item report-action-item">
                                            Filter to see more results...
                                        </li>
                                    </ul>
                                </div>
                            </td>
                            <td v-else>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../../mixins/handle-error'
    export default {
        name: 'ManageAreaOwnerAliases',
        props: {
            user: Object,
            data: Object,
        },
        data() {
            return {
                alert: Object(),
                aliasCount: 0,
                aliasFilter: '',
                aliasName: '',
                aliasNames: [],
                editAliasNameError: '',
                editingAliasName: false,
                editingID: -1,
                editingOwnerAlias: [],
                errorMessage: '',
                formattedAliasNamesList: [],
                formattedOwnerNamesList: [],
                linkOwnerID: 0,
                maxAliases: 50,
                maxOwners: 1000,
                messages: this.data.messages,
                minSearchLength: 3,
                ownerCount: 0,
                ownerFilter: '',
                ownerNames: [],
                pagination: this.data.pagination,
                showAliasNameSelector: false,
                showLinkedOnly: false,
                tools: this.data.tools,
                unlinkOwnerID: 0,
            }
        },
        created() {
            this.refreshData();
            },
        computed: { 
            linkOwner() {
                this.showAliasNameSelector = !this.showAliasNameSelector;
            },
            getToggleIcon() {
                if (this.showLinkedOnly) {
                    return "fa-toggle-on ";
                }
                return "fa-toggle-off ";
            },
            displayOwners() {
                if (this.filteredOwnerNames.length > 0) {
                    return this.formatOwnerNamesList;
                }
                if (this.ownerFilter.length > 0) {
                    this.formattedOwnerNamesList = [];
                    this.formattedOwnerNamesList.push({
                        original_owner_name: this.ownerFilter.toUpperCase(),
                        owner_id: 0, 
                        alias_name: '',
                        alias_id: 0, 
                        message: 'No names were found ',
                    });
                    this.ownerCount = 1;

                    return this.formattedOwnerNamesList;
                }
                return '';
            },
            filteredOwnerNames() {
                const filtered = [];
                const regOption = new RegExp(this.ownerFilter, 'ig');
                for (const option of this.ownerNames) {
                    if (this.ownerFilter.length < 1 || option.original_owner_name.match(regOption)) {
                        if (filtered.length < this.maxOwners) filtered.push(option);
                    }
                }
                return filtered;
            },
            formatOwnerNamesList() {
                var j = 0;
                this.formattedOwnerNamesList = [];
                this.ownerCount = 0;
                for (var i = 0; i < this.filteredOwnerNames.length; i++) {
                    var item = this.filteredOwnerNames[i];
                    if (this.showLinkedOnly && !item.alias_id) {
                        continue;
                    }
                    this.formattedOwnerNamesList.push({
                        owner_name: item.original_owner_name,
                        owner_id: item.original_owner_id, 
                        alias_name: item.alias_name,
                        alias_id: item.alias_id, 
                    });
                    ++this.ownerCount;
                }
                return this.formattedOwnerNamesList
            },
            displayAliases() {
                if (this.filteredAliasNames.length > 0) {
                    return this.formatAliasNamesList;
                }
                if (this.aliasFilter.length > 0) {
                    this.formattedAliasNamesList = [];
                    this.formattedAliasNamesList.push({
                        alias_name: this.aliasFilter.toUpperCase(),
                        alias_id: 0, 
                        class: 'report-sub-item', 
                        message: 'Add this alias: ',
                        handler: 'linkToNewAlias',
                        data: {
                            userId:  this.user.userId,
                            aliasName: this.aliasFilter.toUpperCase(),
                            ownerId: this.linkOwnerID
                        },
                    });
                    this.aliasCount = 1;

                    return this.formattedAliasNamesList;
                }
                return '';
            },
            filteredAliasNames() {
                const filtered = [];
                const regOption = new RegExp(this.aliasFilter, 'ig');
                for (const option of this.aliasNames) {
                    if (this.aliasFilter.length < 1 || option.alias_name.match(regOption)) {
                        if (filtered.length < this.maxAliases) filtered.push(option);
                    }
                }
                return filtered;
            },
            formatAliasNamesList() {
                var j = 0;
                this.formattedAliasNamesList = [];
                this.aliasCount = 0;
                for (var i = 0; i < this.filteredAliasNames.length; i++) {
                    var item = this.filteredAliasNames[i];
                    this.formattedAliasNamesList.push({
                        alias_name: item.alias_name,
                        alias_id: item.alias_id, 
                        class: 'report-sub-item', 
                        message: '',
                    });
                    ++this.aliasCount;
                }
                return this.formattedAliasNamesList
            },
            hasEditAliasNameError() {
                return this.editAliasNameError.length > 0;
            },
        },
        methods: {
            clearAliasFilter() {
                this.linkOwnerID = 0;
                this.aliasFilter = '';
            },
            clearOwnerFilter() {
                this.ownerFilter = '';
            },
            processingName(index) {
                if (this.linkOwnerID == this.formattedOwnerNamesList[index].owner_id) {
                    return true;
                }
                if (this.unlinkOwnerID == this.formattedOwnerNamesList[index].owner_id) {
                    return true;
                }
                return false;
            },
            addAliasLink(index) {
                if (this.formattedAliasNamesList[index] === undefined) {
                    return;
                }
                axios.get('/reports/manage-name-consolidation/update', {
                    params: {
                        aliasId: this.formattedAliasNamesList[index].alias_id,
                        ownerId: this.linkOwnerID,
                        aliasName: this.formattedAliasNamesList[index].alias_name,
                    }
                })
                .then(response => {
                    if (response.data.status_code == 200) {
                        this.refreshData();
                        this.linkOwnerID = 0;
                        return;
                    }
                    this.alert = displayAlert(response.data.status_message);
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                })
                .then(() => {
                    this.linkOwnerID = 0;
                    this.clearAliasFilter
                });

                return;
            },
            removeAliasLink(index) {
                if (this.displayOwners[index] === undefined) {
                    return;
                }
                this.unlinkOwnerID = this.displayOwners[index].owner_id;
                axios.get('/reports/manage-name-consolidation/delete', {
                    params: {
                        aliasId: this.displayOwners[index].alias_id,
                        ownerId: this.displayOwners[index].owner_id,
                        aliasName: this.displayOwners[index].alias_name,
                    }
                })
                .then(response => {
                    if (response.data.status_code == 200) {
                        this.refreshData();
                        this.unlinkOwnerID = 0;
                        return;
                    }
                    this.alert = displayAlert(response.data.status_message);
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
                this.unlinkOwnerID = 0;

                return;
            },
            refreshData() {
                this.refreshOwnerNameList();
                this.refreshAliasNameCounts();
            },
            refreshOwnerNameList() {
                axios.get('/reports/manage-name-consolidation/list/' 
                    + this.user.year + '/'
                    + this.user.areaId, {}
                )
                .then(response => {
                    if (response.data.length == 0) {
                        this.ownerNames = [];
                    }
                    else {
                        this.ownerNames = response.data;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            refreshAliasNameCounts() {
                axios.get('/reports/manage-name-consolidation/list')
                .then(response => {
                    if (response.data.length == 0) {
                        this.aliasNames = [];
                    }
                    else {
                        this.aliasNames = response.data;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },            
            deleteAliasName(index) {
                if (this.aliasNames[index] === undefined) {
                    return;
                }
                axios.get('/reports/manage-name-consolidation/delete', {
                    params: {
                        aliasId: this.aliasNames[index].alias_id,
                    }
                })
                .then(response => {
                    if (response.data.status_code == 200) {
                        this.refreshData();
                        return;
                    }
                    this.alert = displayAlert(response.data.status_message);
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
                return;
            },
            editAliasName(index) {
                this.editingID = index;
                this.editingOwnerAlias = this.displayOwners[index];
                this.aliasName = this.editingOwnerAlias.alias_name;

                return;
            },
            saveEditedAliasName() {
                this.editAliasNameError = '';
                if (this.aliasName == this.editingOwnerAlias.alias_name) {
                    this.cancelEditAliasName();
                    return;
                }
                if (this.aliasName.length < 5) {
                    this.editAliasNameError = 'The new name must be at least four characters long';
                    return;
                }
                
                this.updateAliasName();
            },
            cancelEditAliasName()
            {
                this.aliasName == '';
                this.editAliasNameError = '';
                this.editingAliasName = false;
                this.editingID = -1;
                this.editingOwnerAlias = [];
            },
            updateAliasName() {
                let url = '/reports/manage-name-consolidation/edit';
                axios.get(url, {
                    params: {
                        aliasId: this.editingOwnerAlias.alias_id,
                        aliasName: this.aliasName,
                    }
                })
                .then(response => {
                    if (response.data.status_code != 200) {
                        this.editAliasNameError = response.data.status_message;
                        return;
                    }
                    else {
                        this.cancelEditAliasName();
                        this.refreshData();
                    }
                })
                .catch (error => {
                    this.cancelEditAliasName();
                    this.alert = displayAlert(error.message);
                });
            },            
        },
        watch: {
            ownerNames: function(newValue, oldValue) {
                if (this.data.messages.noData !== undefined) {
                    this.data.messages.noData.display = (newValue.length == 0);                
                }
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>