/**
 * Initialize the form extension points (borrowed from Laravel Spark).
 */
Urbics.forms = {
    register: {},
    updateContactInformation: {},
    updateTeamMember: {}
};

/**
 * Load the UrbicsForm helper class.
 */
require('./form');

/**
 * Define the UrbicsFormError collection class.
 */
require('./errors');

/**
 * Add additional HTTP / form helpers to the Urbics object.
 */
// $.extend(Urbics, require('./http'));
