<template>
    <i 
        aria-hidden="true" 
        aria-label="values.label" 
        :class="[iconSize, iconName, iconColor]"
        @click="toggleField"
    ></i>
</template>
<script>
    export default {
        props: {values: Object},
        data() {
            return {
                processing: false,
                visible: 0,
                reportID: 0,
                userID: 0,
                modelID: 0,
            }
        },
        created() {
            this.visible = this.values.visible;
            this.reportID = this.values.report_id;
            this.userID = this.values.user_id;
            this.modelID = this.values.model_id;
        },
        methods: {
            // Toggle status sequence: not available (-1) => visible (1) => not visible (0)
            toggleField: function () {
                if (this.processing == true) {
                    return;
                }
                var self = this;
                var vis = this.visible == 1 ? 0 : (this.visible == 0 ? -1 : 1);
                var url = (this.modelID == 0) ? this.insertUrl : ( this.visible == 1 ? this.updateUrl : this.deleteUrl);
                this.processing = true;
                axios.post(url, {
                    id: this.modelID,
                    user_id: this.userID,
                    report_id: this.reportID,
                    visible: vis
                })
                .then(function (response) {
                    if (typeof response.data.id != 'undefined') {
                        self.modelID = response.data.id;
                        self.visible = response.data.visible;
                    } else {
                        self.modelID = 0;
                        self.visible = -1;
                    }
                })
                .catch(function (error) {
                    if (process.env.NODE_ENV !== 'production') {            
                        console.log(error.response.data)
                    }
                })
                .finally( function (response) {
                    self.processing = false;
                });                
            },
        },
        computed: {
            iconSize: function () {
                return 'fal'
            },
            iconColor: function () {
                if(this.visible === -1) {
                    return 'text-secondary';
                }
                return 'text-success';
            },
            iconName: function () {
                if (this.processing == true) {
                    return 'fa-circle-notch fa-spin';
                }
                if (this.visible === 1) {
                    return 'fa-toggle-on';
                }
                if (this.visible === 0) {
                    return 'fa-toggle-off'
                }
                return 'fa-times';
            },
            insertUrl: function () {
                return this.values.route_insert
            },
            updateUrl: function () {
                return this.values.route_update + '/' + this.modelID
            },
            deleteUrl: function () {
                return this.values.route_delete + '/' + this.modelID
            },
        },
        mounted() {
            // if (process.env.NODE_ENV !== 'production') {            
            //     console.log('Component mounted.')
            // }
        }
    }
</script>
