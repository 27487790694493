<template>
    <ul class="nav navbar-nav list-group-horizontal" >
        <li v-for="(msg, key) in statusAlerts" class="w-100">
            <div class="alert fade show" 
                :class="messageStatus(key)" 
                role="alert"
            >
                <span v-html="messageText(key)"></span>
                    <button v-if="isDismissable(key)" type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
            </div>                
        </li>
    </ul>
</template>
<script>
    export default {
        props: {
            statusAlerts: {
                type: Object,
                required: false,
            },
            standalone: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        data() {
            return {
            }
        },
        created() {
        },
        computed: {
            rightMargin() {
                if (this.standalone) {
                    return ' w-100';
                }
                return ' mr-auto';
            },
        },
        methods: {
            hasStatusAlert() {
                return (typeof this.statusAlerts !== 'undefined');
            },
            isDismissable(key) {
                return (this.hasStatusAlert()
                    && typeof this.statusAlerts[key].dismissable !== 'undefined'
                    && this.statusAlerts[key].dismissable);
            },
            messageStatus(key) {
                if (this.hasStatusAlert()) {
                    if (typeof this.statusAlerts[key].status !== 'undefined') {
                        let alertClass = 'toolbar-alert-' + this.statusAlerts[key].status;
                        if (this.isDismissable(key)) {
                            alertClass = alertClass + ' alert-dismissible';
                        }
                        return alertClass;
                    }
                    return 'toolbar-alert-warning';
                }
                return null;
            },
            messageText(key) {
                if (this.hasStatusAlert()) {
                    if (typeof this.statusAlerts[key].message !== 'undefined') {
                        return this.statusAlerts[key].message;
                    }
                    return null;
                }
                return null;
            },
        },
        watch: {
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('status-alert component mounted.')
            }
        }
    }
</script>


