export function countDisplayLines(data) {
    let countyID = 0,
        groupID = 0,
        parentID = 0,
        items = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i].county_id != countyID) {
            countyID = data[i].county_id;
            groupID = 0;
            parentID = 0;
            items++;
        }
        if (data[i].group_id != groupID) {
            groupID = data[i].group_id;
            parentID = 0;
            items++;
        }
        if (data[i].parent_area_id != parentID) {
            parentID = data[i].parent_area_id;
            items++;
        }
        items++;
    }

    return items;
}

export function countItems(data, levels) {
    let curLevel = [],
        items = 0;
    for (let inKey = 0; inKey < data.length; inKey++) {
        for (let levelKey = 0; levelKey < levels.length; levelKey++) {
            if (curLevel[levelKey] === undefined) {
                curLevel[levelKey] = {
                    level: levels[levelKey].level, 
                    key: data[inKey][levels[levelKey].fields.key]
                };
            }
            if (data[inKey][levels[levelKey].fields.name] === undefined) {
                continue;
            }
            if (data[inKey][levels[levelKey].fields.name].length == 0) {
                continue;
            }
            if (data[inKey][levels[levelKey].fields.key].length == 0) {
                continue;
            }
            if (data[inKey][levels[levelKey].fields.key] == 0) {
                continue;
            }
            if (curLevel[levelKey].key != data[inKey][levels[levelKey].fields.key]) {
                curLevel[levelKey].key = data[inKey][levels[levelKey].fields.key];
                items++;
            }
        }
    }
    return items;
/*


    for (let levelKey = 0; levelKey < levels.length; levelKey++) {
    let level1 = 0,
        level2 = 0,
        level3 = 0,
        level4 = 0,
        items = 0;
    for (let i = 0; i < data.length; i++) {
        if (levels[1] !== undefined && levels[1].id != level1) {
            level1 = levels[1].id;
            level2 = 0;
            level3 = 0;
            level4 = 0;
            items++;
        }
        if (levels[2] !== undefined && levels[2].id != level2) {
            level2 = levels[2].id;
            level3 = 0;
            level4 = 0;
            items++;
        }
        if (levels[3] !== undefined && levels[3].id != level3) {
            level3 = levels[3].id;
            level4 = 0;
            items++;
        }
        if (levels[4] !== undefined && levels[4].id != level4) {
            level4 = levels[4].id;
            items++;
        }
    }
    return items;
*/
}

export function countGroups(data) {
    let groupID = 0,
        items = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i].group_id != groupID) {
            groupID = data[i].group_id;
            items++;
        }
    }

    return items;
}

export function countParents(data) {
    let parentID = 0,
        items = 0;
    for (let i = 0; i < data.length; i++) {
        if (data[i].parent_area_id != parentID) {
            parentID = data[i].parent_area_id;
            items++;
        }
    }

    return items;
}

export function displayArray(columns) {
    let cols = [];
    for (let i = 0; i < columns; i++) {
        cols.push([]);
    }
    return cols;
}
/*
levels:
[{level: 1, fields: {name: name_field, id: name_id}}, ...]
 */
export function generateHierarchicList(data, levels, columns) {
    let displayList = displayArray(columns);
    let itemCount = countItems(data, levels);
    let colMax = itemCount / columns;
    let curLevel = [];

    for (let inKey = 0, outKey = 0, colKey = 0; inKey < data.length; inKey++) {
        let first = (colKey == 0 ? ' hier-first ' : '');
        for (let levelKey = 0; levelKey < levels.length; levelKey++) {
            if (outKey > 0 && colKey == 0 && levelKey > 0) {
                for (let contKey = 0; contKey < levelKey; contKey++ ) {
                    let contData = {
                        name: data[inKey][levels[contKey].fields.name],
                        key: data[inKey][levels[contKey].fields.key],
                        id: data[inKey][levels[contKey].fields.id], 
                        class: levels[contKey].class, 
                    }

                    if (contData.name === undefined) {
                        continue;
                    }
                    if (contData.name.length == 0) {
                        continue;
                    }
                    displayList[outKey].push({
                        name: contData.name + " (Continued)",
                        class: contData.class + first,
                        id: contData.id,
                    });
                    colKey++;
                }
            }
            let curData = {
                name: data[inKey][levels[levelKey].fields.name],
                key: data[inKey][levels[levelKey].fields.key],
                id: data[inKey][levels[levelKey].fields.id], 
                class: levels[levelKey].class, 
            }
            if (curLevel[levelKey] === undefined) {
                curLevel[levelKey] = {
                    level: levels[levelKey].level, 
                    key: curData.name
                };
            }
            if (curData.name === undefined
                || curData.name.length == 0
                || curData.key.length == 0
                || curData.key == 0
            ) {
                continue;
            }
            if (curLevel[levelKey].key != curData.key) {
                curLevel[levelKey].key = curData.key;
                displayList[outKey].push({
                    name: curData.name,
                    class: curData.class + first,
                    id: curData.id,
                });
                colKey++;
                if (colKey + 1 >= colMax && outKey < (columns - 1)) {
                    outKey = outKey + 1; 
                    colKey = 0;
                }
            }
        }
    }
    return displayList;
}

export function formatAreaListTest(rawData, columns) {
    let levels = [
        {level: 1, 
            fields: {name: 'county_name', id: 'county_id', key: 'county_id'},
            class: 'hier-item-l1',
        },
        {level: 2, 
            fields: {name: 'group_name', id: 'group_id', key: 'group_id'},
            class: 'hier-item-l2',
        },
        {level: 3, 
            fields: {name: 'parent_area_name', id: 'parent_pareaid', key: 'parent_area_id'},
            class: 'hier-group-l3',
        },
        {level: 4, 
            fields: {name: 'area_name_full', id: 'pareaid', key: 'parent_area_id'},
            class: 'hier-subitem-l4',
        },
        {level: 5, 
            fields: {name: 'area_name_full', id: 'pareaid', key: 'pareaid'},
            class: 'hier-item-l3',
        },
    ];
    let result = generateHierarchicList(rawData, levels, columns);

    return result;
}

export function formatAreaList(rawData, columns) {
    let displayList = displayArray(columns);
    let itemCount = countDisplayLines(rawData, true, true, true);
    let maxCols = columns;
    let colMax = itemCount / maxCols;
    let county = '',
        group = '',
        parent = '';
    for (let i = 0, j = 0, k = 0; i < rawData.length; i++) {
        let first = (k == 0 ? ' hier-first ' : '');
        // pareaid - add key if not present
        if (rawData[i].pareaid == undefined) {
            rawData[i].pareaid =  "";
        }
        // id - add key if not present
        if (rawData[i].id == undefined) {
            rawData[i].id =  "";
        }

        // County name - add key if not present 
        if (rawData[i].county_name_full == undefined) {
            rawData[i].county_name_full =  "";
        }
        if ((rawData[i].county_name_full.length > 0) 
            && (rawData[i].county_name_full == county)
            && (k == 0) 
            && (j != 0)
            ) {
                displayList[j].push({
                    name: rawData[i].county_name_full + " (Cont.)",
                    class: "hier-group-l1 " + first,
                    id: rawData[i].county_id,
                    pareaid: ""
                });
                k++;
        }
        if ((rawData[i].county_name_full.length > 0) 
                && (rawData[i].county_name_full != county)) {
            displayList[j].push({
                name: rawData[i].county_name_full,
                class: "hier-group-l1 " + first,
                id: rawData[i].county_id,
                pareaid: ""
            });
            k++;
            county = rawData[i].county_name_full;
            group = '';
            parent = '';
        }

        // Area type - add key if not present 
        if (rawData[i].group_name == undefined) {
            rawData[i].group_name = "";
        }
        if ((rawData[i].group_name.length > 0) 
            && (rawData[i].group_name == group) 
            && (k == 0) 
            && (j != 0)
        ) {
            displayList[j].push({
                name: rawData[i].group_name + " (Cont.)",
                class: "hier-group-l2 " + first,
                id: rawData[i].group_id,
                pareaid: ""
            });
            k++;
        }
        if ((rawData[i].group_name.length > 0) 
                && (rawData[i].group_name != group)) {
            displayList[j].push({
                name: rawData[i].group_name,
                class: "hier-group-l2 " + first,
                id: rawData[i].group_id,
                pareaid: ""
            });
            k++;
            group = rawData[i].group_name;
            parent = '';
        }

        // Parent name - add key if not present
        if (rawData[i].parent_area_name == undefined) {
            rawData[i].parent_area_name = "";
        }
        if (rawData[i].parent_area_name.length > 0) {
            if ((rawData[i].parent_area_name == parent) 
                && (k == 0) 
                && (j != 0)
            ) {
                displayList[j].push({
                    name: rawData[i].parent_area_name + " (Cont.)",
                    class: "hier-group-l3 " + first,
                    id: rawData[i].parent_pareaid,
                    pareaid: rawData[i].parent_pareaid
                });
                k++;
            }
            if ((rawData[i].parent_area_name != parent)) {
                displayList[j].push({
                    name: rawData[i].parent_area_name,
                    class: "hier-group-l3 " + first,
                    id: rawData[i].parent_pareaid,
                    pareaid: rawData[i].parent_pareaid
                });
                k++;
                parent = rawData[i].parent_area_name;
            }
        }

        displayList[j].push({
            name: rawData[i].area_name_full,
            class: (rawData[i].parent_area_name.length > 0
                ? 'hier-subitem-l4 hier-item-circle' 
                : 'hier-item-l3'
            ),
            id: rawData[i].pareaid,
            pareaid: rawData[i].pareaid
        });
        ++k;
        if (k + 1 >= colMax && j < (maxCols - 1)) {
            j = j + 1; 
            k = 0;
        }
    }

    return displayList;
}

