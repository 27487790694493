<template>
    <div id="welcome" class="container-fluid">
        <div class="row header">
        </div>
        <div id="main" class="row">
            <div id="title" class="offset-md-1 col-md-10">
                <h1>Please Sign In</h1>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="card card-register">
                    <div class="card-body">
                        <form id="login-form" @submit.prevent="submitForm">
                            <input type="hidden" name="_token" v-model="csrf">
                                <div v-if="validationErrors.length == 0" class="alert alert-info">
                                    <strong>Subscribers to our legacy platform:</strong>
                                    Please use your existing user name and password and enter the email to be associated with your subscription.  We will send you a verification email to activate your subscription on this site (this will not affect access to our legacy site). 
                                    <br>
                                    <strong>Other registered users</strong> please sign in using your email and password.
                                </div>

                            <!-- E-Mail Address -->
                            <div class="form-group row">
                                <label for="email" class="col-md-4 col-form-label text-md-right">E-Mail Address</label>
                                <div class="col-md-6">
                                    <input id="email" type="email" class="form-control" v-bind:class="{ 'is-invalid': hasEmailError }" name="email" v-model="email" required autocomplete="email" autofocus>
                                        <span v-if="hasEmailError" class="invalid-feedback" role="alert">
                                            <strong>{{ emailErrorMessage }}</strong>
                                        </span>
                                </div>
                            </div>

                            <!-- Password -->
                            <div class="form-group row">
                                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                                <div class="col-md-6">
                                    <input id="password" type="password" class="form-control" v-bind:class="{ 'is-invalid': hasEmailError }" name="password" required autocomplete="current-password">
                                        <span v-if="hasPasswordError" class="invalid-feedback" role="alert">
                                            <strong>{{ passwordErrorMessage }}</strong>
                                        </span>
                                </div>
                            </div>

                            <!-- User Name -->
                            <div class="form-group row">
                                <label class="col-md-4 col-form-label text-md-right">User Name</label>

                                <div class="col-md-6">
                                    <input type="text" class="form-control" name="name" v-model="name"  placeholder="(For subscribers to our legacy platform)" autofocus>
                                </div>
                            </div>

                            <!-- Remember Me -->
                            <div class="form-group row">
                                <div class="col-md-6 offset-md-4">
                                    <div class="form-check">
                                        <input class="form-check-input" type="checkbox" name="remember" id="remember" v-model="rememberChecked">

                                        <label class="form-check-label" for="remember">
                                            Remember Me
                                        </label>
                                    </div>
                                </div>
                            </div>


                            <div class="form-group row mb-0">
                                <div class="col-md-8 offset-md-4">
                                    <button type="submit" class="btn btn-primary">
                                        Login
                                    </button>
                                    <a class="btn btn-link" href="password/reset">
                                        Forgot Your Password?
                                    </a>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: [
        ],
        data: function() {
            return {
                name: '',
                email: '',
                remember: '',
                tos: '',
                validationErrors: '',
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        computed: {
            hasEmailError() {
                return (this.validationErrors.email != undefined);
            }, 
            hasPasswordError() {
                return (this.validationErrors.password != undefined);
            }, 
            emailErrorMessage() {
                if (this.validationErrors.email != undefined) {
                    return this.validationErrors.email.message;
                }
                return '';
            },
            passwordErrorMessage() {
                if (this.validationErrors.password != undefined) {
                    return this.validationErrors.password[0];
                }
                return '';
            },
            rememberChecked() {
                return (this.remember.length != 0 ? 'checked' : '');
            }
        },
        methods: {
            submitForm() {
                axios.post('/login', {
                    name: this.name,
                    email: this.email,
                }).then(response => {
                    console.log('successful');
                }).catch(error => {
                    if (error.response.status == 422){
                        console.log(error.response);
                        this.validationErrors = error.response.data.errors;
                    }
                })
            }
        }
    }
</script>