<template>
    <div class="row" id="billing-form">
        <div class="offset-2 col-8">
            <div class="pricing-card shadow border-secondary h-100">
                <div class="card-body">
                    <div class="top-half">
                        <h1>Enterprise Plan</h1>
                        <div class="price-quote">
                        <h4>Tiered Pricing</h4>
                        </div>
                    </div>
                    <div class="bottom-half billing-plan">
                        <ul class="feature-list fa-ul">
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-map-signs"></i>
                                </span>
                                Quickly access all counties, cities, schools and special districts in California and track them in a portfolio. 
                            </li>
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-map-signs"></i>
                                </span>
                                Create new areas by combining existing areas, parcels or tax rate codes.
                            </li>
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-books"></i>
                                </span>
                                View and download summary reports, overlapping areas, CDIAC data and more.
                            </li>
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-books"></i>
                                </span>
                                View and download analytic reports of an area's assessed value, top owners, land use, tax delinquencies and parcel detail with full supporting details.
                            </li>
                            <li>
                                <span class="fa-li">
                                <i class="fal fa-lg fa-books"></i>
                                </span>
                                Drill down to parcel level detail for most reports.
                            </li>
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-books"></i>
                                </span>
                                Consolidate owner names in reports (Alpha Co + Alpha Inc = Alpha Company).
                            </li>
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-download"></i>
                                </span>
                                Download data in PDF, Excel, text and JSON formats as well as through our API.
                            </li>
                        </ul>
                    </div>
                    <div v-if="paymentSucceeded" class="bottom-half billing-plan row">
                        <div id="cc-instructions" class="col-12">
                            <payment-success
                                :data="paymentMethodId"
                            ></payment-success>
                        </div>
                    </div>
                    <div v-else>
                        <div class="bottom-half billing-plan row">
                            <div id="cc-instructions" class="col-12">
                                <h5>Please enter your payment details. Your card will be charged {{selectedPrice}}.</h5>
                                <h5>The subscription will renew annually. You may cancel the renewal at any time.
                                    </h5>
                                <status-alert 
                                    v-if="hasAlert" 
                                    :statusAlerts="alert" 
                                    :standalone=true  
                                ></status-alert>
                            </div>
                        </div>
                        <div id="payment-form" :data-secret="clientSecret">
                            <div class="row mb-4">
                                <div id="payment-element" class="offset-2 col-8">
                                <!--Stripe.js injects the Payment Element-->
                                </div>
                            </div>
                            <div class="row">
                                <button 
                                    @click="processCard" 
                                    class="btn btn-success btn-lg offset-2 col-8"
                                    id="submit"
                                >
                                    <span v-if="processing">
                                        <i class="fal fa-lg fa-spinner"></i>
                                    </span>
                                    <span v-else>Subscribe</span>
                                </button>
                            </div>
                        </div>  
                    </div>                              
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../mixins/handle-error'
    import { loadStripe } from '@stripe/stripe-js';
    export default {
        props: {
            data: {Object, required: true},
        },
        components: {
            loadStripe,
        },
        data() {
            return {
                alert: this.data.alerts !== 'undefined' ? this.data.alerts : Object,
                clientSecret: this.data.intent.client_secret,
                customer: this.data.customer,
                paymentElement: null,
                paymentMethodId: '',
                paymentSucceeded: false,
                price: this.data.products.subscriptions.enterprise_plan.price.amount,
                price_id: this.data.products.subscriptions.enterprise_plan.price.stripe_id,
                price_lookup_key: this.data.products.subscriptions.enterprise_plan.price.lookup_key,
                processing: false,
                slug_request: this.data.slug_request,
                stripe: Stripe(process.env.MIX_STRIPE_KEY),
            }
        },
        created() {
        },
        computed: {
            elements() {
                return this.stripe.elements({clientSecret: this.clientSecret});
            },
            hasAlert() {
                return Object.keys(this.alert).length > 0;
            },
            formUrl() {
                return window.location.href;
            },
            selectedPrice() {
                return this.formatCurrency(this.price);
            },
        },
        methods: {
            formatCurrency(value) {
                return '$' + Intl.NumberFormat('en-US').format(Number(value/100));
            },
            initialize() {
                const paymentElementOptions = {
                    layout: "tabs",
                    wallets: {
                        applePay: 'never',
                        googlePay: 'never',
                    }
                };

                this.paymentElement = this.elements.create("payment", paymentElementOptions);
                this.paymentElement.mount("#payment-element");            
            },
            processCard() {
                if (this.processing) {
                    return;
                }
                this.processing = true;
                this.stripe.confirmSetup({
                    elements: this.elements, 
                        confirmParams: {
                            return_url: window.location.href,
                        },
                        redirect: 'if_required'
                })
                .then(result => {
                    console.log(result);
                    if (result.error) {
                        this.alert = displayAlert(result.error);
                        return;
                    }
                    if (result.setupIntent && result.setupIntent.status =='succeeded') {
                        this.paymentMethodId = result.setupIntent.payment_method;
                        this.createSubscription();
                    }
                })
                .catch(error => {
                    this.processing = false;
                    this.alert = displayAlert(error);
                });

                this.processing = false;
            },
            createSubscription() {
                this.processing = true;
                axios.post('/subscription/create', {
                    paymentMethodId: this.paymentMethodId,
                    lookupKey: this.price_lookup_key,
                    custId: this.customer.id,
                    priceId: this.price_id,
                })
                .then(response => {
                    this.processing = false;
                    if (response.data.status_code == 200) {
                        this.paymentSucceeded = true;
                        this.redirectToPage(this.slug_request);
                        return;
                    }
                    this.displayAlert(response.data.status_message);
                })
                .catch(error => {
                    this.processing = false;
                    this.displayAlert(error.message);
                });
            },
            redirectToPage(slug) {
                let url = (slug == 'home' ? '/home' : '/reports/' + slug);
                window.location.replace(url);
            },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('billing-ent component mounted.')
            }
            this.initialize();
        }
    }
</script>