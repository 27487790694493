<template>
    <ul class="nav navbar-nav list-group-horizontal" >
        <li v-for="(msg, key) in alerts" class="w-100">
            <div class="alert fade show" 
                :class="messageStatus(key)" 
                role="alert"
            >
                <span v-html="messageText(key)"></span>
                    <button v-if="isDismissable(key)" type="button" class="close" data-dismiss="alert" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                    </button>
            </div>                
        </li>
    </ul>
</template>
<script>
    export default {
        props: {
            messages: {
                type: Object,
                required: false,
            },
            standalone: {
                type: Boolean,
                default: false,
                required: false,
            },
        },
        data() {
            return {
            }
        },
        created() {
        },
        computed: {
            alerts() {
                return (this.messages !== undefined ? this.messages : []);
            },
            rightMargin() {
                if (this.standalone) {
                    return ' w-100';
                }
                return ' mr-auto';
            },
        },
        methods: {
            isDismissable(key) {
                return (typeof this.messages[key] !== 'undefined'
                    && typeof this.messages[key].dismissable !== 'undefined'
                    && this.messages[key].dismissable);
            },
            messageStatus(key) {
                if (typeof this.messages[key] !== 'undefined') {
                    if (typeof this.messages[key].status !== 'undefined') {
                        let alertClass = 'toolbar-alert-' + this.messages[key].status;
                        if (typeof this.messages[key].dismissable !== 'undefined'
                            && this.messages[key].dismissable) {
                                alertClass = alertClass + ' alert-dismissible';
                        }
                        return alertClass;
                    }
                    if (typeof this.messages[key] == 'notice') {
                        return 'toolbar-alert-warning';
                    }
                    return null;
                }
                return null;
            },
            messageText(key) {
                if (typeof this.messages[key] !== 'undefined') {
                    if (typeof this.messages[key].message !== 'undefined') {
                        return this.messages[key].message;
                    }
                    if (typeof this.messages[key] !== 'undefined') {
                        return this.messages[key];
                    }
                    return null;
                }
                return null;
            },
        },
        watch: {
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('toolbar-alert component mounted.')
            }
        }
    }
</script>


