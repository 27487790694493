<template>
    <area-quick-search></area-quick-search>
</template>
<script>
    export default {
        props: {
            searchBox: {
                type: Object | Array,
            },
        },
        data() {
            return {
            }
        },
        created() {
        },
        computed: {
        },
        methods: {
        },
        watch: {
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('toolbar-search component mounted.')
            }
        }
    }
</script>


