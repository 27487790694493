<template>
    <div class="col-md-12 report-card shadow border-secondary">
	    <div class="card-body">
            <div class="section-title">
            	View Reports
        	</div>
            <div class="sub-section-title text-center mb">
                Viewing reports for:<br/>
                <span v-if="user.parentAreaName.length > 0">
                    {{ user.parentAreaName }}<br/>
                </span>
                {{ user.areaName }} ({{ user.fullCountyName }})
            </div>
	        <div class="row section-content">
	            <div v-for="pos in [0, 1]" class="col-md-6">
	                <ul class="report-list list-unstyled">
	                    <li v-for="(col, index) in displayList[pos]" 
                            :key="index" 
                            class="" 
                            :class="col.class">
                            <template v-if="col.reportId != ''">
                                <a :href="url(col.slug)">
                                    {{ col.name }}
                                </a>
                            </template>
                            <template v-else>{{ col.name }}</template>




<!-- 	                        <span v-if="col.reportId != ''" class="item-label">
	                            {{ col.label }}
	                        </span>
	                        <span v-if="col.has_link == true">
	                            <a 
	                                :href="col.link_href" 
	                                :target="col.link_target" 
	                                :rel="col.link_rel"
	                            >
	                                <i v-if="col.has_icon == true" 
	                                    :class="col.icon"  
	                                    aria-hidden="true" 
	                                    aria-label="col.label"
	                                ></i>
	                                {{ col.value }}
	                            </a>
	                        </span>
	                        <span v-else>{{ col.value }}</span>
 -->	                    </li>
	                </ul>
	            </div>
	        </div>
	    </div>
	</div>
</template>
<script>
    import {handleError} from '../../../mixins/handle-error'
    import {formatReportList} from '../../../mixins/format-report-list'
    export default {
        name: 'ReportSelector',
        props: {
            user: Object,
            data: Object,
        },
        data: function() {
            return {
                displayList: [],
                rawData: [],
            }
        },
        computed: {
        },
        methods: {
            loadData() {
                axios.post('/reports/list-reports', {
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.clearAll();
                        return;
                    } 
                    this.rawData = response.data;
                    this.displayList = formatReportList(response.data);
                })
                .catch(error => {
                    handleError(error);
                });
            },
            colOutput(col) {
                return this.output.filter( function(item) {
                    return item.column == col;
                })
            },
            url(slug) {
                return "/reports/" + slug;
            }
        },
        created() {
        	console.log('report-selector created');
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('report-selector component mounted.')
            }
            this.loadData();
        }
    }

</script>