<template>
    <div id="welcome" class="container-fluid intro pricing">
        <div class="header">
            <div class="row">
            </div>
        </div>
        <div id="main" class="intro plans">
            <div class="row">
                <div id="title" class="offset-md-1 col-md-10">
                    <h1>Our Plans</h1>
                    <p class="introtext">The <strong>Professional</strong> plan provides access to Urbics reports for an area (a city, county, school district, special district or other type of area) with pricing set by the type of report (land use, ownership, assessed valuation, etc.).  Access to current and historic reports of that type are available for 30 days.  It is designed for individuals and firms providing specific analytics, such as continuing disclosure reporting, to clients while managing costs.</p>
                    <p class="introtext">The <strong>Enterprise</strong> plan provides access to all analytic reports for all areas for an annual fee.  Intended for those requiring ongoing access to credit analysis for issuance, trading, monitoring and disclosure purposes, Enterprise subscribers also receive access to advanced tools and features.  Both plans offer data downloads in various formats, portfolio management, online support and general analytics.</p>
                </div>
            </div>
            <div v-if="statusAlerts" class="row">
                <div id="title" class="offset-md-1 col-md-10">
                    <status-alert :statusAlerts="statusAlerts"></status-alert>
                </div>
            </div>
            <div class="row container-pricing pricing-cards">
                <div class="offset-md-1 col-md-10">
                    <div class="row">
                        <div class="offset-md-1 col-md-10">
                            <div class="pricing-card shadow border-secondary h-100">
                                <div class="card-body">
                                    <div class="top-half row">
                                        <div class="col-12">
                                            <pricing-table-per-category
                                                :data="data"
                                            >
                                            </pricing-table-per-category>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'Pricing',
        props: {
            data: {Object, required: true},
        },
        data() {
            return {
                statusAlerts: this.data.alerts.status,
            }
        },
        computed: {
        },
        methods: {
        },
        created() {
        },
    }
</script>
