<template>
    <div class="base-page-content">
        <no-data-message v-if="reportHasNoContent" v-bind="noDataMessage"></no-data-message>
<!--         <toolbar 
          :alerts=alerts
          :paginator=paginator
          :tools= tools
        ></toolbar>
 -->        <div class="row section-content report-cards justify-content-around">
            <div class="col-md-6 report-card shadow border-secondary">
                <div class="card-body">
                    <div class="section-title">Owner Names</div>
                    <table class="table report-table">
                        <thead>
                            <tr>
                                <th class="width-40">Name On Roll</th>
                                <th class="width-10">Linkage</th>
                                <th class="width-50">Linked to Alias</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="">
                                    <input 
                                        type="text" 
                                        class="form-control" 
                                        id="ownerSelector" 
                                        v-model="ownerFilter"
                                        v-on:keyup.escape="clearOwnerFilter"
                                        placeholder="Filter names..."
                                    >
                                </td>
                                <td>&nbsp;</td>
                                <td class="text-center">Show linked only 
                                    <a class="pad-left align-middle">
                                        <i 
                                            id="toggleIcon"
                                            class="fal fa-lg text-success"
                                            :class="getToggleIcon"
                                            v-on:click.self="showLinkedOnly = !showLinkedOnly"
                                        ></i>
                                    </a>

                                </td>
                            </tr>
                            <tr 
                                v-for="owner, ownerIndex in displayOwners" 
                                v-bind:key="owner.owner_id"
                            >
                                <td class="pad-left">{{owner.owner_name}}</td>
                                <td class="text-center">
                                    <a v-if="owner.alias_id" class="align-middle" >
                                        <i 
                                            id="linkAliasIcon" 
                                            class="fal fa-lg fa-unlink text-danger"
                                            v-on:click="() => removeAliasLink(ownerIndex)"
                                        ></i>
                                    </a>
                                    <a v-else class="align-middle">
                                        <i 
                                            id="linkAliasIcon" 
                                            class="fal fa-lg fa-link text-success"
                                            v-on:click="linkOwnerID = owner.owner_id"
                                        ></i>
                                    </a>
                                </td>
                                <td v-if="owner.alias_name" class="pad-left">
                                    {{ owner.alias_name}}
                                </td>
                                <td v-else-if="linkOwnerID == owner.owner_id">
                                    <div class="form-group col-12 dropdown" 
                                        v-click-away="clearAliasFilter">
                                        <input 
                                            type="text" 
                                            class="form-control" 
                                            id="aliasSelector" 
                                            v-model="aliasFilter"
                                            v-on:keyup.escape="clearAliasFilter"
                                            placeholder="Filter...">

                                        <ul class="quick-search list-unstyled list-group dropdown-menu">
                                            <li class="list-group-item" 
                                                v-bind:class="alias.class" 
                                                v-for="(alias, index) in displayAliases" 
                                                v-bind:key="alias.alias_id"
                                            >
                                                <span v-if="alias.message.length > 0">
                                                    {{alias.message}}
                                                </span>
                                                <a v-on:click="() => addAliasLink(index)">
                                                    {{alias.alias_name}}
                                                </a>
                                            </li>
                                            <li v-if="aliasCount == maxAliases" class="list-group-item report-action-item">
                                                Filter to see more results...
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                                <td v-else>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="col-md-4 report-card shadow border-secondary">
                <div class="card-body">
                    <div class="section-title">Alias Names</div>
                    <table class="table report-table">
                        <thead>
                            <tr>
                                <th class="width-40">Alias Name</th>
                                <th class="width-20">Number of Linked Names</th>
                                <th class="width-20">Edit Alias Name</th>
                                <th class="width-20">Delete Alias Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr 
                                v-for="alias, aliasIndex in aliasNames" 
                                v-bind:key="alias.alias_id"
                            >
                                <td class="pad-left">{{alias.alias_name}}</td>
                                <td class="text-center">{{alias.owner_count}}</td>
                                <td class="text-center">
                                    <a href="javascript:void(0)" class="align-middle" >
                                        <i 
                                            id="editAliasIcon" 
                                            class="fal fa-lg fa-edit text-success"
                                            v-on:click="() => editAliasName(aliasIndex)"
                                        ></i>
                                    </a>
                                </td>
                                <td class="text-center">
                                    <a href="javascript:void(0)" class="align-middle" >
                                        <i 
                                            id="deleteAliasIcon" 
                                            class="fal fa-lg fa-times-circle text-danger"
                                            v-on:click="() => deleteAliasName(aliasIndex)"
                                        ></i>
                                    </a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            user: Object,
            // data: Object,
            alerts: Object,
        },
        data() {
            return {
                aliasNames: [],
                aliasCount: 0,
                errorMessage: '',
                ownerCount: 0,
                ownerNames: [],
                showAliasNameSelector: false,
                formattedAliasNamesList: [],
                formattedOwnerNamesList: [],
                linkOwnerID: 0,
                minSearchLength: 3,
                maxAliases: 50,
                maxOwners: 1000,
                aliasFilter: '',
                ownerFilter: '',
                noDataMessage: {message: '', linktext: '', linkPath: ''},
                showLinkedOnly: false,
            }
        },
        created() {
            this.refreshData();
            },
        computed: { 
            paginator() {
                return {};
            },
            tools() {
                return {
                    hasToolbar: true,
                };
            },
            linkOwner() {
                this.showAliasNameSelector = !this.showAliasNameSelector;
            },
            getToggleIcon() {
                if (this.showLinkedOnly) {
                    return "fa-toggle-on ";
                }
                return "fa-toggle-off ";
            },
            reportHasNoContent() {
                // this.noDataMessage = 'No data found.  This area may have no associated properties.';
                // return !(this.dataHasElement('output') && (typeof this.data.output.data !== 'undefined'));
            },
            displayOwners() {
                if (this.filteredOwnerNames.length > 0) {
                    return this.formatOwnerNamesList;
                }
                if (this.ownerFilter.length > 0) {
                    this.formattedOwnerNamesList = [];
                    this.formattedOwnerNamesList.push({
                        original_owner_name: this.ownerFilter.toUpperCase(),
                        owner_id: 0, 
                        alias_name: '',
                        alias_id: 0, 
                        message: 'No names were found ',
                    });
                    this.ownerCount = 1;

                    return this.formattedOwnerNamesList;
                }
                return '';
            },
            filteredOwnerNames() {
                const filtered = [];
                const regOption = new RegExp(this.ownerFilter, 'ig');
                for (const option of this.ownerNames) {
                    if (this.ownerFilter.length < 1 || option.original_owner_name.match(regOption)) {
                        if (filtered.length < this.maxOwners) filtered.push(option);
                    }
                }
                return filtered;
            },
            formatOwnerNamesList() {
                var j = 0;
                this.formattedOwnerNamesList = [];
                this.ownerCount = 0;
                for (var i = 0; i < this.filteredOwnerNames.length; i++) {
                    var item = this.filteredOwnerNames[i];
                    if (this.showLinkedOnly && !item.alias_id) {
                        continue;
                    }
                    this.formattedOwnerNamesList.push({
                        owner_name: item.original_owner_name,
                        owner_id: item.original_owner_id, 
                        alias_name: item.alias_name,
                        alias_id: item.alias_id, 
                    });
                    ++this.ownerCount;
                }
                return this.formattedOwnerNamesList
            },
            displayAliases() {
                if (this.filteredAliasNames.length > 0) {
                    return this.formatAliasNamesList;
                }
                if (this.aliasFilter.length > 0) {
                    this.formattedAliasNamesList = [];
                    this.formattedAliasNamesList.push({
                        alias_name: this.aliasFilter.toUpperCase(),
                        alias_id: 0, 
                        class: 'report-sub-item', 
                        message: 'Add this alias: ',
                        handler: 'linkToNewAlias',
                        data: {
                            userId:  this.user.userId,
                            aliasName: this.aliasFilter.toUpperCase(),
                            ownerId: this.linkOwnerID
                        },
                    });
                    this.aliasCount = 1;

                    return this.formattedAliasNamesList;
                }
                return '';
            },
            filteredAliasNames() {
                const filtered = [];
                const regOption = new RegExp(this.aliasFilter, 'ig');
                for (const option of this.aliasNames) {
                    if (this.aliasFilter.length < 1 || option.alias_name.match(regOption)) {
                        if (filtered.length < this.maxAliases) filtered.push(option);
                    }
                }
                return filtered;
            },
            formatAliasNamesList() {
                var j = 0;
                this.formattedAliasNamesList = [];
                this.aliasCount = 0;
                for (var i = 0; i < this.filteredAliasNames.length; i++) {
                    var item = this.filteredAliasNames[i];
                    this.formattedAliasNamesList.push({
                        alias_name: item.alias_name,
                        alias_id: item.alias_id, 
                        class: 'report-sub-item', 
                        message: '',
                    });
                    ++this.aliasCount;
                }
                return this.formattedAliasNamesList
            },
        },
        methods: {
            clearAliasFilter() {
                this.linkOwnerID = 0;
                this.aliasFilter = '';
            },
            clearOwnerFilter() {
                this.ownerFilter = '';
            },
            dataHasElement(el) {
                if (typeof this.data !== 'undefined') {
                    return typeof this.data[el] !== 'undefined';
                }
                return false;
            },
            dataElement(el, defVal = false) {
                return this.dataHasElement(el) ? this.data[el] : defVal;
            },
            addAliasLink(index) {
                if (this.formattedAliasNamesList[index] === undefined) {
                    return;
                }
                axios.get('/reports/manage-name-consolidation/update', {
                    params: {
                        aliasId: this.formattedAliasNamesList[index].alias_id,
                        ownerId: this.linkOwnerID,
                        aliasName: this.formattedAliasNamesList[index].alias_name,
                    }
                })
                .then(response => {
                    if (response.data.status_code == 200) {
                        this.refreshData();
                        return;
                    }
                    console.log(response.data.status_message);
                    // Display an error message to user.
                })
                .catch (error => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        if (error.response.data.message !== 'undefined') {
                            this.errorMessage += ' The error message is: ' + error.response.data.message;
                        }
                        console.log(this.errorMessage);
                    }
                })
                .then(() => {
                    this.linkOwnerID = 0;
                    this.clearAliasFilter
                });
                return;
            },
            removeAliasLink(index) {
                if (this.displayOwners[index] === undefined) {
                    return;
                }
                axios.get('/reports/manage-name-consolidation/delete', {
                    params: {
                        aliasId: this.displayOwners[index].alias_id,
                        ownerId: this.displayOwners[index].owner_id,
                        aliasName: this.displayOwners[index].alias_name,
                    }
                })
                .then(response => {
                    if (response.data.status_code == 200) {
                        this.refreshData();
                        return;
                    }
                    console.log(response.data.status_message);
                    // TBA: Display an error message to user.
                })
                .catch (error => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        if (error.response.data.message !== 'undefined') {
                            this.errorMessage += ' The error message is: ' + error.response.data.message;
                        }
                        console.log(this.errorMessage);
                    }
                });
                return;
            },
            editAliasName(index) {
                console.log('Not yet implemented');
                return;
            },
            deleteAliasName(index) {
                if (this.aliasNames[index] === undefined) {
                    return;
                }
                axios.get('/reports/manage-name-consolidation/delete', {
                    params: {
                        aliasId: this.aliasNames[index].alias_id,
                    }
                })
                .then(response => {
                    if (response.data.status_code == 200) {
                        this.refreshData();
                        return;
                    }
                    console.log(response.data.status_message);
                    // TBA: Display an error message to user.
                })
                .catch (error => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        if (error.response.data.message !== 'undefined') {
                            this.errorMessage += ' The error message is: ' + error.response.data.message;
                        }
                        console.log(this.errorMessage);
                    }
                });
                return;
            },
            refreshData() {
                this.refreshOwnerNameList();
                this.refreshAliasNameCounts();
            },
            refreshOwnerNameList() {
                axios.get('/reports/manage-name-consolidation/list/' 
                    + this.user.year + '/'
                    + this.user.areaId, {}
                )
                .then(response => {
                    if (response.data.length == 0) {
                        this.ownerNames = [];
                    }
                    else {
                        this.ownerNames = response.data;
                    }
                })
                .catch (error => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        if (error.response.data.message !== 'undefined') {
                            this.errorMessage += ' The error message is: ' + error.response.data.message;
                        }
                        console.log(this.errorMessage);
                    }
                })
            },
            refreshAliasNameCounts() {
                axios.get('/reports/manage-name-consolidation/list')
                .then(response => {
                    if (response.data.length == 0) {
                        this.aliasNames = [];
                    }
                    else {
                        this.aliasNames = response.data;
                    }
                })
                .catch (error => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        if (error.response.data.message !== 'undefined') {
                            this.errorMessage += ' The error message is: ' + error.response.data.message;
                        }
                        console.log(this.errorMessage);
                    }
                });
            }
        },
        watch: {
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>