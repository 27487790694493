<template>
    <i 
        aria-hidden="true" 
        aria-label="values.label" 
        :class="[iconSize, iconName, iconColor]"
        @click="toggleField"
    ></i>
</template>
<script>
    import {mapState, mapMutations, mapGetters} from 'vuex';

    export default {
        props: {values: Object},
        data() {
            return {
                processing: false,
                reportID: 0,
                userID: 0,
            }
        },
        created() {
            this.reportID = this.values.report_id;
            this.userID = this.values.user_id;
        },
        methods: {
            toggleField: function () {
                if (this.processing == true) {
                    return;
                }
                if (this.visibility === -1) {
                    return;
                }
                var self = this;
                this.processing = true;                
                axios.post(this.url, {
                    visible: (this.visibility ? 0 : 1),
                    user_id: this.userID,
                    report_id: this.reportID,
                    id: this.modelID
                })
                .then(function (response) {
                    self.updateReport({
                        reportID: self.reportID, 
                        userID: self.userID, 
                        modelID: self.modelID,
                        available: 1,
                        visible: response.data.visible
                    })
                })
                .catch(function (error) {
                    if (process.env.NODE_ENV !== 'production') {   
                        console.log(error)
                    }
                })
                .finally( function (response) {
                    self.processing = false;
                });                
            },
            ...mapMutations(['updateReport']),
        },
        computed: {
            iconSize: function () {
                return this.values.icon_size;
            },
            iconColor: function () {
                if (this.processing == true) {
                    return 'text-secondary';
                }
                if(this.visibility === 1) {
                    return 'text-success';
                }
                if(this.visibility === -1) {
                    return 'text-secondary';
                }
                return 'text-secondary';
            },
            iconName: function () {
                if (this.processing == true) {
                    return 'fal fa-circle-notch fa-spin';
                }
                if(this.visibility === 1) {
                    return 'fal fa-eye';
                }
                if (this.visibility === -1) {
                    return 'fal fa-minus'
                }
                return 'fal fa-eye-slash';
            },
            visibility: function () {
                return this.$store.getters.reportVisible(this.values.report_id);
            },
            modelID: function () {
                return this.$store.getters.reportModelID(this.values.report_id)
            },
            url: function () {
                return this.values.route_update + "/" + this.modelID
            }
        },
        mounted() {
            // if (process.env.NODE_ENV !== 'production') {            
            //     console.log('Component mounted.')
            // }
        }
    }
</script>
