<template>
</template>
<script>
    import {handleError} from '../../../mixins/handle-error'
    export default {
        name: 'ReportPayment',
        props: {
            user: Object,
            data: Object,
        },
        data() {
            return {
            }
        },
        created() {
        },
        computed: {
            computedTemplate() {
                return null;
            },
        },
        methods: {
            methodTemplate() {
                return;
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>


