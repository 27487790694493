<template>
    <span>
        <profile
            :urbicsUser="urbicsUser"
        ></profile>
        <subscriptions-enterprise
            v-if="false"
            :urbicsUser="urbicsUser"
        ></subscriptions-enterprise>
        <subscriptions-professional
            v-else
            :urbicsUser="urbicsUser"
        ></subscriptions-professional>
    </span>
</template>
<script>
    export default {
        props: {
            urbicsUser: { Object, required: true },
        },
        data: function() {
            return {
                showEnterpriseVue: this.urbicsUser.isOnEnterprisePlan,
            }
        },
        created() {
        },
        mounted() {
        },
        computed: {
        },
        methods: {
        }
    }
</script>


