<template>
    <div class="row">
        <div class="offset-2 col-8">
            <div class="pricing-card shadow border-secondary h-100">
                <div class="card-body">
                    <div class="top-half">
                        <h1>Professional Plans</h1>
                        <div class="price-quote">
                            <div v-for="(item, index) in planList" class="row price-block">
                                <div class="col-1 offset-2">
                                    <a href="javascript:void(0)">
                                        <i class="text-center" :class="checkPlan(index)"
                                            v-on:click="selectPlan(index)"
                                            area-label="close"
                                        ></i>
                                    </a>
                                </div>
                                <div class="col-4 text-left">
                                    <h4>{{item.term}}</h4>
                                </div>
                                <div class="col-3 text-right">
                                    <h4>{{item.price}}</h4>
                                </div>
                            </div>
                            <div class="row price-block">
                                <div class="col-12 text-left">
                                    <h6>Analytic reports are an additional {{areaReportsPrice}} per area for {{areaReportsLength}} {{areaReportsInterval}}s with a paid subscription.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-half billing-plan row">
                        <div v-if="paymentSucceeded" id="cc-instructions" class="col-12">
                            <payment-success
                                :data="paymentMethodId"
                            ></payment-success>
                        </div>
                        <div v-else id="cc-instructions" class="col-12">
                            <h5>Please enter your payment details. Your card will be charged {{selectedPrice}}.</h5>
                            <h5>The subscription will renew {{renewalPeriod}}. You may cancel the renewal at any time.
                                </h5>
                            <status-alert 
                                v-if="hasAlert" 
                                :statusAlerts="alert" 
                                :standalone=true  
                            ></status-alert>
                            <div id="payment-form" :data-secret="clientSecret">
                                <div class="row mb-4">
                                    <div id="payment-element" class="offset-2 col-8">
                                    <!--Stripe.js injects the Payment Element-->
                                    </div>
                                </div>
                                <div class="row">
                                    <button 
                                        @click="processCard" 
                                        class="btn btn-success btn-lg offset-2 col-8"
                                        id="submit"
                                    >
                                        <span v-if="processing">
                                            <i class="fal fa-lg fa-spinner"></i>
                                        </span>
                                        <span v-else>Subscribe</span>
                                    </button>
                                </div>
                            </div>  
                        </div>
                    </div>                              
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../mixins/handle-error'
    import { loadStripe } from '@stripe/stripe-js';
    export default {
        name: 'BillingProPaid',
        props: {
            data: {Object, required: true},
        },
        components: {
            loadStripe,
        },
        data() {
            return {
                alert: this.data.alerts !== 'undefined' ? this.data.alerts : Object,
                clientSecret: this.data.intent.client_secret,
                customer: this.data.customer,
                paymentElement: null,
                paymentMethodId: '',
                paymentSucceeded: false,
                planSelected: 'quarterly',
                price: this.data.products.subscriptions.enterprise_plan.price.amount,
                price_id: this.data.products.subscriptions.enterprise_plan.price.stripe_id,
                price_lookup_key: this.data.products.subscriptions.enterprise_plan.price.lookup_key,
                processing: false,
                slug_request: this.data.slug_request,
                stripe: Stripe(process.env.MIX_STRIPE_KEY),
                user: null,
            }
        },
        created() {
        },
        watch: {
        },
        computed: {
            elements() {
                return this.stripe.elements({clientSecret: this.clientSecret});
            },
            hasAlert() {
                return Object.keys(this.alert).length > 0;
            },
            yearlyPlan() {
                return this.data.products.subscriptions.pro_plan_year;
            },
            quarterlyPlan() {
                return this.data.products.subscriptions.pro_plan_quarter;
            },
            trialPlan() {
                return this.data.products.subscriptions.pro_plan_trial;
            },
            areaReports() {
                return this.data.products.items.area_reports;
            },
            planList() {    
                let plans = {
                    yearly: {
                        price: this.yearlyPrice, 
                        term: 'Yearly', 
                        price_id: this.yearlyPriceId,
                        name: this.yearlyPlan.display_name,
                        lookup_key: 'pro_plan_year',
                    },
                    quarterly: {
                        price: this.quarterlyPrice, 
                        term: 'Quarterly', 
                        price_id: this.quarterlyPriceId,
                        name: this.quarterlyPlan.display_name,
                        lookup_key: 'pro_plan_quarter',
                    },
                };

                return plans;
            },
            yearlyPrice() {
                return this.formatCurrency(this.yearlyPlan.price.amount);
            },
            quarterlyPrice() {
                return this.formatCurrency(this.quarterlyPlan.price.amount);
            },
            trialPrice() {
                return this.formatCurrency(this.trialPlan.price.amount);
            },
            yearlyPriceId() {
                return this.yearlyPlan.price.stripe_id;
            },
            quarterlyPriceId() {
                return this.quarterlyPlan.price.stripe_id;
            },
            trialPriceId() {
                return this.trialPlan.price.stripe_id;
            },
            areaReportsPrice() {
                return this.formatCurrency(this.areaReports.price.amount);
            },
            areaReportsLength() {
                return this.areaReports.price.interval_count;
            },
            areaReportsInterval() {
                return this.areaReports.price.interval;
            },
            trialLength() {
                return this.data.user.trialLength;
            },
            renewalPeriod() {
                if (this.planSelected == 'yearly') {
                    return 'annually';
                }
                return this.planSelected;
            },
            selectedPrice() {
                return this.planList[this.planSelected].price;
            },
            selectedPriceId() {
                return this.planList[this.planSelected].price_id;
            },
            selectedPeriod() {
                return this.planList[this.planSelected].term;
            },
            selectedName() {
                return this.planList[this.planSelected].name;
            },
            selectedLookupKey() {
                return this.planList[this.planSelected].lookup_key;
            },
            isOnTrial() {
                return (this.data.user.isOnTrial);
            },
            reportPrices() {
                return this.data.products.items;
            },
            useTestMode() {
                return this.data.environment == 'local';
            },
        },
        methods: {
            formatCurrency(value) {
                return '$' + Intl.NumberFormat('en-US').format(Number(value/100));
            },
            initialize() {
                const paymentElementOptions = {
                    layout: "tabs",
                    wallets: {
                        applePay: 'never',
                        googlePay: 'never',
                    }
                };

                this.paymentElement = this.elements.create("payment", paymentElementOptions);
                this.paymentElement.mount("#payment-element");            
            },
            selectPlan(plan) {
                this.planSelected = plan;
            },
            checkPlan(plan) {
                if (this.planSelected === plan) {
                   return this.setCheckIcon('check-circle'); 
                }
                return this.setCheckIcon('circle');
            },
            setCheckIcon(icon) {
                return 'text-success fal fa-lg fa-' + icon;
            },
            redirectToPage(slug) {
                let url = (slug == 'home' ? '/home' : '/reports/' + slug);
                window.location.replace(url);
            },
            startTrial() {
                this.createSubscription();
            },
            startPlan() {
                this.processing = true;
                this.$refs.cardElement.submit();
            },
            processCard() {
                if (this.processing) {
                    return;
                }
                this.processing = true;
                this.stripe.confirmSetup({
                    elements: this.elements, 
                        confirmParams: {
                            return_url: window.location.href,
                        },
                        redirect: 'if_required'
                })
                .then(result => {
                    if (result.error) {
                        this.alert = displayAlert(result.error);
                        return;
                    }
                    if (result.setupIntent && result.setupIntent.status =='succeeded') {
                        this.paymentMethodId = result.setupIntent.payment_method;
                        this.createSubscription();
                    }
                })
                .catch(error => {
                    this.processing = false;
                    this.alert = displayAlert(error);
                });

                this.processing = false;
            },
            createSubscription() {
                this.processing = true;
                axios.post('/subscription/create', {
                    lookupKey: this.selectedLookupKey,
                    custId: this.customer.id,
                    paymentMethodId: this.paymentMethodId,
                    priceId: this.selectedPriceId,
                    planDesc: this.selectedPlanName,
                })
                .then(response => {
                    this.processing = false;
                    if (response.data.status_code == 200) {
                        this.redirectToPage('home');
                        return;
                    }
                    this.displayAlert({
                        message: response.data.status_message,
                    });
                })
                .catch(error => {
                    this.processing = false;
                    this.displayAlert({
                        message: error.message,
                    });
                });
            },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('billing-pro-paid component mounted.')
            }
            this.initialize();
        }
    }
</script>