<template>
    <nav class="navbar navbar-dark navbar-expand-md">
        <div class="container">
            <!-- Branding Image -->
            <a class="navbar-brand" href="/home">
                <img src="/img/urbics-brand-main.png">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div id="navbarSupportedContent" class="collapse navbar-collapse">
                <ul class="navbar-nav mx-auto">
                    <li v-if="isAdmin != 0 && environment == 'production'">
                        <h3 style="color: purple;">Careful! This is the beta production server.</h3>
                    </li>
                    <li v-if="isAdmin != 0 && environment == 'staging'">
                        <h3 style="color: green;">This is the beta staging server.</h3>
                    </li>
                    <li v-if="isAdmin != 0 && (environment == 'local' || environment == 'development')">
                        <h3 style="color: black;">This is the beta local server.</h3>
                    </li>
                 </ul>
                <ul class="navbar-nav ml-md-auto">
                    <li>
                        <a href="/home" class="navbar-icon nav-link">
                            <i class="fa-light fal fa-home fa-2x"></i>
                        </a>
                    </li>
                    <li>
                        <a href="/search" class="navbar-icon nav-link">
                            <i class="fa-light fal fa-search fa-2x"></i>
                        </a>
                    </li>
<!--                     <li class="nav-item">
                        <a @click="displayNotifications" class="has-activity-indicator nav-link">
                            <div class="navbar-icon">
                                <i class="activity-indicator" v-if="unreadNotifications != 0"></i>
                                <i class="fa-light fal fa-bell fa-2x"></i>
                            </div>
                            <urbics-notifications 
                                v-if="showNotifications"
                                :user="user"
                            ></urbics-notifications>                           
                        </a>
                    </li>
 -->                    
                    <div v-if="isAdmin > 0">
                      <!-- Administrator Nav -->
                      <li class="nav-item dropdown">
                          <a href="#" class="d-block d-md-flex navbar-icon nav-link dropdown" id="dropdownAdminMenuButton" data-toggle="dropdown"
                             aria-haspopup="true" aria-expanded="false">
                              <i class="fa-light fal fa-cog fa-2x"></i>
                          </a>
                          <div class="dropdown-menu dropdown-menu-right toolbar-site" aria-labelledby="dropdownMenuButton">
                            <h6 class="dropdown-header">Site Administration</h6>
                            <a class="dropdown-item" href="/admin">
                                  <i class="fa-light fal fa-fw text-left fa-btn fa-cog"></i> Dashboard
                              </a>
                            <a class="dropdown-item" href="/admin/reports">
                                  <i class="fa-light fal fa-fw text-left fa-btn fa-file-alt"></i> Reports
                              </a>
                            <a class="dropdown-item" href="/admin/areas">
                                  <i class="fa-light fal fa-fw text-left fa-btn fa-map-signs"></i> Areas
                              </a>
                            <a class="dropdown-item" href="/admin/users">
                                  <i class="fa-light fal fa-fw text-left fa-btn fa-user"></i> Users
                              </a>
                            <a class="dropdown-item" href="/admin/contacts">
                                  <i class="fa-light fal fa-fw text-left fa-btn fa-address-card"></i> Contacts
                              </a>
                            <a class="dropdown-item" href="/admin/imports">
                                  <i class="fa-light fal fa-fw text-left fa-btn fa-database"></i> Data
                              </a>
                            <a class="dropdown-item" href="/admin/test/output" target="_blank" rel="noopener noreferrer">
                                  <i class="fa-light fal fa-fw text-left fa-btn fa-database"></i> Test Results
                              </a>
                          </div>
                      </li>
                    </div>
                    <!-- User Settings Nav -->
                    <li class="nav-item dropdown">
                        <a href="#" class="d-block d-md-flex has-activity-indicator nav-link dropdown" id="dropdownMenuButton" data-toggle="dropdown"
                           aria-haspopup="true" aria-expanded="false">
                           <div class="navbar-icon">
                            <i class="activity-indicator" v-if="unreadNotificationsCount != 0"></i>
                            <i v-bind:class="userIcon"></i>
                          </div>
                        </a>
                        <div class="dropdown-menu dropdown-menu-right toolbar-site" aria-labelledby="dropdownMenuButton">
                          <div v-if="isImpersonating">
                            <h6 class="dropdown-header">Impersonating</h6>
                            <a class="dropdown-item" href="/admin/users/stop-impersonating">
                                <i class="fa-light fal fa-fw text-left fa-user"></i> Back to Account
                            </a>

                          </div>
                          <!-- Settings -->
                          <h6 class="dropdown-header">Settings</h6>

                          <!-- Profile -->
                          <a class="dropdown-item" href="/settings#profile">
                              <i class="fa-light fal fa-fw text-left fa-user"></i> Profile
                          </a>

                          <!-- Notifications -->
                          <a class="dropdown-item" href="/settings#notifications">
                            <i class="fa-light fal fa-fw text-left fa-bell"></i> Notifications ({{ unreadNotificationsCount }} unread)
                          </a>

                          <!-- Subscription -->
                          <a class="dropdown-item" href="/settings#subscription">
                              <i class="fa-light fal fa-fw text-left fa-cog"></i> Subscription
                          </a>
                          <!-- Preferences -->
                          <a class="dropdown-item" href="/settings#preferences">
                              <i class="fa-light fal fa-fw text-left fa-sliders-h"></i> Preferences
                          </a>

                          <div class="dropdown-divider"></div>

                          <!-- Sign Out -->
                          <a class="dropdown-item" href="/logout">
                              <i class="fa-light fal fa-fw text-left fa-sign-out"></i> Sign Out
                          </a>
                        </div>
                    </li>
                    <li>
                        <a href="https://urbics.zendesk.com/hc/en-us" class="navbar-icon nav-link" target="_blank" rel="nopener noreferrer">
                            <i class="fa-light fal fa-question fa-2x"></i>
                        </a>
                    </li>
                </ul>
            </div>

        </div>
    </nav>
</template>
<script>
  export default {
      props: {
        urbicsUser: Object,
        env: Object,
      },
      data: function() {
        return {
            isLegacyUser: this.urbicsUser.isLegacyUser,
            isAdmin: this.urbicsUser.isAdmin,
            isImpersonating: this.urbicsUser.isImpersonating,
            unreadNotificationsCount: this.urbicsUser.unreadNotificationsCount,
            showNotifications: false,
            environment: this.env.platform,
        }
      },
      created() {
      },
      computed: {
        userIcon() {
          if (this.isImpersonating == true) {
              return 'fa-light fal fa-user-secret fa-2x';
          }
          return 'fa-light fal fa-user-alt fa-2x'
        },
      },
      methods: {
        displayNotifications() {
          if (this.unreadNotificationsCount > 0) {
            this.showNotifications = !this.showNotifications;
          }
        },
      },
      mounted() {
        if (process.env.NODE_ENV !== 'production') {            
            console.log('UrbicsNavbar component mounted.')
        }
      }
  };
</script>