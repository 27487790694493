<template>
    <div class="row">
        <div class="offset-2 col-8">
            <div class="pricing-card shadow border-secondary h-100">
                <div class="card-body">
                    <div class="top-half">
                        <h1>Enterprise Plan</h1>
                        <div class="price-quote">
                        <h4>Tiered Pricing</h4>
                        </div>
                    </div>
                    <div class="bottom-half billing-plan">
                        <ul class="feature-list fa-ul">
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-map-signs"></i>
                                </span>
                                Quickly access all counties, cities, schools and special districts in California and track them in a portfolio. 
                            </li>
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-map-signs"></i>
                                </span>
                                Create new areas by combining existing areas, parcels or tax rate codes.
                            </li>
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-books"></i>
                                </span>
                                View and download summary reports, overlapping areas, CDIAC data and more.
                            </li>
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-books"></i>
                                </span>
                                View and download analytic reports of an area's assessed value, top owners, land use, tax delinquencies and parcel detail with full supporting details.
                            </li>
                            <li>
                                <span class="fa-li">
                                <i class="fal fa-lg fa-books"></i>
                                </span>
                                Drill down to parcel level detail for most reports.
                            </li>
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-books"></i>
                                </span>
                                Consolidate owner names in reports (Alpha Co + Alpha Inc = Alpha Company).
                            </li>
                            <li>
                                <span class="fa-li pad-top">
                                <i class="fal fa-lg fa-download"></i>
                                </span>
                                Download data in PDF, Excel, text and JSON formats as well as through our API.
                            </li>
                        </ul>
                    </div>
                    <div class="bottom-half billing-plan row">
                        <div id="cc-instructions" class="col-12">
                            <status-alert 
                                v-if="hasAlert" 
                                :statusAlerts="alert" 
                                :standalone=true  
                            ></status-alert>
                            <h5>Please enter your payment details. Your card will be charged {{selectedPrice}}.</h5>
                            <h5>The subscription will renew annually. You may cancel the renewal at any time.
                                </h5>
                        </div>
                    </div>
                    <form id="payment-form" :data-secret="clientSecret">
                        <div class="row mb-4">
                            <div id="payment-element" class="offset-2 col-8">
                            <!--Stripe.js injects the Payment Element-->
                            </div>
                        </div>
                        <div v-if="message.length > 0">{{message}}</div>
                        <div class="row">
                            <button @click="processCard" class="btn btn-success btn-lg offset-2 col-8"
                                id="submit"
                            >
                                <span v-if="processing">
                                    <i class="fal fa-lg fa-spinner"></i>
                                </span>
                                <span v-else>Subscribe</span>
                            </button>
                        </div>
                    </form>                                
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../mixins/handle-error'
    import { loadStripe } from '@stripe/stripe-js';
    export default {
        props: {
            data: {Object, required: true},
        },
        components: {
            loadStripe,
        },
        data() {
            return {
                alert: Object(),
                customer: this.data.customer,
                errorMsg: '',
                intent: this.data.intent,
                clientSecret: this.data.intent.client_secret,
                message: '',
                paymentMethodId: '',
                plan: this.data.plan,
                price: this.data.products.subscriptions.enterprise_plan.price.amount,
                price_id: this.data.products.subscriptions.enterprise_plan.price.stripe_id,
                price_lookup_key: this.data.products.subscriptions.enterprise_plan.price.lookup_key,
                processing: false,
                products: this.data.products,
                setup_intent: null,
                showPriceDetail: false,
                slug_request: this.data.slug_request,
                stripe: Stripe(process.env.MIX_STRIPE_KEY),
                stripe_key: process.env.MIX_STRIPE_KEY,
            }
        },
        created() {
        },
        computed: {
            hasAlert() {
                return Object.keys(this.alert).length > 0;
            },
            useTestMode() {
                return this.data.environment == 'local';
            },
            selectedPrice() {
                return this.formatCurrency(this.price);
            },
            elements() {
                return this.stripe.elements({clientSecret: this.clientSecret});
            }
        },
        methods: {
            initialize() {
                const paymentElementOptions = {
                    layout: "tabs",
                    wallets: {
                        applePay: never,
                        googlePay: never,
                    }
                };

                const paymentElement = this.elements.create("payment", paymentElementOptions);
                paymentElement.mount("#payment-element");            
            },
            // Fetches the payment intent status after payment submission
            checkStatus() {
                if (!this.clientSecret) {
                    return;
                }
                const { paymentIntent } = this.stripe.retrievePaymentIntent(this.clientSecret);

                switch (paymentIntent.status) {
                    case "succeeded":
                        showMessage("Payment succeeded!");
                    break;
                    case "processing":
                        showMessage("Your payment is processing.");
                    break;
                    case "requires_payment_method":
                        showMessage("Your payment was not successful, please try again.");
                    break;
                    default:
                        showMessage("Something went wrong.");
                    break;
                }
            },
            getPaymentIntent() {
                return stripe.retrievePaymentIntent(this.clientSecret);
            },

            // displayAlert(error) {
            //     this.alert = handleError({
            //         status: (error.status ? error.status :'warning'), 
            //         message: 'Unable to create a new enterprise subscription. '
            //             + (error.message ? error.message : ''), 
            //         detail: ((error.response 
            //                 && error.response.error 
            //                 && error.response.error.message) 
            //             ? error.response.error.message : ''),
            //         key: (error.key ? error.key : 'urbicsError'),
            //         response: error.response,
            //         request: error.request,
            //     });
            // },
            formatCurrency(value) {
                return '$' + Intl.NumberFormat('en-US').format(Number(value/100));
            },
            processCard() {
                this.processing = true;
                self = this;
                let result = this.stripe.confirmCardSetup(
                    this.clientSecret, {
                        payment_method: {
                            card: this.elements,
                        }
                })
                .then((result) => {
                    if (result.error) {
                        this.processing = false;
                        if (result.error.type === 'card_error' || result.error.type === 'validation_error' ) {
                            // elements will display card/validation error messages
                            return;
                        }
                        // Display other types of error
                        this.displayAlert(result.error);
                    } else {
                        this.paymentMethodId = result.paymentMethod.id;
console.log(this.paymentMethodId);                        
                        // this.createSubscription();
                    }
                })
                .catch(error => {
                    this.processing = false;
                    this.message = error.message;
                })
                this.processing = false;
            },
            tokenCreated (token) {
                this.processing = true;
                let result = this.stripe.createPaymentMethod({
                    type: 'card',
                    card: {token: token.id},
                    billing_details: {
                        name: this.customer.name,
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.processing = false;
                        this.displayAlert({
                            message: result.error.message,
                        });
                    } else {
                        this.paymentMethodId = result.paymentMethod.id;
                        this.createSubscription();
                    }
                })
                .catch(error => {
                    this.processing = false;
                    this.displayAlert({
                        message: error.message,
                    });
                })
            },
            createSubscription() {
                this.processing = true;
                axios.post('/subscription/create', {
                    paymentMethodId: this.paymentMethodId,
                    lookupKey: this.price_lookup_key,
                    custId: this.customer.id,
                    priceId: this.price_id,
                    message: this.message,
                })
                .then(response => {
                    this.processing = false;
                    if (response.data.status_code == 200) {
                        this.redirectToPage(this.slug_request);
                        return;
                    }
                    this.displayAlert({
                        message: response.data.status_message,
                    });
                })
                .catch(error => {
                    this.processing = false;
                    this.displayAlert({
                        message: error.message,
                    });
                });
            },
            redirectToPage(slug) {
                let url = (slug == 'home' ? '/home' : '/reports/' + slug);
                let err = (this.errorMsg != '' ? '?error=' + this.errorMsg : '');
                window.location.replace(url + err);
            },

        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
            this.initialize();
            // this.checkStatus();
        }
    }
</script>