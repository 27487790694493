<template>
    <div class="section-content">
        <div class="toolbar">
            <alert 
              :alerts="alert"
            ></alert>
        </div>
        <div class="row section-title">
            <div class="col-md-12">Your Existing Reporting Areas</div>
        </div>
        <div class="row section-content report-table">
            <div class="col-md-12 table-responsive">
                    <table class="table report-table">
                        <thead>
                            <tr>
                                <th class="width-40">Area Name</th>
                                <th class="width-20">Number of Linked<br/> Areas, APNs or TRAs</th>
                                <th class="width-15">Edit Area</th>
                                <th class="width-15">Delete Area</th>
                            </tr>
                        </thead>
                        <tbody>
                            <template v-for="area, areaIndex in areaNames">
                            <tr v-if="isNewCounty(areaIndex)">
                                <td colspan="4" class="pad-left report-sub-group">{{area.county_name_full}}</td>
                            </tr>
                            <tr>
                                <td class="pad-left-more">{{area.area_name_full}}</td>
                                <td class="text-right">{{displayLinkCount(areaIndex)}}</td>
                                <td v-if="area.id == deletingId" class="text-center">
                                    <a href="javascript:void(0)" class="align-right disabled">
                                        <i 
                                            id="editAreaIcon" 
                                            class="fal fa-lg fa-circle-notch fa-spin text-secondary"
                                        ></i>
                                    </a>
                                </td>
                                <td v-else class="text-center">
                                    <a href="javascript:void(0)" class="align-middle" >
                                        <i 
                                            id="editAreaIcon" 
                                            class="fal fa-lg fa-edit text-success"
                                            v-on:click="() => editArea(area)"
                                        ></i>
                                    </a>
                                </td>
                                <td v-if="area.id == markedForDeletion" class="text-center">
                                    <span>Are you sure?
                                    <a href="javascript:void(0)" class="pad-left" aria-label="Yes">
                                        <i 
                                            id="confirmDeletionIcon" 
                                            class="fal fa-lg fa-thumbs-up text-primary"
                                            v-on:click="() => deletionConfirmed(area.id)"
                                        ></i>
                                    </a>
                                    <a href="javascript:void(0)" class="pad-left"  aria-label="No">
                                        <i 
                                            id="cancelDeletionIcon" 
                                            class="fal fa-lg fa-thumbs-down text-danger"
                                            v-on:click="markedForDeletion = 0"
                                        ></i>
                                    </a>
                                    </span>
                                </td>
                                <td v-else-if="area.id == deletingId" class="text-center">
                                    <a href="javascript:void(0)" class="align-right disabled" >
                                        <i 
                                            id="deleteAreaIcon" 
                                            class="fal fa-lg fa-circle-notch fa-spin text-secondary"
                                        ></i>
                                    </a>
                                </td>
                                <td v-else class="text-center">
                                    <a href="javascript:void(0)" class="align-right" >
                                        <i 
                                            id="deleteAreaIcon" 
                                            class="fal fa-lg fa-times-circle text-danger"
                                            v-on:click="markedForDeletion = area.id"
                                        ></i>
                                    </a>
                                </td>
                            </tr>
                            </template>
                        </tbody>
                    </table>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../../mixins/handle-error'
    export default {
        name: 'ListReportingAreas',
        props: {
            user: Object,
            data: Object,
        },
        data() {
            return {
                alert: Object(),
                messages: this.data.messages,
                pagination: this.data.pagination,
                tools: this.data.tools,
                areaNames: [],
                markedForDeletion: 0,
                deletingId: 0,
            };
        },
        created() {
            this.refreshAreaNames();
        },
        computed: { 
        },
        methods: {
            refreshAreaNames() {
                axios.get('/areas/list-user-areas-raw', {
                    params: {
                        userId: this.user.userId,
                    }
                })
                .then(response => {
                    if (typeof response.data.results === 'undefined') {
                        this.alert = displayAlert('Unable to retrieve area names.');
                        return;
                    }
                    if (response.data.results.length == 0) {
                        this.areaNames = [];
                        this.alert = displayAlert(response.data.status_message);

                        return;
                    }
                    this.areaNames = response.data.results;
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            deletionConfirmed(id) {
                this.markedForDeletion = 0;
                this.deletingId = id;
                axios.get('/areas/delete-user-area/' + id, {
                    params: {
                        userId: this.user.userId,
                    }
                })
                .then(response => {
                    this.deletingId = 0;
                    if (typeof response.data.results === 'undefined') {
                        this.alert = displayAlert('Unable to retrieve area names.');

                        return;
                    }
                    if (response.data.results.length == 0) {
                        this.areaNames = [];
                        this.alert = displayAlert(response.data.status_message);

                        return;
                    }
                    this.areaNames = response.data.results;
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                })
                .then(() => {
                    this.deletingId = 0;
                });
            },
            editArea(area) {
                if (area.group_type == 'area') {
                    this.$store.commit(
                        'updateSelectedVue',
                        {selectedVue: 'editGroup', areaData: area}
                    );
                    return;
                }
                if (area.group_type == 'apn') {
                    this.$store.commit(
                        'updateSelectedVue',
                        {selectedVue: 'editApn', areaData: area}
                    );
                    return;
                }
                if (area.group_type == 'tra') {
                    this.$store.commit(
                        'updateSelectedVue',
                        {selectedVue: 'editTra', areaData: area}
                    );
                    return;
                }
                return;
            },
            displayLinkCount(id) {
                if (this.areaNames[id].group_type == 'area') {
                    return Number(this.areaNames[id].group_item_count).toLocaleString() + ' Areas';
                }
                if (this.areaNames[id].group_type == 'apn') {
                    return Number(this.areaNames[id].group_item_count).toLocaleString() + ' APNs';
                }
                if (this.areaNames[id].group_type == 'tra') {
                    return Number(this.areaNames[id].group_item_count).toLocaleString() + ' TRAs';
                }
                return Number(this.areaNames[id].group_item_count).toLocaleString() + ' unknown type';
            },
            isNewCounty(id) {
                if (id == 0) {
                    return true;
                }
                if (this.areaNames[id].county_id != this.areaNames[id-1].county_id) {
                    return true;
                }
                return false;
            },
        },

        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('list-reporting-areas component mounted.')
            }
        }
    }
</script>


