<template>
    <div class="form-group dropdown" v-click-away="clearSearchAll">
        <input 
            type="text" 
            class="form-control" 
            id="quickSearchInput" 
            v-model="searchFilter"
            v-on:keyup.escape="clearSearchAll"
            v-on:keyup="debounceInput"
            placeholder="Search by Name">
        <ul class="quick-search list-unstyled list-group dropdown-menu" v-if="displaySearchDropdown">
            <li class="list-group-item" 
                v-bind:class="item.class" 
                v-for="(item, index) in formattedSearchAreaList" 
            >
                <span v-html="htmlSearchItem(index)"></span>
            </li>
            <li v-if="areaCount == maxItems" class="list-group-item report-action-item">
                ...and {{remainingAreas}} others
            </li>
        </ul>
    </div>
</template>
<script>
    export default {
        props: ['user'],
        data: function() {
            return {
                searchFilter: '',
                areaList: [],
                formattedSearchAreaList: [],
                areaCount: 0,
                displaySearchDropdown: false,
                displayRecentAreas: false,
                errorMessage: '',
                maxItems: 20,
                searchLimit: 100,
                counties: [],
                countyID: 0,
                areaTypeID: 1018,
                recentAreas: [],
                formattedRecentAreas: [],
                recentAreaID: 0,
            }
        },
        created() {
            this.getCounties();
            this.getRecentAreas();
        },
        watch: {
            countyID: function() {
                this.clearSearchAll();

            },
        },
        methods: {
            getCounties() {
                axios.post('/quick/active-counties')
                    .then(response => {
                        this.counties = response.data;
                    })
                    .catch (response => {
                        this.errorMessage = 'An error occurred while processing this page.';
                        if (process.env.NODE_ENV !== 'production') {
                            this.errorMessage += ' The error message is: ' + response.data;
                        }
                    });
            },          
            getRecentAreas() {
                axios.post('/quick/recent-areas', {
                    limit: 5,
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.recentAreas = [];
                        this.formattedRecentAreas = [{area_name_full: "Nothing was found", class: 'report-sub-group disabled', url: '#'}];
                    }
                    else {
                        this.recentAreas = response.data;
                        this.formatRecentAreas();
                    }
                })
                .catch (response => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        this.errorMessage += ' The error message is: ' + response.data;
                    }
                });
            },
            debounceInput: _.debounce(function(value) {
                this.getAreas()
            }, 300),
            getAreas() {
                if (this.searchFilter.length < 4) {
                    this.clearSearchResults()
                    return
                }
                this.searchFilter = this.searchFilter.substr(0, 50)
                if (this.filteredOptions.length > 0) {
                    this.formatSearchAreaList();
                    this.displaySearchDropdown = true;
                    return;
                }
                axios.post('/quick/area-search-dbd', {
                    limit: this.searchLimit,
                    filter: {
                        name: this.searchFilter.trim(),
                        county: this.countyID,
                        area_type: this.areaTypeID,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.areaList = [];
                        this.areaCount = 1;
                        this.formattedSearchAreaList = [{area_name_full: "Nothing was found", class: 'report-sub-group disabled', url: '#'}];
                    }
                    else {
                        this.areaList = response.data;
                        this.formatSearchAreaList();
                    }
                    this.displaySearchDropdown = true;
                })
                .catch (response => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        this.errorMessage += ' The error message is: ' + response.data;
                    }
                });
            },
            formatSearchAreaList() {
                var county = 'XXX';
                var group = 'YYY';
                var j = 0;
                this.formattedSearchAreaList = [];
                this.areaCount = 0;
                for (var i = 0; i < this.filteredOptions.length; i++) {
                    var item = this.filteredOptions[i];
                    if ((item.county_name_full.length > 0) && (item.county_name_full != county)) {
                        this.formattedSearchAreaList.push({area_name_full: item.county_name_full, class: 'report-group'});
                        county = item.county_name_full;
                    }
                    // if ((item.group_name.length > 0) && (item.group_name != group)) {
                    //     this.formattedSearchAreaList.push({area_name_full: item.group_name, class: 'report-sub-group'});
                    //     group = item.group_name;
                    // }
                    this.formattedSearchAreaList.push({
                        area_name_full: item.area_name_full, 
                        class: 'report-sub-item', 
                        url: '/reports/dirt-bond-data?pareaid=0_' + item.id
                    });
                    ++this.areaCount;
                }
                return this.formattedSearchAreaList
            },
            formatRecentAreas() {
                var county = 'XXX';
                var group = 'YYY';
                var j = 0;
                this.formattedRecentAreas = [];
                for (var i = 0; i < this.recentAreas.length; i++) {
                    var item = this.recentAreas[i];
                    if ((item.county_name_full.length > 0) && (item.county_name_full != county)) {
                        this.formattedRecentAreas.push({area_name_full: item.county_name_full, class: 'report-group'});
                        county = item.county_name_full;
                    }
                    // if ((item.group_name.length > 0) && (item.group_name != group)) {
                    //     this.formattedRecentAreas.push({area_name_full: item.group_name, class: 'report-sub-group'});
                    //     group = item.group_name;
                    // }
                    this.formattedRecentAreas.push({
                        area_name_full: item.area_name_full, 
                        class: 'report-sub-item', 
                        url: '/reports/dirt-bond-data?pareaid=0_' + item.id
                    });
                }
                return this.formattedRecentAreas
            },            
            clearSearchResults() {
                this.areaList = [];
                this.areaCount = 0;
                this.formattedSearchAreaList = [];
                this.resultHeader = '';
                this.displaySearchDropdown = false;
            },
            clearSearchAll() {
                this.clearSearchResults();
                this.searchFilter = '';
            },
            htmlSearchItem(index) {
                if (this.formattedSearchAreaList[index].url) {
                    return '<a href="' + this.formattedSearchAreaList[index].url + '">' + this.formattedSearchAreaList[index].area_name_full + '</a>'
                }
                return this.formattedSearchAreaList[index].area_name_full
            },
            htmlRecentAreas(index) {
                if (this.formattedRecentAreas[index].url) {
                    return '<a href="' + this.formattedRecentAreas[index].url + '">' + this.formattedRecentAreas[index].area_name_full + '</a>'
                }
                return this.formattedRecentAreas[index].area_name_full
            },
            toggleRecentAreas() {
                this.displayRecentAreas = !this.displayRecentAreas;
            },
            hideRecentAreas() {
                this.displayRecentAreas = false;
            },
        },
        computed: {
            currentAreaUrl() {
                return "/admin/areas/edit/" + this.user.current_area_id;
            },
            remainingAreas() {
                return this.areaList.length - this.maxItems;
            },
            searchUrl: function() {
                return '/search/search-areas?filter%5Bname%5D=' + this.searchFilter.trim() + '&filter%5Bgeography%5D=all'
            },
            filteredOptions() {
                const filtered = [];
                const regOption = new RegExp(this.searchFilter, 'ig');
                for (const option of this.areaList) {
                    if (this.searchFilter.length < 1 || option.area_name_full.match(regOption)) {
                        if (filtered.length < this.maxItems) filtered.push(option);
                    }
                }
                return filtered;
            },
            searchGeography() {
                if (this.countyID > 0) {
                    return 
                }
            },
            testCountyOptions() {
                var testCounties = [{CountyName: 'All Counties', CountyID: 0}];
                for (const county of this.counties) {
                    testCounties.push(county);
                }
                return testCounties;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>