<template>
    <div class="section-content">
        <div class="row section-title">
            <div class="col-md-12">Create a Reporting Area</div>
        </div>
        <div class="form-group report-sub-group row">
            <label 
                for="editAreaName" 
                class="offset-2 col-3 col-form-label text-right"
            >
            Area Name
            </label>
            <div class="col-4 text-left">
                <input 
                    type="text" 
                    placeholder="Enter a name"
                    id="editAreaName"
                    v-model="groupName"
                    class="form-control"
                    v-bind:class="{'is-invalid': hasEditGroupNameError}"
                >
                <span role="alert" class="invalid-feedback"><strong>{{editGroupNameError}}</strong></span>
            </div>
        </div>
        <div class="form-group report-sub-group row">
            <label 
                for="countyList" 
                class="offset-2 col-3 col-form-label text-right"
            >
            County
            </label>
            <div class="col-4 text-left">
                <select v-model="county" id="countyList" name="countyList" class="form-control">
                    <option 
                        v-for="(item, index) in countyList" 
                        :value="{id: index, name: item}"
                        :selected="index == user.countyId"
                    >{{item}}</option>
                </select>
            </div>
        </div>
        <div class="form-group report-sub-group row">
            <label 
                for="areaTypeList" 
                class="offset-2 col-3 col-form-label text-right"
            >
            Type
            </label>
            <div class="col-md-4 text-left">
                <select v-model="areaType" id="areaTypeList" name="areaTypeList" class="form-control">
                    <option 
                        v-for="(item, index) in areaTypeList" 
                        :value="{id: index, name: item}"
                        :selected="index == areaType.id"
                    >{{item}}</option>
                </select>
            </div>
        </div>
        <div v-if="areaIsStatewide" class="form-group report-sub-group row">
            <div class="offset-2 col-3 text-right">Made of</div>
            <div class="col-4 text-left">Areas</div>
        </div>
        <div v-else-if="areaIsCountywide" class="form-group report-sub-group row">
            <div class="offset-2 col-3 text-right">Made of</div>
            <div class="col-3 text-left">Areas</div>
        </div>
        <div v-else class="form-group report-sub-group row">
            <label 
                for="definedByList" 
                class="offset-2 col-3 col-form-label text-right"
            >
            Made of
            </label>
            <div class="col-md-4 text-left">
                <select v-model="definedBy" id="definedByList" name="definedByList" class="form-control">
                    <option 
                        v-for="(item, index) in definedByList" 
                        :value="{id: index, name: item}"
                        :selected="index == definedBy.id"
                    >{{item}}</option>
                </select>
            </div>
        </div>
        <div class="form-group report-sub-group row">
            <div class="offset-2 col-8 text-left">
                <span>
                Your new area will be created in <strong>{{county.name}}</strong> county.
                <br/>
                It will be listed under the <strong>{{areaType.name}}</strong> category.
                </span>
                <br/>
                <span v-if="areaIsGroup">
                    You can link other areas listed in this category to your area.
                </span>
                <span v-else>
                    You can link <strong>{{definedBy.name}}</strong> in {{county.name}} county to this area.
                </span>
                <br/>
                <div class="pad-top pad-bottom"> 
                Your area can be used like any other Urbics reporting area.  It will not be visible to others on Urbics.
                </div>
            </div>
        </div>
        <div class="row justify-content-around form-actions form-group">
            <div class="offset-2 col-4">
                <button 
                    name="saveArea" 
                    type="button" 
                    :disabled="disableSave"
                    class="btn btn-success btn-min-width"
                    v-on:click="createGroupName"
                >Save</button>
            </div> 
            <div class="col-4">
                <button 
                    name="cancelCreateArea" 
                    type="button" 
                    class="btn btn-secondary btn-min-width"
                    v-on:click="cancelCreateGroupName"
                >Cancel</button>
            </div> 
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../../mixins/handle-error'
    export default {
        props: {
            user: Object,
            data: Object,
        },
        data() {
            return {
                alert: Object(),
                messages: this.data.messages,
                pagination: this.data.pagination,
                tools: this.data.tools,
                selectedVue: 'create',
                editingGroupName: false,
                editGroupNameError: '',
                groupName: '',
                countyList: {},
                county: {id: this.user.countyId, name: this.user.countyName},
                areaTypeList: {
                    1: 'City',
                    2: 'Elementary School District',
                    3: 'High School District',
                    4: 'Unified School District',
                    5: 'Community College District',
                    9: 'Airport District',
                    10: 'Bridge District',
                    15: 'Flood Control District',
                    21: 'Fire Protection District',
                    26: 'Hospital District',
                    50: 'Regional Park District',
                    57: 'Redevelopment Agency',
                    67: 'Transit District',
                    1010: 'Assessment District',
                    1016: 'User-Defined Area',
                    1017: 'Landscape and Lighting District',
                    1018: 'Mello-Roos CFD',
                    1022: 'Ad Valorem Levies',
                },
                areaType: {},
                definedByList: {
                    'GRP': 'Other Areas',
                    'APN': 'Parcel Numbers',
                    'TRA': 'Tax Rate Area Codes',
                },
                definedBy: {id: 'GRP', name: 'Other Areas'},
            };
        },
        created() {
            this.buildCountyNameList();
            this.areaType = this.defaultAreaType;
        },
        computed: { 
            areaIsStatewide() {
                return this.county.id.toString().substr(-3,3) == '999';
            },
            areaIsCountywide() {
                return this.areaType.name == "County";
            },
            disableSave() {
                return this.groupName.length < 4;
            },
            areaIsGroup() {
                return this.definedBy.id == "GRP";
            },
            defaultAreaType() {
                if (this.areaTypeList.hasOwnProperty(this.user.areaTypeId)) {
                    return {
                        id: this.user.areaTypeId, 
                        name: this.areaTypeList[this.user.areaTypeId]
                    };
                }

                return {id: 1016, name: 'User-Defined Area'};
            },
            hasEditGroupNameError() {
                return this.editGroupNameError.length > 0;
            },
        },
        methods: {
            editGroupName()
            {
                this.groupName = this.$store.getters.areaData.area_name_full;
                this.editingGroupName = true;
            },
            editArea(area) {
                if (area.group_type == 'area') {
                    this.$store.commit('updateSelectedVue', {selectedVue: 'editGroup', areaData: area});
                }
                if (area.group_type == 'apn') {
                    this.$store.commit('updateSelectedVue', {selectedVue: 'editApn', areaData: area});
                }
                if (area.group_type == 'tra') {
                    this.$store.commit('updateSelectedVue', {selectedVue: 'editTra', areaData: area});
                }
            },
            cancelCreateGroupName()
            {
                this.$store.commit('updateSelectedVue', {selectedVue: 'list', areaData: null});
            },
            createGroupName() {
                let url = '/areas/create-user-area-name';
                axios.get(url, {
                    params: {
                        areaName: this.groupName,
                        countyId: this.county.id,
                        typeId: this.areaType.id,
                        definedBy: this.definedBy.id
                    }
                })
                .then(response => {
                    if (response.data.status.status_code != 200) {
                        this.editGroupNameError = response.data.status.status_message;
                        return;
                    }
                    else {
                        this.editArea(response.data.userArea);
                    }
                    this.editingGroupName = false;
                })
                .catch (error => {
                    this.editingGroupName = false;
                    this.alert = displayAlert(error.message);
                });
            },
            buildCountyNameList() {
                let url = '/areas/county-names';
                axios.get(url, {
                    params: {
                        stateID: this.user.stateId,
                    }
                })
                .then(response => {
                    if (response.data.counties === undefined) {
                        this.countyList = {};
                        return;
                    }
                    this.countyList = response.data.counties;
                })
                .catch (error => {
                    this.countyList = {};
                    this.alert = displayAlert(error.message);
                });
            },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('manage-name-consolidation component mounted.')
            }
        }
    }
</script>


