<template>
    <div>
        <div class="row">
            <div class="col-12 hier-list-h1 mb-1">
                <span>Search by Name:</span>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <input 
                    type="text" 
                    class="form-control form-control-md searchBoxText w-100" 
                    id="searchInput" 
                    v-model="searchFilter"
                    v-on:keyup.escape="clearSearch"
                    placeholder="Search for cities, counties or districts">
            </div>
            <div class="col-6">
                <span @click="toggleCounty">
                    <i class="pr-3" :class="countyState"></i>Limit to {{ user.countyName }} county.
                </span>

            </div>
        </div>
        <div v-if="rawData.length > 0" class="row">
            <div class="col-12 hier-list-h1 mt-2 mb-1">
                <span>{{ resultLabel }}</span>
            </div>
        </div>
        <div class="row">
            <template v-for="(col, index1) in [0, 1, 2]" :key="index1">
                <ul class="hier-list col-4">
                    <template v-for="(item, index2) in displayList[col]" :key="index2">
                        <li v-if="hasID(item)" class="" :class="item.class">
                            <a :href="url(item.pareaid)">{{ item.name }}</a>
                        </li>
                        <li v-else class="" :class="item.class">
                            {{ item.name }}
                        </li>
                    </template>
                </ul>
            </template>
        </div>
    </div>
</template>
<script>
    import debounce from 'lodash/debounce';
    import axios from 'axios';
    import {formatAreaList} from '../../../mixins/format-area-list'

    export default {
        name: 'AreaSearch',
        props: {
            user: { Object, required: true },
            attributes: { type: Object, 
                default: function() {
                    return {
                        label: ''
                    } 
                } 
            },
        },
        data() {
            return {
                maxItems: 60,
                displayColumns: 3,
                rawData: [],
                useCounty: false,
                searchLimit: 100,
                minSearchLength: 3,
                displayList: [],
                searchFilter: '',
                countyID: 0,
                hasData: false,
            }
        },
        watch: {
            searchFilter: function() {
                if (this.searchFilter.length > this.minSearchLength) {
                    this.getAreas();
                    return;
                }
                if (this.searchFilter.length > 0) {
                    this.clearResults();
                    return;
                }
                if (this.searchFilter.length == 0) {
                    this.clearSearch();
                    return;
                }
            },
        },
        methods: {
            debounceInput: debounce(function(value) {
                this.getAreas()
            }, 300),
            getAreas() {
                this.searchFilter = this.searchFilter.substr(0, 50)
                if (this.rawData.length > 0) {
                    this.displayList = formatAreaList(this.filteredData, this.displayColumns);
                    return;
                }
                axios.post('/quick/area-search', {
                    limit: this.searchLimit,
                    filter: {
                        name: this.searchFilter.trim(),
                        county: this.countyID,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.displayList = [{
                            name: "Nothing was found", 
                            class: 'hier-h3 disabled', 
                            pareaid: ''
                        }];
                        return;
                    }
                    this.rawData = response.data; 
                    this.displayList = formatAreaList(response.data, this.displayColumns);
                })
                .catch(error => {
                    this.displayAlert({
                        message: error.message,
                    });
                });
            },
            clearSearch() {
                this.clearResults;
                this.searchFilter = '';

            },
            clearResults() {
                this.rawData = [];
                this.displayList = [];
            },
            hasID(item) {
                return (item.pareaid !== undefined && item.pareaid.length > 0);
            },
            url(id) {
                return "/reports/base-page/?pareaid=" + id;
            },
            toggleCounty() {
                this.useCounty = !this.useCounty;
                this.countyID = (this.useCounty ? this.user.countyId : 0);
                this.clearResults();
                if (this.searchFilter.length > this.minSearchLength) {
                    this.getAreas();
                }
            },
        },
        computed: {
            searchUrl: function() {
                return '/search/search-areas?filter%5Bname%5D=' + this.searchFilter.trim() + '&filter%5Bgeography%5D=all'
            },
            filteredData() {
                const filtered = [];
                const regOption = new RegExp(this.searchFilter, 'ig');
                for (const option of this.rawData) {
                    if (this.searchFilter.length < 1 || option.name.match(regOption)) {
                        if (filtered.length < this.maxItems) filtered.push(option);
                    }
                }
                return filtered;
            },
            countyState() {
                if (this.useCounty) {
                    return "fa-regular fa-lg fa-toggle-on text-success"
                }
                return "fa-regular fa-lg fa-toggle-off text-secondary";
            },
            resultLabel() {
                if (this.rawData.length == this.searchLimit) {
                    return "Results (limited to " + this.searchLimit + " records):";
                }
                return "Results:";
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('area-search component mounted.')
            }            
        }
    }
</script>


