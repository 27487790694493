// subscription-info store
// Subscription object structure:
// {subs: 
// 	{active: {plan: {stripe_id: {}}}, area: {stripe_id: {}, ...}}}, 
// 	{cancelled: {plan: {stripe_id: {}}}, area: {stripe_id: {}, ...}}}, 
// 	{ended: {plan: {stripe_id: {}}}, area: {stripe_id: {}, ...}}}, 
// 	{trialing: {plan: {stripe_id: {}}}, area: {stripe_id: {}, ...}}}, 
// }

export default {
	namespaced: false,
	state: {
		subs: {},
		subUsers: {},
	},

	getters: {
	    subs(state) {
	    	return state.subs;
	    },
	    subUsers(state) {
	    	return state.subUsers;
	    },
	},

	actions: {
	},

	mutations: {
		updateSubs(state, payload) {
			if (payload.subs.length == 0) {
				return;
			}
			state.subs = payload.subs;
		},
		
		updateSubUsers(state, payload) {
			if (payload.subUsers.length == 0) {
				return;
			}
			state.subUsers = payload.subUsers;
		},
	},
}
