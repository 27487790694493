// import store from '../store/store';
export function handleError(error) {
    let alertMessage = (error.message === undefined 
        ? 'An error occurred while processing this page. ' 
        : error.message
    );;
    let alertStatus = (error.status === undefined ? 'warning' : error.status);
    let alertKey = (error.key === undefined ? 'errorMessage' : error.key);
    let dismissable = (error.dismissable === undefined ? true : error.dismissable);

    return {[alertKey]: { status: alertStatus, message: alertMessage, dismissable: dismissable}};
}

export function displayAlert(value) {
    let error = {};
    if (typeof value === 'string' || value instanceof String) {
        error.message = value;
    } else {
        error.message = (value.message  === undefined ? '' : value.message);
    }
    error.status = (value.status  === undefined ? 'warning' : value.status );
    error.key = (value.key === undefined ? 'urbics-error' : value.key);

    return handleError(error);
}
