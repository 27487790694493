<template>
    <div>
        <div class="form-inline dropdown mb-0" v-click-away="clearSearchAll">
            <label for="searchInput" class="hier-list-h1" >Search By Name:</label>
            <div class="w-100">
                <input 
                    type="text" 
                    class="form-control form-control-md searchBoxText w-100" 
                    id="searchInput" 
                    v-model="searchFilter"
                    v-on:keyup.escape="clearSearchAll"
                    placeholder="Search for cities, counties or districts">
            </div>

            <ul class="quick-search list-unstyled list-group dropdown-menu" v-if="displaySearchDropdown">
                <li class="list-group-item" 
                    v-bind:class="item.class" 
                    v-for="(item, index) in formattedSearchAreaList" 
                >
                    <span v-html="htmlSearchItem(index)"></span>
                </li>
                <li v-if="areaCount == maxItems" class="list-group-item report-action-item">
                    <a v-bind:href="searchUrl">More Results...</a>
                </li>
            </ul>
        </div>
    </div>
</template>
<script>
    import debounce from 'lodash/debounce';
    import axios from 'axios';

    export default {
        name: 'AreaSearch',
        props: {
            // user: { Object, required: true },
            attributes: { type: Object, 
                default: function() {
                    return {
                        label: ''
                    } 
                } 
            },
        },
        data() {
            return {
                areaGroupID: 0,
                areaList: [],
                areaCount: 0,
                countyID: 0,
                displaySearchDropdown: false,
                errorMessage: '',
                formattedSearchAreaList: [],
                maxItems: 20,
                minSearchLength: 3,
                searchFilter: '',
                searchLimit: 100,
                label: this.attributes.label,
            }
        },
        computed: {
        },
        watch: {
            countyID: function() {
                this.clearSearchAll();
            },
            areaGroupID: function() {
                this.clearSearchAll();
            },
            searchFilter: function() {
                if (this.searchFilter.length > this.minSearchLength) {
                    this.getAreas();
                    return;
                }
                if (this.searchFilter.length == 0) {
                    this.clearSearchResults();
                    return;
                }
                if (this.searchFilter.length <= this.minSearchLength) {
                    this.displaySearchDropdown = false;
                    return;
                }
            },
        },
        methods: {
            debounceInput: debounce(function(value) {
                this.getAreas()
            }, 300),
            getAreas() {
                this.searchFilter = this.searchFilter.substr(0, 50)
                if (this.filteredOptions.length > 0) {
                    this.formatSearchAreaList();
                    this.displaySearchDropdown = true;
                    return;
                }
                axios.post('/quick/area-search', {
                    limit: this.searchLimit,
                    filter: {
                        name: this.searchFilter.trim(),
                        county: this.countyID,
                        area_group: this.areaGroupID,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.areaList = [];
                        this.areaCount = 1;
                        this.formattedSearchAreaList = [{area_name_full: "Nothing was found", class: 'report-sub-group disabled', url: '#'}];
                    }
                    else {
                        this.areaList = response.data;
                        this.formatSearchAreaList();
                    }
                    this.displaySearchDropdown = true;
                })
                .catch (response => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        this.errorMessage += ' The error message is: ' + response.data;
                    }
                });
            },
            formatSearchAreaList() {
                var county = 'XXX';
                var group = 'YYY';
                var j = 0;
                this.formattedSearchAreaList = [];
                this.areaCount = 0;
                for (var i = 0; i < this.filteredOptions.length; i++) {
                    var item = this.filteredOptions[i];
                    if ((item.county_name_full.length > 0) && (item.county_name_full != county)) {
                        this.formattedSearchAreaList.push({area_name_full: item.county_name_full, class: 'report-group'});
                        county = item.county_name_full;
                    }
                    if ((item.group_name.length > 0) && (item.group_name != group)) {
                        this.formattedSearchAreaList.push({area_name_full: item.group_name, class: 'report-sub-group'});
                        group = item.group_name;
                    }
                    this.formattedSearchAreaList.push({
                        area_name_full: item.area_name_full, 
                        class: 'report-sub-item', 
                        url: '/reports/base-page?pareaid=0_' + item.id
                    });
                    ++this.areaCount;
                }
                return this.formattedSearchAreaList
            },
            clearSearchResults() {
                this.areaList = [];
                this.areaCount = 0;
                this.formattedSearchAreaList = [];
                this.resultHeader = '';
                this.displaySearchDropdown = false;
            },
            clearSearchAll() {
                this.clearSearchResults();
                this.searchFilter = '';
            },
            htmlSearchItem(index) {
                if (this.formattedSearchAreaList[index].url) {
                    return '<a href="' + this.formattedSearchAreaList[index].url + '">' + this.formattedSearchAreaList[index].area_name_full + '</a>'
                }
                return this.formattedSearchAreaList[index].area_name_full
            },
        },
        computed: {
            searchUrl: function() {
                return '/search/search-areas?filter%5Bname%5D=' + this.searchFilter.trim() + '&filter%5Bgeography%5D=all'
            },
            filteredOptions() {
                const filtered = [];
                const regOption = new RegExp(this.searchFilter, 'ig');
                for (const option of this.areaList) {
                    if (this.searchFilter.length < 1 || option.area_name_full.match(regOption)) {
                        if (filtered.length < this.maxItems) filtered.push(option);
                    }
                }
                return filtered;
            },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('area-search-box component mounted.')
            }
        }
    }
</script>


