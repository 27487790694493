<template>
    <!-- NOT IN USE - SEE REGISTER.BLADE.PHP -->
<div id="welcome" class="container-fluid">
    <div class="row header"></div>
    <div id="main" class="row">
        <div id="title" class="offset-md-1 col-md-10">
            <h1 v-if="plan === 'ent'">Subscribe to the Enterprise Plan</h1>
            <h1 v-if="plan === 'trial'">Register for a Free Trial</h1>
            <h1 v-else>Subscribe to the Professional Plan</h1>
        </div>
    </div>
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="card card-register">
                <div class="card-body">
                    <alert
                        :alerts="alerts"
                    ></alert>
                    <form id="register-form" @submit.prevent="submitForm">
                        <input type="hidden" name="_token" v-model="csrf">
                        <input type="hidden" id="plan" name="plan" v-model="plan"> 
                        <div class="alert alert-info">
                            <strong>Thanks for your interest in Urbics.</strong><br/>
                            We'll get you registered then ask for your payment information.  Your email and password will be your Urbics login. 
                        </div>
                        <div class="form-group row">

                            <label for="email" class="col-md-4 col-form-label text-md-right">
                                E-Mail
                            </label>

                            <div class="col-md-6">
                                <input 
                                    id="email" 
                                    type="email" 
                                    class="form-control" 
                                    v-bind:class="{ 'is-invalid': hasEmailError }" 
                                    name="email" 
                                    v-model="email" 
                                    required 
                                    autocomplete="email" 
                                    autofocus
                                >
                                <span v-if="hasEmailError" class="invalid-feedback" role="alert">
                                    <strong>{{ emailErrorMessage }}</strong>
                                </span>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="company" class="col-md-4 col-form-label text-md-right"> Organization</label>
                            <div class="col-md-6">
                                <input 
                                    id="company" 
                                    type="text" 
                                    class="form-control" 
                                    name="company" 
                                    v-model="company"
                                    placeholder="Company Name (Optional)">
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>

                            <div class="col-md-6">
                                <input 
                                    id="password" 
                                    type="password" 
                                    class="form-control" 
                                    v-bind:class="{ 'is-invalid': hasPasswordError }" 
                                    name="password" 
                                    v-model="password"
                                    required 
                                    autocomplete="current-password"
                                >
                                <span v-if="hasPasswordError" class="invalid-feedback" role="alert">
                                    <strong>{{ passwordErrorMessage }}</strong>
                                </span>
                            </div>
                        </div>

                        <div class="form-group row">
                            <label for="password-confirm" class="col-md-4 col-form-label text-md-right">Confirm Password</label>

                            <div class="col-md-6">
                                <input 
                                    id="password-confirmation" 
                                    type="password" 
                                    class="form-control" 
                                    v-bind:class="{ 'is-invalid': hasPasswordConfirmError }" 
                                    name="password-confirmation" 
                                    v-model="passwordConfirmation"
                                    required 
                                    autocomplete="current-password"
                                >
                                <span v-if="hasPasswordConfirmError" class="invalid-feedback" role="alert">
                                    <strong>{{ passwordConfirmErrorMessage }}</strong>
                                </span>
                            </div>

                        </div>

                        <!-- Terms And Conditions -->
                        <div class="form-group row">
                            <div class="form-check col-md-6 offset-md-4">
                                <input 
                                    id="terms" 
                                    name="terms" 
                                    v-model="terms"
                                    type="checkbox" 
                                    class="form-check-input"
                                    v-bind:class="{ 'is-invalid': hasTermsError, 'checked': terms }" 
                                >
                                <label class="form-check-label" for="terms">
                                    I Accept 
                                    <a href="/terms" target="_blank">The Terms Of Service</a>
                                </label>
                                <span v-if="hasTermsError" class="invalid-feedback" role="alert">
                                    <strong>{{ termsErrorMessage }}</strong>
                                </span>
                            </div>
                        </div>

                        <div class="form-group row mb-0">
                            <div class="col-md-4 offset-md-5">
                                <button type="submit" class="btn btn-primary">
                                    Subscribe
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>
</template>
<script>
    export default {
        props: {
            data: {Object, required: true},
        },
        data() {
            return {
                plan: this.data.plan,
                alerts: this.data.alerts,
                company: '',
                email: '',
                name: '',
                password: '',
                passwordConfirmation: '',
                terms: false,
                validationErrors: '',
                csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
            }
        },
        created() {
        },

        computed: {
            hasEmailError() {
                return (this.validationErrors.email != undefined);
            }, 
            hasPasswordError() {
                return (this.validationErrors.password != undefined);
            }, 
            hasPasswordConfirmError() {
                return (this.validationErrors.password_confirmation != undefined);
            }, 
            hasTermsError() {
                return (this.validationErrors.terms != undefined);
            }, 
            emailErrorMessage() {
                if (this.validationErrors.email != undefined) {
                    return this.validationErrors.email[0];
                }
                return '';
            },
            passwordErrorMessage() {
                if (this.validationErrors.password != undefined) {
                    return this.validationErrors.password[0];
                }
                return '';
            },
            passwordConfirmErrorMessage() {
                if (this.validationErrors.passwordConfirm != undefined) {
                    return this.validationErrors.password_confirmation[0];
                }
                return '';
            },
            termsErrorMessage() {
                if (this.validationErrors.terms != undefined) {
                    return this.validationErrors.terms[0];
                }
                return '';
            },
            toggleTerms() {
                return !this.terms;
            },
            setName() {
                if (this.name.length) {
                    return;
                }
                if (!this.email.length) {
                    return;
                }
                if (this.email.indexOf('@') == -1) {
                    return;
                }
                let nameArray = this.email.split('@');
                this.name = nameArray[0];
            }
        },
        methods: {
            submitForm() {
                this.setName;
                axios.post('/register', {
                    name: this.name,
                    email: this.email,
                    company: this.company,
                    password: this.password,
                    password_confirmation: this.passwordConfirmation,
                    terms: this.terms,
                    plan: this.plan
                }).then(response => {
                    console.log('successful');
                    window.location.href = '/home';
                }).catch(error => {
                    if (error.response.status == 422){
                        this.validationErrors = error.response.data.errors;
                        if (Object.keys(error.response.data.errors.email).length) {
                            if (error.response.data.errors.email[0] == 'The email has already been taken.') {
                                this.alerts = {warning: 'This email is already registered with Urbics.  Please use our <a href="login">login page</a>, or continue with a different email.'}
                            }
                        }
                    }
                })
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>


