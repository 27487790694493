<template>
    <div class="section-content">
        <div class="row section-content report-table">
            <div class="col-md-12 table-responsive">
                <table class="table report-table">
                    <thead>
                        <tr>
                            <th class="width-45">Linkable Tax Rate Areas</th>
                            <th class="width-10 no-bottom-line"><br/></th>
                            <th class="width-45">Your Area</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="top-align">
                                <table class="table nested-table report-table">
                                    <thead>
                                        <tr>
                                            <th class="width-40 text-center">Tax Rate Areas</th>
                                            <th class="width-20 text-center">Linked TRAs</th>
                                            <th class="width-20 text-center">Link/Unlink</th>
                                            <th class="width-20 text-center">Detail</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                        <td colspan="4" class="pad-left report-sub-group">
                                            Primary TRA Codes
                                        </td>
                                    </tr>
                                    <template v-for="primaries, index in primaryTras">
                                        <tr v-if="index == openedIndex || openedIndex == -1">
                                            <td class="pad-left-more">
                                                {{ primaryTras[index].primary_code }}
                                            </td>
                                            <td class="text-left">
                                                {{ primaryCodeDisplay(index) }}
                                            </td>
                                            <td class="text-center">
                                                <a 
                                                    href="javascript:void(0)" 
                                                    class="pad-left text-center"
                                                    aria-label="unlink"
                                                >
                                                    <i 
                                                        v-if="primaries.linked_tra_count == primaries.tra_count"
                                                        id="unlinkPrimaryTra" 
                                                        class="fal fa-lg fa-unlink text-danger"
                                                        title="Unlink"
                                                        v-on:click="() => unlinkPrimaryTra(index)"
                                                    ></i>
                                                    <i 
                                                        v-else
                                                        id="linkPrimaryTra" 
                                                        class="fal fa-lg fa-link text-success"
                                                        title="Link"
                                                        v-on:click="() => linkPrimaryTra(index)"
                                                    ></i>
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                <a 
                                                    href="javascript:void(0)" 
                                                    class="pad-left text-center" 
                                                    aria-label="Open or Close"
                                                >
                                                    <i 
                                                        v-if="openedIndex == index"
                                                        id="closePrimaryIndex" 
                                                        class="fal fa-2x fa-angle-double-up text-primary"
                                                        title="Close"
                                                        v-on:click="() => closePrimaryIndex(index)"
                                                    ></i>
                                                    <i 
                                                        v-else
                                                        id="openPrimaryIndex" 
                                                        class="fal fa-2x fa-angle-double-down text-primary"
                                                        title="Open"
                                                        v-on:click="() => openPrimaryIndex(index)"
                                                    ></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <template v-if="index == openedIndex">
                                            <tr>
                                                <td colspan="4" class="pad-left-more report-sub-group">
                                                    Tax Rate Area Codes
                                                </td>
                                            </tr>
                                            <template v-for="detail, detailIndex in traDetail">
                                                <tr v-if="traDetail[detailIndex].linked_tra == 0">
                                                    <td class="pad-left-triple">
                                                        {{ traDetail[detailIndex].tra_formatted }}
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td class="text-center">
                                                        <a 
                                                            href="javascript:void(0)" 
                                                            class="pad-left text-center"
                                                            aria-label="unlink"
                                                        >
                                                            <i 
                                                                v-if="traDetail[detailIndex].linked_tra"
                                                                id="unlinkTraCode" 
                                                                class="fal fa-lg fa-unlink text-danger"
                                                                title="UnlinkTra"
                                                                v-on:click="() => unlinkTraCode(detailIndex)"
                                                            ></i>
                                                            <i 
                                                                v-else
                                                                id="linkTraCode" 
                                                                class="fal fa-lg fa-link text-success"
                                                                title="Link"
                                                                v-on:click="() => linkTraCode(detailIndex)"
                                                            ></i>
                                                        </a>
                                                    </td>      
                                                    <td>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </table>
                            </td>
                            <td>
                            </td>
                            <td class="top-align">
                                <table class="table report-table">
                                    <thead>
                                        <tr>
                                            <th class="width-40 text-center">Tax Rate Areas</th>
                                            <th class="width-20 text-center">Linked TRAs</th>
                                            <th class="width-20 text-center">Link/Unlink</th>
                                            <th class="width-20 text-center">Detail</th>
                                        </tr>
                                    </thead>
                                    <tr>
                                        <td colspan="4" class="pad-left report-sub-group">
                                            Primary TRA Codes
                                        </td>
                                    </tr>
                                    <template v-for="linked, index in primaryTras">
                                        <tr v-if="index == openedIndex || (openedIndex == -1 && linked.linked_tra_count > 0)">
                                            <td class="pad-left-more">
                                                {{ primaryTras[index].primary_code }}
                                            </td>
                                            <td class="text-left">
                                                {{ primaryCodeDisplay(index) }}
                                            </td>
                                            <td class="text-center">
                                                <a 
                                                    href="javascript:void(0)" 
                                                    class="pad-left text-center"
                                                    aria-label="unlink"
                                                >
                                                    <i 
                                                        v-if="linked.linked_tra_count > 0"
                                                        id="unlinkPrimaryTra" 
                                                        class="fal fa-lg fa-unlink text-danger"
                                                        title="Unlink"
                                                        v-on:click="() => unlinkPrimaryTra(index)"
                                                    ></i>
                                                    <i 
                                                        v-else
                                                        id="linkPrimaryTra" 
                                                        class="fal fa-lg fa-link text-success"
                                                        title="Link"
                                                        v-on:click="() => linkPrimaryTra(index)"
                                                    ></i>
                                                </a>
                                            </td>
                                            <td class="text-center">
                                                <a 
                                                    href="javascript:void(0)" 
                                                    class="pad-left text-center" 
                                                    aria-label="Open or Close"
                                                >
                                                    <i 
                                                        v-if="openedIndex == index"
                                                        id="closePrimaryIndex" 
                                                        class="fal fa-2x fa-angle-double-up text-primary"
                                                        title="Close"
                                                        v-on:click="() => closePrimaryIndex(index)"
                                                    ></i>
                                                    <i 
                                                        v-else
                                                        id="openPrimaryIndex" 
                                                        class="fal fa-2x fa-angle-double-down text-primary"
                                                        title="Open"
                                                        v-on:click="() => openPrimaryIndex(index)"
                                                    ></i>
                                                </a>
                                            </td>
                                        </tr>
                                        <template v-if="index == openedIndex">
                                            <tr>
                                                <td colspan="4" class="pad-left-more report-sub-group">
                                                    <span v-if="primaryTras[index].linked_tra_count == 0">
                                                        (No Linked TRAs)
                                                    </span>
                                                    <span v-else>
                                                        Tax Rate Area Codes
                                                    </span>
                                                </td>
                                            </tr>
                                            <template v-for="detail, detailIndex in traDetail">
                                                <tr v-if="traDetail[detailIndex].linked_tra == 1">
                                                    <td class="pad-left-triple">
                                                        {{ traDetail[detailIndex].tra_formatted }}
                                                    </td>
                                                    <td>
                                                    </td>
                                                    <td class="text-center">
                                                        <a 
                                                            href="javascript:void(0)" 
                                                            class="pad-left text-center"
                                                            aria-label="unlink"
                                                        >
                                                            <i 
                                                                v-if="traDetail[detailIndex].linked_tra"
                                                                id="unlinkTraCode" 
                                                                class="fal fa-lg fa-unlink text-danger"
                                                                title="UnlinkTra"
                                                                v-on:click="() => unlinkTraCode(detailIndex)"
                                                            ></i>
                                                            <i 
                                                                v-else
                                                                id="linkTraCode" 
                                                                class="fal fa-lg fa-link text-success"
                                                                title="Link"
                                                                v-on:click="() => linkTraCode(detailIndex)"
                                                            ></i>
                                                        </a>
                                                    </td>      
                                                    <td>
                                                    </td>
                                                </tr>
                                            </template>
                                        </template>
                                    </template>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../../mixins/handle-error'
    export default {
        name: 'EditTraArea',
        props: {
            user: Object,
            data: Object,
        },
        data() {
            return {
                alert: Object(),
                messages: this.data.messages,
                errorMessage: '',
                pagination: this.data.pagination,
                tools: this.data.tools,
                primaryTras: [],
                openedIndex: -1,
                traDetail: [],
            }
        },
        created() {
            this.listPrimaryTras();
        },
        computed: {
            parentArea() {
                return this.$store.getters.areaData;
            },
        },
        methods: {
            listPrimaryTras() {
                axios.get('/areas/list-primary-tras', {
                    params: {
                        areaId: this.parentArea.id,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.primaryTras = [];
                    }
                    else {
                        this.primaryTras = response.data;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            listTraDetail()
            {
                axios.get('/areas/list-tra-detail', {
                    params: {
                        areaId: this.parentArea.id,
                        primaryCode: this.primaryTras[this.openedIndex].primary_code,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.traDetail = [];
                    }
                    else {
                        this.traDetail = response.data;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            primaryCodeDisplay(index)
            {
                return this.primaryTras[index].linked_tra_count
                + " of " + this.primaryTras[index].tra_count;
            },
            openPrimaryIndex(index)
            {
                this.TraDetail = [];
                this.openedIndex = index;
                this.listTraDetail();
            },
            closePrimaryIndex(index)
            {
                this.openedIndex = -1;
                this.TraDetail = [];
            },
            linkPrimaryTra(index)
            {
                this.linkUnlinkPrimaryTra(index, 'link');
                if (index == this.openedIndex) {
                    this.listTraDetail();
                }
            },                
            unlinkPrimaryTra(index)
            {
                this.linkUnlinkPrimaryTra(index, 'unlink');
                if (index == this.openedIndex) {
                    this.listTraDetail();
                }
            },                
            linkUnlinkPrimaryTra(index, action) {
                axios.get('/areas/' + action + '-primary-tra', {
                    params: {
                        areaId: this.parentArea.id,
                        primaryCode: this.primaryTras[index].primary_code,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.primaryTras = [];
                    }
                    else {
                        this.primaryTras = response.data;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            linkTraCode(index)
            {
                this.linkUnlinkTraCode(index, 'link');
            },
            unlinkTraCode(index)
            {
                this.linkUnlinkTraCode(index, 'unlink');

            },
            linkUnlinkTraCode(index, action) {
                axios.get('/areas/' + action + '-tra-code', {
                    params: {
                        areaId: this.parentArea.id,
                        traCode: this.traDetail[index].tra,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.primaryTras = [];
                        this.traDetail = [];
                    }
                    else {
                        this.primaryTras = response.data.primaryTras;
                        this.traDetail = response.data.traDetail;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('edit-tra-area component mounted.')
            }
        }
    }
</script>
