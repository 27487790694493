<template>
    <div class="form-group col-4 dropdown">
        <button
            class="btn btn-info btn-lg dropdown-toggle" 
            data-toggle="dropdown"
            type="button" 
            id="recentAreasList" 
            aria-haspopup="true" 
            aria-expanded="false"  
        >
        Recent/Related
        </button>
        <div class="dropdown-menu" aria-labelledby="recentAreasList">
            <span
                v-bind:class="item.class" 
                v-for="(item, index) in formattedRecentAreas" 
                v-html="htmlRecentAreas(index)"                
            >
            </span>
        </div>
    </div>
</template>
<script>
    import axios from 'axios';
    export default {
        name: 'AreasRecentRelated',
        props: {
            user: { Object, required: true },
        },
        data: function() {
            return {
                errorMessage: '',
                recentAreas: [],
                formattedRecentAreas: [],
            }
        },
        created() {
            this.getRecentAreas();
        },
        watch: {
        },
        methods: {
            getRecentAreas() {
                axios.post('/quick/recent-areas', {
                    limit: 10,
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.recentAreas = [];
                        this.formattedRecentAreas = [{area_name_full: "Nothing was found", class: 'report-sub-group disabled', url: '#'}];
                    }
                    else {
                        this.recentAreas = response.data;
                        this.formatRecentAreas();
                    }
                })
                .catch (error => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        if (error.response.data.message !== 'undefined') {
                            this.errorMessage += ' The error message is: ' + error.response.data.message;
                        }
                        console.log(this.errorMessage);
                    }
                });
            },
            formatRecentAreas() {
                var county = 'XXX';
                var group = 'YYY';
                var j = 0;
                this.formattedRecentAreas = [];
                for (var i = 0; i < this.recentAreas.length; i++) {
                    var item = this.recentAreas[i];
                    if ((item.county_name_full.length > 0) && (item.county_name_full != county)) {
                        this.formattedRecentAreas.push({
                            area_name_full: item.county_name_full, 
                            class: 'report-group dropdown-item-text'
                        });
                        county = item.county_name_full;
                    }
                    if ((item.group_name.length > 0) && (item.group_name != group)) {
                        this.formattedRecentAreas.push({
                            area_name_full: item.group_name,
                            class: 'report-sub-group dropdown-item-text'
                        });
                        group = item.group_name;
                    }
                    this.formattedRecentAreas.push({
                        area_name_full: item.area_name_full, 
                        class: 'report-sub-item dropdown-item', 
                        url: '/reports/base-page?pareaid=' + item.parent_area_id
                    });
                }
                return this.formattedRecentAreas
            },            
            htmlRecentAreas(index) {
                if (this.formattedRecentAreas[index].url) {
                    return '<a href="' 
                        + this.formattedRecentAreas[index].url 
                        + '">' 
                        + this.formattedRecentAreas[index].area_name_full 
                        + '</a>'
                }
                return this.formattedRecentAreas[index].area_name_full
            },
        },
        computed: {
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>


