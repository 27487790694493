<template>
    <div>
        <div class="row">
            <div class="col-12 tw-text-site-blue tw-pb-3 tw-font-bold">Search by name:</div>
        </div>
        <div class="row">
            <div class="col-12">
                <area-search-box 
                    :user="user"
                ></area-search-box>
            </div>
        </div>
        <div class="row">
            <div class="col-12 tw-text-site-blue tw-mt-4 tw-font-bold">
                Search by location:
                <span class="pad-left">
                    <a href="javascript:void(0)" @click="toggleLocations">
                    <i :class="showLocationsIcon" aria-label="close"></i>
                    </a>
                </span>
            </div>
        </div>
        <details class="tw--mt-2 tw-mb-10 tw-rounded-xl tw-border tw-px-6 tw-py-3 tw-prose tw-prose-slate open:tw-pb-5 dark:tw-prose-dark dark:tw-border-slate-800" open="">
            <summary class="tw-font-medium tw-cursor-default tw-select-none tw-text-slate-900 dark:tw-text-slate-200">How does this compare to traditional CSS?</summary>
            <p>When writing CSS the traditional way, a single class name would do different things based on the current state.</p>
        </details>


        <div class="row shim-top">
            <div class="form-group col-4 quick-search">
                <a href="/areas/select-area" name="btn_area_search" class="btn btn-success btn-lg">Advanced</a>
            </div>
            <areas-recent-related
                :user="user"
            ></areas-recent-related>
            <areas-portfolio
                :user="user"
            ></areas-portfolio>
        </div>
    </div>
</template>
<script>
    import AreaSummary from './area-summary';
    import AreaQuickSearch from './area-quick-search';
    import AreasRecentRelated from './areas-recent-related';
    export default {
        name: 'AreaSelectionTools',
        props: {
            user: { Object, required: true },
        },
        components: {
            AreaSummary,
            AreaQuickSearch,
            AreasRecentRelated,
        },
        data: function() {
            return {
                showLocations: false,
                showPorfolio: false,
                showRecent: false,
                iconAngleUp: "tw-site-blue tw-font-bold fal fa-lg fa-angle-up",
                iconAngleDown: "tw-site-blue tw-font-bold fal fa-lg fa-angle-down",
            }
        },
        computed: {
            showLocationsIcon() {
                if (this.showLocations === true) {
                   return this.iconAngleUp; 
                }
                return this.iconAngleDown;
            },
        },
        methods: {
            setIcon(icon) {
                return 'fal fa-xl ' + icon;
            },
            toggleLocations() {
                this.showLocations = !this.showLocations;
            }
        }
    }
</script>


