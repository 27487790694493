<template>
    <input 
        type="checkbox" 
        :checked="checked"
        :name="name" 
        :value="value" 
        :id="id"
        @input="checked = !checked"
    >
</template>
<script>
    export default {
        props: {
            settings: Object
        },
        data() {
            return {
                name: this.settings.name,
                value: this.settings.value,
                id: this.settings.id,
                checked: false
            }
        },
        watch: {
            selectAll(newValue, oldValue) {
                this.checked = newValue;
            },
            checked(newValue, oldValue) {
                this.$store.commit({
                    type: 'childrenChecked', 
                    checked: newValue
                });
            }
        },
        computed: {
            selectAll() {
                return this.$store.getters.selectAllChecked;
            }
        },
        created() {
            this.$store.commit('childAdded');
        },
        methods: {
        },
        mounted() {
            // if (process.env.NODE_ENV !== 'production') {            
            //     console.log('Component mounted.')
            // }
        }
    }
</script>
