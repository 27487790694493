<template>
    <div class="section-content">
        <div class="row section-content report-table">
            <div class="offset-1 col-md-10 table-responsive">
                <table class="table report-table">
                    <thead>
                        <tr>
                            <th class="width-45">Your Area</th>
                            <th class="width-10"><br/></th>
                            <th class="width-45">Linkable Areas</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td class="top-align">
                                <table class="table report-table">
                                    <tr v-if="hasSubAreas == false">
                                        <td class="text-center report-sub-group">
                                              (No linked areas)
                                        </td>
                                    </tr>
                                    <template v-for="(subArea, subIndex in subAreas">
                                        <tr v-if="subAreaHasNewCounty(subIndex)">
                                            <td class="pad-left report-sub-group">{{subArea.sub_area_county_name}} County
                                            </td>
                                        </tr>
                                        <tr>
                                          <td class="pad-left-more">
                                              <span>{{subArea.sub_area_name}}
                                                  <a 
                                                      v-if="subIndex == unlinkingId"
                                                      href="javascript:void(0)" 
                                                      class="pad-left disabled"
                                                  >
                                                      <i 
                                                          id="linkArea" 
                                                          class="fal fa-lg fa-circle-notch fa-spin text-secondary"
                                                      ></i>
                                                  </a>
                                                  <a v-else
                                                      href="javascript:void(0)" 
                                                      class="pad-left" 
                                                      aria-label="Unlink"
                                                  >
                                                      <i 
                                                          id="unlinkArea" 
                                                          class="fal fa-lg fa-unlink text-danger"
                                                          v-on:click="() => unlinkArea(subIndex)"
                                                      ></i>
                                                  </a>
                                              </span>
                                          </td>
                                        </tr>
                                    </template>
                                </table>
                            </td>
                            <td>
                            </td>
                            <td class="top-align">
                                <table class="table report-table">
                                    <tbody>
                                      <tr v-if="hasNoLinkableAreas">
                                          <td class="text-center report-sub-group">
                                              (No linkable areas were found)
                                          </td>
                                      </tr>
                                      <template v-for="linkable, linkableIndex in linkableAreas"
                                        :key="linkable.area_id">
                                          <tr v-if="linkableHasNewCounty(linkableIndex)">
                                              <td class="pad-left report-sub-group">
                                                {{linkable.county_name}} County
                                              </td>
                                          </tr>
                                          <tr v-if="hasNewParent(linkableIndex)">
                                              <td class="pad-left report-sub-group">{{linkable.parent_area_name}}
                                              </td>
                                          </tr>
                                          <tr>
                                              <td 
                                                  v-if="linkable.parent_area_name == null"
                                                  class="pad-left-more"
                                              >
                                                  <span>{{linkable.area_name}}
                                                  <a 
                                                      v-if="linkableIndex == linkingId"
                                                      href="javascript:void(0)" 
                                                      class="pad-left disabled"
                                                  >
                                                      <i 
                                                          id="linkArea" 
                                                          class="fal fa-lg fa-circle-notch fa-spin text-secondary"
                                                      ></i>
                                                  </a>
                                                  <a v-else
                                                      href="javascript:void(0)" 
                                                      class="pad-left" 
                                                      aria-label="Link"
                                                  >
                                                      <i 
                                                          id="linkArea" 
                                                          class="fal fa-lg fa-link text-success"
                                                          v-on:click="() => linkArea(linkableIndex)"
                                                      ></i>
                                                  </a>
                                                  </span>
                                              </td>
                                              <td v-else class="pad-left-more">
                                                  <span>- {{linkable.area_name}}
                                                  <a href="javascript:void(0)" class="pad-left" aria-label="Link">
                                                      <i 
                                                          id="linkArea" 
                                                          class="fal fa-lg fa-link text-success"
                                                          v-on:click="() => linkArea(linkableIndex)"
                                                      ></i>
                                                  </a>
                                                  </span>
                                              </td>
                                          </tr>
                                      </template>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../../mixins/handle-error'
    export default {
        name: 'EditGroupArea',
        props: {
            user: Object,
            data: Object,
        },
        data() {
            return {
                alert: Object(),
                messages: this.data.messages,
                pagination: this.data.pagination,
                tools: this.data.tools,
                subAreas: [],
                linkableAreas: [],
                definedBy: '',
                editingGroupName: false,
                editGroupNameError: '',
                groupName: this.$store.getters.areaData.area_name_full,
                linkingId: -1,
                unlinkingId: -1,
            };
        },
        created() {
            this.refreshSubAreas();
            this.listLinkableAreas();
        },
        computed: { 
            parentArea() {
                return this.$store.getters.areaData;
            },
            hasNoLinkableAreas() {
                return this.linkableAreas.length == 0;
           },
           hasSubAreas() {
                return this.subAreas.length != 0;
            },
           alertNoLinkableAreas() {
                this.$store.commit('updateHasLinkableAreas', {hasLinkableAreas: false});
                this.$store.updateMessages({
                    status: 'warning', 
                    message: "The area category <strong>" 
                    + this.parentArea.type_name 
                    + "</strong> has no areas to link with.  You can change the category by editing your area name."
                });
                return true;
           }
        },
        methods: {
            isLinking(id) {
                return this.linkingId == id;
            },
            isUnlinking(id) {
                return this.unlinkingId == id;
            },
            refreshSubAreas() {
                axios.get('/areas/list-sub-areas', {
                    params: {
                        areaId: this.parentArea.id,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.subAreas = [];
                    }
                    else {
                        this.subAreas = response.data;
                        this.definedBy = this.subAreas[0].sub_area_defined_by;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            listLinkableAreas() {
                axios.get('/areas/list-linkable-areas', {
                    params: {
                        userId: this.user.userId,
                        accountId: this.user.accountId,
                        areaId: this.parentArea.id,
                        countyId: this.parentArea.county_id,
                        typeId: this.parentArea.type_id,
                        definedBy: this.definedBy,
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.linkableAreas = [];
                        if (! this.hasSubAreas) {
                            this.alertNoLinkableAreas;
                        }
                }
                    else {
                        this.linkableAreas = response.data;
                    }
                })
                .catch (error => {
                    this.alert = displayAlert(error.message);
                });
            },
            linkArea(linkableIndex) {
                if (this.definedBy.length == 0) {
                    this.definedBy = this.linkableAreas[linkableIndex].area_defined_by
                }
                this.linkingId = linkableIndex;
                this.addRemoveSubArea(this.linkableAreas[linkableIndex].area_id, 'link');
            },
            unlinkArea(subAreaIndex) {
                this.unlinkingId = subAreaIndex;
                this.addRemoveSubArea(this.subAreas[subAreaIndex].sub_area_id, 'unlink');
                if (this.subAreas.length == 1) {
                    this.definedBy = '';
                }
            },
            addRemoveSubArea(id, action) {
                let url = '/areas/' + action + '-sub-area';
                axios.get(url, {
                    params: {
                        areaId: this.parentArea.id,
                        subAreaId: id
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.subAreas = [];
                    }
                    else {
                        this.subAreas = response.data;
                    }
                    this.updateAreaGroupCount(response.data.length);
                    this.listLinkableAreas();
                    this.linkingId = -1;
                    this.unlinkingId = -1;
                })
                .catch (error => {
                    this.linkingId = -1;
                    this.unlinkingId = -1;
                    this.alert = displayAlert(error.message);
                });
            },
            updateAreaGroupCount(count) {
                return this.$store.commit('updateAreaGroupCount', {count: count});
            },
            subAreaHasNewCounty(subAreaIndex) {
                if (subAreaIndex == 0) {
                    return true;
                }
                if (this.subAreas[subAreaIndex].sub_area_county_name 
                    != this.subAreas[subAreaIndex - 1].sub_area_county_name) {
                    return true;
                }

                return false;
            },
            linkableHasNewCounty(linkableIndex) {
                if (linkableIndex == 0) {
                    return true;
                }
                if (this.linkableAreas[linkableIndex].county_name 
                    != this.linkableAreas[linkableIndex - 1].county_name) {
                    return true;
                }

                return false;
            },
            hasNewParent(linkableIndex) {
                if (this.linkableAreas[linkableIndex].parent_area_name !== null) {
                    if (linkableIndex == 0) {
                        return true;
                    }
                    if (this.linkableAreas[linkableIndex - 1].parent_area_name === null) {
                        return true;
                    }
                    if (this.linkableAreas[linkableIndex].parent_area_name 
                        != this.linkableAreas[linkableIndex - 1].parent_area_name) {
                        return true;
                    }

                    return false;
                }

                return false;
            },
        },

        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('edit-group-area component mounted.')
            }
        }
    }
</script>


