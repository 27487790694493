<template>
    <div class="col-md-12 report-card shadow border-secondary">
        <div class="card-body">
            <div class="section-title mb-0" :class="{ 'no-bottom-line': showCard === false }">
                Find Reporting Areas
                <span class="pl-2">
                    <a href="#" @click="toggleShowCard">
                    <i :class="showCardIcon" aria-label="close"></i>
                    </a>
                </span>
            </div>
            <div v-if="showCard === true">
                <div class="sub-section-title text-center">
                    Your current reporting area is:<br/>
                    <span v-if="user.parentAreaName.length > 0">
                        {{ user.parentAreaName }}<br/>
                    </span>
                    {{ user.areaName }} ({{ user.fullCountyName }})
                </div>
                <div class="container text-center my-3">
                    <div class="row section-content justify-content-center">
                        <div class="col-3" >
                            <button 
                                type="button" 
                                class="btn btn-md "
                                :class="buttonState(showListByName)"
                                @click="toggleListByName"
                            >
                            By Name
                            </button>
                        </div>
                        <div class="col-3" >
                            <button 
                                type="button" 
                                class="btn btn-md "
                                :class="buttonState(showListByCounty)"
                                @click="toggleListByCounty"
                            >
                            By County
                            </button>
                        </div>
                        <div class="col-3" >
                            <button 
                                type="button" 
                                class="btn btn-md "
                                :class="buttonState(showRecentAreas)"
                                @click="toggleListRecentAreas"
                            >
                            Recent/Related
                            </button>
                        </div>
                        <div class="col-3" >
                            <button 
                                type="button" 
                                class="btn btn-md "
                                :class="buttonState(showPortfolioAreas)"
                                @click="toggleListPortfolioAreas"
                            >
                            Portfolio
                            </button>
                        </div>
                    </div>
                </div>
                <div v-if="showListByName === true" class="row section-content">
                    <div class="col-12">
                        <area-search 
                            :user="user"
                        ></area-search>
                    </div>
                </div>
                <div v-if="showListByCounty === true" class="row section-content">
                    <div class="col-12">
                        <area-list-by-county
                            :user="user"
                        ></area-list-by-county>
                    </div>
                </div>

                <div v-if="showRecentAreas === true" class="row section-content">
                    <div class="col-12">
                        <recent-areas
                            :user="user"
                        ></recent-areas>
                    </div>
                </div>

                <div v-if="showPortfolioAreas === true" class="row section-content">
                    <div class="col-12">
                        <portfolio-areas
                            :user="user"
                        ></portfolio-areas>
                    </div>
                </div>
            </div>
        </div>
   </div>
</template>
<script>
    import AreaSearch from './area-search';
    import AreaListByCounty from './area-list-by-county';
    import RecentAreas from './recent-areas';
    export default {
        name: 'AreaSelectionTools',
        props: {
            user: { Object, required: true },
        },
        components: {
            AreaSearch,
            AreaListByCounty,
            RecentAreas
        },
        data: function() {
            return {
                showCard: false,
                showListByName: true,
                showListByCounty: false,
                showRecentAreas: false,
                showRelatedAreas: false,
                showPortfolioAreas: false,
            }
        },
        computed: {
            showCardIcon() {
                if (this.showCard === true) {
                   return "fa-thin fa-chevron-circle-up"; 
                }
                return "fa-thin fa-chevron-circle-down";
            },

        },
        methods: {
            buttonState(show) {
                if (show === true) {
                    return 'btn-outline-secondary disabled';
                }
                return 'btn-outline-primary';
            },
            toggleShowCard() {
                this.showCard = ! this.showCard;
                this.showListByName = this.showCard;
                this.showListByCounty = false;
                this.showRecentAreas = false;
                this.showRelatedAreas = false;
                this.showPortfolioAreas = false;
            },
            toggleListByName() {
                this.showListByName = ! this.showListByName;
                this.showListByCounty = false;
                this.showRecentAreas = false;
                this.showRelatedAreas = false;
                this.showPortfolioAreas = false;
            },
            toggleListByCounty() {
                this.showListByCounty = ! this.showListByCounty;
                this.showListByName = false;
                this.showRecentAreas = false;
                this.showRelatedAreas = false;
                this.showPortfolioAreas = false;
            },
            toggleListRecentAreas() {
                this.showRecentAreas = ! this.showRecentAreas;
                this.showListByName = false;
                this.showListByCounty = false;
                this.showRelatedAreas = false;
                this.showPortfolioAreas = false;
            },
            toggleListRelatedAreas() {
                this.showRelatedAreas = ! this.showRelatedAreas;
                this.showListByName = false;
                this.showListByCounty = false;
                this.showRecentAreas = false;
                this.showPortfolioAreas = false;
            },
            toggleListPortfolioAreas() {
                this.showPortfolioAreas = ! this.showPortfolioAreas;
                this.showListByName = false;
                this.showListByCounty = false;
                this.showRelatedAreas = false;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('area-selection-tools component mounted.')
            }
        }
    }
</script>


