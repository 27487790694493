<template>
    <div>
        <div v-if="countyID == 0" class="row section-content">
            <div class="col-12 sub-section-title">
                Select a county:
            </div>
        </div>
        <div v-if="countyID > 0 && typeID == 0" class="row section-content">
            <div class="col-12 sub-section-title">
                Select the area type in <a href="javascript:void(0)" @click="clearCounty()"> {{ countyName.toUpperCase() }} COUNTY</a>:
            </div>
        </div>
        <div v-if="countyID > 0 && typeID > 0" class="row section-content">
            <div class="col-12 sub-section-title">
                Select an area in <a href="javascript:void(0)" @click="clearCounty()"> {{ countyName.toUpperCase() }} COUNTY</a> >> <a href="javascript:void(0)" @click="clearType()"> {{ typeName.toUpperCase() }}</a>:
            </div>
        </div>

        <div v-if="displayList.length > 0" class="row">
            <template v-for="col in [0, 1, 2]">
                <ul class="report-list list-unstyled col-4">
                    <template v-for="(item, index) in displayList[col]">
                        <template v-if="item.type == 'area'">
                            <template v-if="item.group.length > 0">
                                <li class="report-item item-label">{{ item.group }}</li>
                            </template>
                            <li class="report-item"
                                :class="{
                                    'report-sub-item': item.isSubArea == 1,
                                    'item-label': item.isParent == 1,
                                    'report-single-item': item.type == 'area' && item.isParent + item.isSubArea == 0
                                }">
                                <a :href="url(item.id)">{{ item.name }}</a>
                            </li>
                        </template>
                        <template v-else>
                            <li v-if="item.group.length > 0" 
                                class="report-group pb-0" 
                                :class="{'first': index == 0}"
                            >{{ item.group }}</li>
                            <li class="report-item"
                                :class="{
                                    'report-sub-item': item.isSubArea == 1,
                                    'item-label': item.isParent == 1,
                                    'report-single-item': item.type == 'area' && item.isParent + item.isSubArea == 0
                                }">
                                <a href="javascript:void(0)" @click="selectItem(item.id, item.name)">{{ item.name }}</a>
                            </li>
                        </template>
                    </template>
                </ul>
            </template>
        </div>
    </div>
</template>
<script>
    import {handleError} from '../../../mixins/handle-error'
    export default {
        name: 'AreaListByCounty',
        props: {
            user: { Object, required: true },
        },
        components: {
        },
        data: function() {
            return {
                alert: Object(),
                countyID: 0,
                countyName: '',
                typeID: 0,
                typeName: '',
                areaID: 0,
                maxItems: 60,
                displayList: [],
            }
        },
        computed: {
            clearAll() {
                this.countyID = 0;
                this.countyName = '';
                this.typeID = 0;
                this.typeName = '';
                this.areaID = 0;
                this.displayList = [];
            },
        },
        methods: {
            listAreasByCounty() {
                axios.post('/areas/list-areas-by-county', {
                    countyID: this.countyID,
                    typeID: this.typeID,
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.clearAll();
                        this.displayAlert({
                            message: "Nothing was found.",
                            status: "info"
                        })
                        return;
                    } 
                    return this.formatDisplayList(response.data);
                })
                .catch(error => {
                    this.displayAlert({
                        message: error.message,
                    });
                });
            },
            formatDisplayList(rawData) {
                this.displayList = [[],[],[]];
                let colMax = Math.ceil(Math.min(this.maxItems, rawData.length) / 3);
                let itemName = '', 
                    itemGroup = '', 
                    itemID = 0, 
                    lastGroup = '', 
                    isSubArea = 0, 
                    isParent = 0,
                    itemType = '';
                for (let i = 0, j = 0, k = 0; i < rawData.length; i++) {
                    if (this.countyID == 0) {
                        // Counties
                        itemName = rawData[i].county_name;
                        itemID = rawData[i].county_id;
                        itemType = 'county';
                    } else if (this.typeID == 0) {
                        // Types
                        itemGroup = '';
                        if (rawData[i].group_name != lastGroup) {
                            itemGroup = rawData[i].group_name;
                            lastGroup = itemGroup;
                        }
                        if ((rawData[i].group_name == lastGroup) && (k == 0) && (j != 0)) {
                            itemGroup = rawData[i].group_name + " (Continued)";
                        }
                        itemName = rawData[i].category_plural;
                        itemID = rawData[i].type_id;
                        itemType = 'type';
                    } else {
                        // Areas
                        itemName = rawData[i].area_name;
                        itemGroup = '';
                        if (rawData[i].is_header) {
                            itemName = rawData[i].group_header;
                            lastGroup = itemName;
                        }
                        if ((rawData[i].parent_area_name == lastGroup) && (k == 0) && (j != 0)) {
                            itemGroup = lastGroup + " (Continued)";
                        }
                        isSubArea = rawData[i].is_grouped;
                        isParent = rawData[i].is_header;
                        itemID = rawData[i].pareaid;
                        itemType = 'area';
                    }
                    this.displayList[j].push({
                        group: itemGroup,
                        name: itemName,
                        id: itemID,
                        isSubArea: isSubArea,
                        isParent: isParent,
                        type: itemType,
                    });
                    ++k;
                    if (k + 1 == colMax) {
                        ++j;
                        k = 0;
                    }
                }
            },
            url(id) {
                return "/reports/base-page/?pareaid=" + id;
            },
            selectItem(id, name) {
                if (this.countyID == 0) {
                    this.countyID = id;
                    this.countyName = name;
                    return this.listAreasByCounty();
                }
                if (this.typeID == 0) {
                    this.typeID = id;
                    this.typeName = name;
                    return this.listAreasByCounty();
                }

                return null;
            },
            clearCounty() {
                this.countyID = 0;
                this.countyName = '';
                this.typeID = 0;
                this.typeName = '';
                return this.listAreasByCounty();
            },
            clearType() {
                this.typeID = 0;
                this.typeName = '';
                return this.listAreasByCounty();
            },
            displayAlert(error) {
                this.alert = handleError({
                    status: (error.status ? error.status :'warning'), 
                    message: 'An error occurred. '
                        + (error.message ? error.message : ''), 
                    detail: ((error.response 
                            && error.response.error 
                            && error.response.error.message) 
                        ? error.response.error.message : ''),
                    key: (error.key ? error.key : 'urbicsError'),
                    response: error.response,
                    request: error.request,
                });
            },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('area-list-by-county component mounted.')
            }
            this.clearAll;
            this.listAreasByCounty();
        },
    }
</script>
