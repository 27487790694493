import { createApp } from 'vue'
import { createStore } from 'vuex'
import { store } from './store/store.js'
import moment from 'moment'
import VueClickAway from "vue3-click-away";

const app = createApp({
    data() {
        return {
        }
    },
    created() {
    },
    computed: {
        urbics() {
            return window.Urbics;
        },

        user() {
            return window.Urbics.user;
        },

        environment() {
            return window.Urbics.environment;
        },
    },
    methods: {
    }
})

app.use(store)
app.use(VueClickAway);

import './forms/bootstrap.js'


require('./bootstrap');
// Start - used for polyfill, replace with laravel-mix-polyfill when mix 6 can be used.
import 'core-js/stable';
import "regenerator-runtime/runtime";
// End - used for polyfill, replace with laravel-mix-polyfill when mix 6 can be used.
import 'bootstrap/js/dist/alert';


/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => app.component(key.split('/').pop().split('.')[0], files(key).default))

import vSelect from 'vue-select';
app.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';


import {Tabs, Tab} from 'vue3-tabs-component';
app.component('tabs', Tabs);
app.component('tab', Tab);

// Filters (migration from Vue2 filter function)
app.config.globalProperties.$filters = {
    date(value) {
        return moment.utc(value).local().format('MMMM Do, YYYY')
    },
    dateShort(value) {
        return moment.utc(value).local().format('M/DD/YYYY')
    },
    datetime(value) {
        return moment.utc(value).local().format('MMMM Do, YYYY h:mm A');
    },
    datetimeShort(value) {
        return moment.utc(value).local().format('M/DD/YYYY h:mm A');
    },
    relative(value) {
        return moment.utc(value).local().locale('en').fromNow();
    },
    capitalize(value) {
        if (! value && value !== 0) {
            return '';
        }

        return value.toString().charAt(0).toUpperCase()
            + value.slice(1);
    },
    currency(value) {
        const Dinero = require('dinero.js').default

        return Dinero({
            amount: Math.round(value * 100),
            currency: window.Urbics.currency
        }).setLocale(window.Urbics.currencyLocale).toFormat('$0,0.00');
    },

}

app.mount('#app')

// require('./components/bootstrap');
// require('./filters');
require('./forms/bootstrap');

/*
const app = new Vue({
    el: '#app',
    store,

    data: {

    },
    created() {
        var self = this;

        if (this.user.userId) {
            this.loadDataForAuthenticatedUser();
        }
    },

    computed: {
    	urbics() {
    		return window.Urbics;
    	},

        user() {
            return window.Urbics.user;
        },

        environment() {
            return window.Urbics.environment;
        },
    },
    methods: {
        loadDataForAuthenticatedUser() {
        },
    }
});
*/

/* Turn off devtools in production */
if (process.env.NODE_ENV === 'production') {
    app.config.devtools = false;
    app.config.debug = false;
    app.config.silent = true; 
}
