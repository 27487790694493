<template>
    <div>
        <div class="row">
            <div class="col-12 hier-list-h1">
                <span v-if="countyID == 0">Select a county:</span>
                <span v-else-if="countyID > 0 && typeID == 0">
                    Select the area type in <a href="javascript:void(0)" @click="clearCounty()"> {{ countyName.toUpperCase() }} COUNTY</a>:
                </span>
                <span v-else-if="countyID > 0 && typeID > 0">
                    Select an area in 
                    <a href="javascript:void(0)" @click="clearCounty()">
                        {{ countyName.toUpperCase() }} COUNTY
                    </a> >> 
                    <a href="javascript:void(0)" @click="clearType()"> 
                        {{ typeName.toUpperCase() }}
                    </a>:
                </span>
                <span v-else></span>
            </div>
        </div>
        <div class="row">
            <template v-for="col in columnIndex" :key="col">
                <ul class="hier-list col-4">
                    <template v-for="(item, index) in displayList[col]"
                        :key="index">
                        <li v-if="item.id == 0" class="" :class="item.class">
                            {{ item.name }}
                        </li>
                        <li v-else-if="typeID == 0" class="" :class="item.class">
                            <a 
                                :href="url(item.id)" 
                                @click="selectItem(item.id, item.name)"
                            >{{ item.name }}</a>
                        </li>
                        <li v-else class="" :class="item.class">
                            <a :href="url(item.id)">{{ item.name }}</a>
                        </li>
                    </template>
                </ul>
            </template>
        </div>
    </div>
</template>
<script>
    import {handleError} from '../../../mixins/handle-error'
    import {formatAreaList} from '../../../mixins/format-area-list'
    import {countDisplayLines} from '../../../mixins/format-area-list'
    import {countGroups} from '../../../mixins/format-area-list'
    import {countParents} from '../../../mixins/format-area-list'
    export default {
        name: 'AreaListByCounty',
        props: {
            user: { Object, required: true },
        },
        components: {
        },
        data: function() {
            return {
                alert: Object(),
                countyID: 0,
                countyName: '',
                typeID: 0,
                typeName: '',
                areaID: 0,
                maxItems: 60,
                displayList: [],
                displayColumns: 3,
            }
        },
        computed: {
            columnIndex() {
                let cols = [];
                for (let i = 0; i < this.displayColumns; i++) {
                    cols.push(i);
                }
                return cols;
            },
        },
        methods: {
            listAreasByCounty() {
                axios.post('/areas/list-areas-by-county', {
                    countyID: this.countyID,
                    typeID: this.typeID,
                    userID: this.user.userId
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.clearAll();
                        return;
                    } 
                    return this.formatDisplayList(response.data);
                })
                .catch(error => {
                    handleError(error);
                });
            },
            formatDisplayList(rawData) {
                if (this.countyID == 0) {
                    return this.formatCountyList(rawData);
                }
                if (this.typeID == 0) {
                    return this.formatGroupList(rawData);
                }
                try {
                    this.displayList = formatAreaList(rawData, 3);
                } catch(error) {
                    console.log(error)
                    handleError(error);
                } finally {
                    return this.displayList;
                }
                // return this.formatAreaList(rawData);
            },

            formatCountyList(rawData) {
                this.displayList = [[],[],[]];
                let itemCount = rawData.length
                let maxCols = 3;
                let colMax = Math.ceil(itemCount / maxCols);
                let itemName = '', 
                    itemGroup = '', 
                    itemID = 0;
                for (let i = 0, j = 0, k = 0; i < rawData.length; i++) {
                    this.displayList[j].push({
                        name: rawData[i].county_name,
                        id: rawData[i].county_id,
                        class: "hier-item-h1",
                    });
                    k++;
                    if (k + 1 >= colMax && j < (maxCols - 1)) {
                        j = j + 1; 
                        k = 0;
                    }
                }
                return this.displayList;
            },
            formatGroupList(rawData) {
                this.displayList = [[],[],[]];
                let itemCount = rawData.length + countGroups(rawData);
                let maxCols = 3;
                let colMax = Math.ceil(itemCount / maxCols);
                let itemName = '', 
                    itemClass = '', 
                    lastGroup = '';
                for (let i = 0, j = 0, k = 0; i < rawData.length; i++) {
                    itemName = '';
                    if (rawData[i].group_name == lastGroup && (k == 0)) {
                        this.displayList[j].push({
                            name: rawData[i].group_name + " (Continued)",
                            id: 0,
                            class: "hier-h1 first",
                        });
                        k++;
                    }
                    if (rawData[i].group_name != lastGroup) {
                        lastGroup = rawData[i].group_name;
                        itemName = rawData[i].group_name;
                        itemClass = "hier-h1";
                        if (k == 0) {
                            itemClass += " first";
                        }

                        this.displayList[j].push({
                            name: itemName,
                            id: 0,
                            class: itemClass,
                        });
                        k++;
                    }

                    this.displayList[j].push({
                        name: rawData[i].category_plural,
                        id: rawData[i].type_id,
                        class: "hier-item-h1",
                    });
                    k++;
                    if (k + 1 >= colMax && j < (maxCols - 1)) {
                        j = j + 1; 
                        k = 0;
                    }
                }
                return this.displayList;
            },
            clearAll() {
                this.countyID = 0;
                this.countyName = '';
                this.typeID = 0;
                this.typeName = '';
                this.areaID = 0;
                this.displayList = [];
            },

/*            
            formatAreaList(rawData) {
                this.displayList = [[],[],[]];
                let itemCount = rawData.length + countParents(rawData);
                let maxCols = 3;
                let colMax = Math.ceil(itemCount / maxCols);
                let itemName = '', 
                    itemGroup = '', 
                    itemID = '',
                    lastParent = '';

                for (let i = 0, j = 0, k = 0; i < rawData.length; i++) {
                    if (rawData[i].group_header == lastParent && (k == 0)) {
                        this.displayList[j].push({
                            name: rawData[i].group_header + " (Continued)",
                            id: 0,
                            class: "hier-h3 first",
                        });
                        k++;
                    }
                    if (rawData[i].is_header) {
                        lastParent = rawData[i].group_header;

                        this.displayList[j].push({
                            name: rawData[i].group_header,
                            id: rawData[i].pareaid,
                            class: "hier-h3",
                        });
                        // k++;
                    }
                    if (rawData[i].parent_area_id == 0) {
                        this.displayList[j].push({
                            name: rawData[i].area_name,
                            id: rawData[i].pareaid,
                            class: "hier-item-h1",
                        });

                    } else {
                        this.displayList[j].push({
                            name: rawData[i].area_name,
                            id: rawData[i].pareaid,
                            class: "hier-item-h3",
                        });
                    }

                    k++;
                    if (k + 1 >= colMax && j < (maxCols - 1)) {
                        j = j + 1; 
                        k = 0;
                    }
                }
    
                return this.displayList;
            },
*/            
            url(id) {
                if (this.typeID != 0) {
                    return "/reports/base-page?pareaid=" + id;
                }
                return "javascript:void(0)";
            },

            selectItem(id, name) {
                if (this.countyID == 0) {
                    this.countyID = id;
                    this.countyName = name;
                    return this.listAreasByCounty();
                }
                if (this.typeID == 0) {
                    this.typeID = id;
                    this.typeName = name;
                    return this.listAreasByCounty();
                }

                return null;
            },
            clearCounty() {
                this.countyID = 0;
                this.countyName = '';
                this.typeID = 0;
                this.typeName = '';
                return this.listAreasByCounty();
            },
            clearType() {
                this.typeID = 0;
                this.typeName = '';
                return this.listAreasByCounty();
            },
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('area-list-by-county component mounted.')
            }
            this.clearAll;
            this.listAreasByCounty();
        },
    }
</script>
