<template>
    <div>
    <!-- <div class="form-group col-12 dropdown quick-search"> -->
        <a 
            class="btn btn-info btn-lg dropdown-toggle" 
            href="#" 
            role="button" 
            id="recentAreasList" 
            aria-haspopup="true" 
            aria-expanded="false"  
            v-on:click="toggleRecentAreas" 
            v-click-away="hideRecentAreas"
        >
        Recent Areas
        </a>
        <ul class="quick-search list-unstyled list-group dropdown-menu" v-if="displayRecentAreas" aria-labelledby="recentAreasList">
            <li class="list-group-item" 
                v-bind:class="item.class" 
                v-for="(item, index) in formattedRecentAreas" 
            >
            <span v-html="htmlRecentAreas(index)"></span>
            </li>
        </ul>
    <!-- </div> -->
    </div>
</template>
<script>
    import axios from 'axios';
    export default {
        name: 'AreasRecentRelated',
        props: {
            user: { Object, required: true },
        },
        data: function() {
            return {
                displayRecentAreas: false,
                errorMessage: '',
                recentAreas: [],
                formattedRecentAreas: [],
                recentAreaID: 0,
            }
        },
        created() {
            this.getRecentAreas();
        },
        watch: {
        },
        methods: {
            getRecentAreas() {
                axios.post('/quick/recent-areas', {
                    limit: 5,
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.recentAreas = [];
                        this.formattedRecentAreas = [{area_name_full: "Nothing was found", class: 'report-sub-group disabled', url: '#'}];
                    }
                    else {
                        this.recentAreas = response.data;
                        this.formatRecentAreas();
                    }
                })
                .catch (error => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        if (error.response.data.message !== 'undefined') {
                            this.errorMessage += ' The error message is: ' + error.response.data.message;
                        }
                        console.log(this.errorMessage);
                    }
                });
            },
            formatRecentAreas() {
                var county = 'XXX';
                var group = 'YYY';
                var j = 0;
                this.formattedRecentAreas = [];
                for (var i = 0; i < this.recentAreas.length; i++) {
                    var item = this.recentAreas[i];
                    if ((item.county_name_full.length > 0) && (item.county_name_full != county)) {
                        this.formattedRecentAreas.push({area_name_full: item.county_name_full, class: 'report-group'});
                        county = item.county_name_full;
                    }
                    if ((item.group_name.length > 0) && (item.group_name != group)) {
                        this.formattedRecentAreas.push({area_name_full: item.group_name, class: 'report-sub-group'});
                        group = item.group_name;
                    }
                    this.formattedRecentAreas.push({
                        area_name_full: item.area_name_full, 
                        class: 'report-sub-item', 
                        url: '/reports/base-page?pareaid=' + item.pareaid
                    });
                }
                return this.formattedRecentAreas
            },            
            htmlRecentAreas(index) {
                if (this.formattedRecentAreas[index].url) {
                    return '<a href="' + this.formattedRecentAreas[index].url + '">' + this.formattedRecentAreas[index].area_name_full + '</a>'
                }
                return this.formattedRecentAreas[index].area_name_full
            },
            toggleRecentAreas() {
                this.displayRecentAreas = !this.displayRecentAreas;
            },
            hideRecentAreas() {
                this.displayRecentAreas = false;
            },
        },
        computed: {
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>


