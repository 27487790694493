<template>
    <div id="welcome" class="container-fluid intro pricing">
        <div class="row container-pricing pricing-cards">
            <div v-if="billingType === 'ent'" class="offset-md-2 col-md-8">
                <billing-ent
                    :data="data"
                >
                </billing-ent>
            </div>
            <div v-else-if="billingType === 'rep'" class="offset-md-2 col-md-8">
                <billing-area-reports
                    :data="data"
                >
                </billing-area-reports>
            </div>
            <div v-else-if="billingType === 'pro'" class="offset-md-2 col-md-8">
                <billing-pro-free
                    :data="data"
                >
                </billing-pro-free>
            </div>
            <div v-else-if="billingType === 'trial'" class="offset-md-2 col-md-8">
                <billing-area-reports-trial
                    :data="data"
                >
                </billing-area-reports-trial>
            </div>
            <div v-else class="offset-md-2 col-md-8">
                <pricing
                    :data="data"
                >
                </pricing>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            data: {Object, required: true},
        },
        data() {
            return {
                plan: this.data.plan,
            }
        },
        created() {
        },
        computed: {
            billingType() {
                if (this.data.user.isSubscribed) {
                    if (this.data.plan.startsWith('enterprise')) {
                        return 'ent';
                    }
                    if (this.data.user.isOnTrial) {
                        return 'trial';
                    }
                    if (this.data.slug_request) {
                        return 'rep';
                    }
                    return 'pro';
                }
                if (this.data.plan) {
                    if (this.data.plan.startsWith('enterprise')) {
                        return 'ent';
                    }
                    if (this.data.plan.startsWith('pro')) {
                        return 'pro';
                    }
                }

                return 'pro'
            },
        },
        methods: {
        },
        watch: {
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Billing component mounted.')
            }
        }
    }
</script>


