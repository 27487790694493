<template>
<!-- @includeWhen($data->displayToolbar($sec_id), 'reports.report-toolbar')
@includeWhen(empty($data->sectionHasContent($sec_id)), 'reports.no-data-message')
@if ($data->sectionHasContent($sec_id))
    @include('reports.table-header')
    @include('reports.section-title')
    @php $noTitle = (empty($data->sectionConfig($sec_id, 'section_title')) ? ' no-section-title ' : ''); @endphp
    <div class="row section-content {{$noTitle}}">
        @for( $col_id = 1; $col_id < ($data->sectionConfig($sec_id, 'columns') + 1); $col_id++)
        <div class="col-md-{{ $data->sectionColumnWidth($sec_id, $col_id) }}">
            <ul class="report-list list-unstyled">
                @foreach (($data->sectionOutput($sec_id, $col_id) ?: []) as $rowKey => $rowValue)
                    @foreach (($rowValue ?: []) as $cols => $col)
                        @foreach (($col->data ?: []) as $colKey => $colValue)
                            @php
                            $hideLabel = (isset($col->meta->hide_label) ? $col->meta->hide_label : false);
                            $label = (isset($col->meta->label) ? $col->meta->label : '');
                            $icon = (isset($col->meta->icon_class) ? $col->meta->icon_class : '');
                            $link = (isset($col->links->related) ? $col->links->related : '');
                            @endphp
                            <li class="{{ $col->meta->data_class ?? ''}}">
                            @if ($label and !$hideLabel)
                                <span class="item-label">{{ $label }}: </span>
                            @endif
                            @if ($link)
                                <a href="{{ $link }}" target="{{ $col->meta->link_target ?? ''}}" rel="{{ $col->meta->link_rel ?? ''}}">
                                    @if ($icon))
                                    <i class="{{ $icon }}"  aria-hidden="true" aria-label="{{ $label }}"></i>></i>
                                    @endif
                                    {{ $colValue }}
                                </a>
                            @else 
                                {!! $colValue !!}
                            @endif
                            </li>
                        @endforeach
                    @endforeach
                @endforeach
            </ul>
        </div>
        @endfor
    </div>
@endif     -->
</template>
<script>
    export default {
        props: {
        },
        data() {
            return {
            }
        },
        created() {
        },
        computed: {
            computedTemplate() {
                return null;
            },
        },
        methods: {
            methodTemplate() {
                return;
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>


