<template>
    <div class="row">
        <div class="offset-2 col-8">
            <div class="pricing-card shadow border-secondary h-100">
                <div class="card-body">
                    <div class="top-half">
                        <h1>Sample Analytic Reports</h1>
                        <div class="price-quote-no-border">
                            <div class="row price-block">
                                <div class="col-10 offset-1 text-left">
                                    <h4>Analytic reports are provided for random areas during a free trial.  You will be viewing data for <strong>{{trial_area_name}}</strong></h4>
                                </div>
                            </div>
                            <div class="row price-block">
                                <div class="col-10 offset-1 text-left">
                                    <h6>Professional plan subscribers may purchase and view analytic reports for an area for {{areaReportsLength}} {{areaReportsInterval}}.  Enterprise plan subscribers receive full access to analytic reports.</h6>
                                </div>
                            </div>
                            <div class="row price-block">
                                <status-alert 
                                    v-if="hasAlert" 
                                    :statusAlerts="alert" 
                                    :standalone=true  
                                ></status-alert>
                                <div class="col-4 offset-4">
                                    <button @click="viewTrialReport" class="btn btn-success btn-lg btn-block"
                                    >
                                        <span v-if="processing">
                                            <i class="fal fa-lg fa-spinner"></i>
                                        </span>
                                        <span v-else>Continue</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {displayAlert} from '../../mixins/handle-error'
    import { loadStripe } from '@stripe/stripe-js';
    export default {
        name: 'BillingAreaReportsTrial',
        props: {
            data: {Object, required: true},
        },
        components: {
            loadStripe,
        },
        data() {
            return {
                alert: this.data.alerts !== 'undefined' ? this.data.alerts : Object,
                clientSecret: this.data.intent.client_secret,
                customer: this.data.customer,
                isOnTrial: this.data.user.isOnTrial,
                billingMessage: '',
                paymentMethodId: '',
                processing: false,
                reports: this.data.products.items,
                slug_request: this.data.slug_request,
                statusMessage: this.data.status,
                stripe: Stripe(process.env.MIX_STRIPE_KEY),
                trial_area_id: this.data.trial_area_id,
                trial_area_name: this.data.trial_area_name,
                user: this.data.user,
            }
        },
        created() {
        },
        computed: {
            elements() {
                return this.stripe.elements({clientSecret: this.clientSecret});
            },
            hasAlert() {
                return Object.keys(this.alert).length > 0;
            },
            reportItem() {
                return this.reports.area_reports;
            },
            reportTrial() {
                return this.reports.area_reports_trial;
            },
            reportType() {
                return this.reportItem.display_name;
            },
            reportTrialPriceID() {
                return this.reportTrial.price.stripe_id;
            },
            selectedLookupKey() {
                return 'area_reports';
            },
            areaReportsTrialLookupKey() {
                return this.reportTrial.price.lookup_key;
            },
            areaReportsPriceId() {
                return this.reportItem.price.stripe_id;
            },
            areaReportsPrice() {
                return this.formatCurrency(this.reportItem.price.amount);
            },
            areaReportsLength() {
                return this.reportItem.price.interval_count;
            },
            areaReportsInterval() {
                return this.reportItem.price.interval;
            },
            areaReportsIntervalPlural() {
                return this.areaReportsInterval + (this.areaReportsLength == 1 ? '' : 's');
            },
            hasParentArea() {
                return this.user.parentAreaId != 0;
            },
            hasSubAreas() {
                return this.user.hasSubAreas;
            },
            areaID() {
                if (this.user.parentAreaId != 0) {
                    return this.user.parentAreaId;
                }
                return this.user.areaId;
            },
            fullAreaName() {
                if (this.user.parentAreaName.length == 0) {
                    return this.user.areaName + " (" + this.user.countyName + " County, " + this.user.stateAbbr + ")";
                }
                return this.user.parentAreaName + " (" + this.user.countyName + " County, " + this.user.stateAbbr + ")";
            },
        },
        methods: {
            formatCurrency(value) {
                return '$' + Number(value/100).toFixed(0).toLocaleString();
            },
            redirectToPage(slug) {
                let url = (slug == 'home' ? '/home' : '/reports/' + slug);
                window.location.replace(url);
            },
            cancel() {
                this.redirectToPage('home');
            },
            viewTrialReport() {
                this.processing = true;
                axios.post('/subscription/create_trial_area_access', {
                    areaId: this.trial_area_id,
                    lookupKey: this.areaReportsTrialLookupKey,
                    custId: this.customer.id,
                    priceId: this.reportTrialPriceID
                })
                .then(response => {
                    this.processing = false;
                    this.redirectToPage(this.slug_request);
                })
                .catch(error => {
                    this.processing = false;
                    this.alert = displayAlert({
                        message: error.message,
                    });
                });
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('billing-area-reports-trial component mounted.')
            }
        }
    }
</script>