<template>
    <div class="form-inline dropdown mb-0" >
        <div class="w-100">
            <input 
                type="text" 
                class="form-control form-control-md searchBoxText w-100" 
                :id="searchBoxId" 
                v-model="searchFilter"
                v-on:keyup.escape="clearSearch()"
                placeholder="Search for cities, counties or districts">
        </div>
        <template v-if="displayMenu">
            <ul v-for="col in columnIndex" class="quick-search list-unstyled list-group dropdown-menu" >
                <template v-for="(item, index) in displayList[col]">
                    <li v-if="hasID(item)" class="" :class="item.class">
                        <a :href="url(item.pareaid)">{{ item.name }}</a>
                    </li>
                    <li v-else class="" :class="item.class">
                        {{ item.name }}
                    </li>
                </template>
            </ul>
        </template>
    </div>
</template>
<script>
    import debounce from 'lodash/debounce';
    import { directive } from "vue3-click-away";    
    import axios from 'axios';
    import {formatAreaList} from '../../../mixins/format-area-list'
    import {handleError} from '../../../mixins/handle-error'

    export default {
        name: 'AreaSearchDropdown',
        props: {
            user: { Object, required: true },
            attributes: { type: Object, 
                default: function() {
                    return {
                        label: ''
                    } 
                } 
            },
        },
        data() {
            return {
                maxItems: 20,
                rawData: [],
                minSearchLength: 3,
                displayList: [],
                displayColumns: 1,
                searchFilter: '',
                hasData: false,
                displaySearchDropdown: false,
                searchBoxId: "searchInput_"+((Math.random()+1).toString(36).substring(2)),
            }
        },
        directives: {
            ClickAway: directive
        },        
        watch: {
            searchFilter: function() {
                if (this.searchFilter.length > this.minSearchLength) {
                    this.getAreas();
                    return;
                }
                if (this.searchFilter.length > 0) {
                    this.clearResults();
                    return;
                }
                if (this.searchFilter.length == 0) {
                    this.clearSearch();
                    return;
                }
            },
        },
        methods: {
            debounceInput: debounce(function(value) {
                this.getAreas()
            }, 300),
            getAreas() {
                this.searchFilter = this.searchFilter.substr(0, 50)
                if (this.rawData.length > 0) {
                    this.displayList = formatAreaList(this.filteredData, this.displayColumns);
                    return;
                }
                axios.post('/quick/area-search', {
                    limit: this.maxItems,
                    filter: {
                        name: this.searchFilter.trim(),
                    }
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.displayList = [{
                            name: "Nothing was found", 
                            class: 'area-h3 disabled', 
                            pareaid: ''
                        }];
                        return;
                    }
                    this.rawData = response.data; 
                    this.displayList = formatAreaList(response.data, this.displayColumns);
                })
                .catch(error => {
                    handleError(error);
                });
            },
            clearSearch() {
                this.clearResults;
                this.searchFilter = '';

            },
            clearResults() {
                this.rawData = [];
                this.displayList = [];
            },
            hasID(item) {
                return (item.pareaid.length > 0);
            },
            url(id) {
                return "/reports/base-page/?pareaid=" + id;
            },
        },
        computed: {
            searchUrl: function() {
                return '/search/search-areas?filter%5Bname%5D=' + this.searchFilter.trim() + '&filter%5Bgeography%5D=all'
            },
            displayMenu() {
                return (this.displayList.length > 0);
            },
            filteredData() {
                const filtered = [];
                const regOption = new RegExp(this.searchFilter, 'ig');
                for (const option of this.rawData) {
                    if (this.searchFilter.length < 1 || option.name.match(regOption)) {
                        if (filtered.length < this.maxItems) filtered.push(option);
                    }
                }
                return filtered;
            },
            countyState() {
                if (this.useCounty) {
                    return "fa-regular fa-lg fa-toggle-on text-success"
                }
                return "fa-regular fa-lg fa-toggle-off text-secondary";
            },
            columnIndex() {
                let cols = [];
                for (let i = 0; i < this.displayColumns; i++) {
                    cols.push(i);
                }
                return cols;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('area-search component mounted.')
            }            
        }
    }
</script>


