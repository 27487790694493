<template>
    <div class="vue-content">
        <div class="tabs-component-panel">
            <div class="toolbar">
                <alert 
                  :alerts="propMessages"
                ></alert>
            </div>
            <div class="row section-title no-bottom-line">
                <div class="offset-2 col-md-4 text-center">
                    <button 
                        type="button" 
                        v-on:click="selectVue('list', null)"
                        class="btn btn-lg shim-y-2"
                        :class="listAreaButton">
                    List Your Areas
                    </button>
                </div>
                <div class="col-md-4 text-center">
                    <button 
                        type="button" 
                        v-on:click="selectVue('create', null)"
                        class="btn btn-lg shim-y-2"
                        :class="createAreaButton">
                    Create a New Area
                    </button>
                </div>
            </div>
            <div v-if="showAreaNameInTitle" class="row section-title">
                <div class="col-md-12">
                    <div class="row">
                        <div class="col-md-12">
                            {{ titleFirstLine }} in {{ parentArea.county_name_full }}
                        </div>
                    </div>
                    <div v-if="editingGroupName == false" class="row">
                        <div class="col-md-12">
                        to {{ parentArea.area_name_full }}
                            <a 
                                href="javascript:void(0)" 
                                class="pad-left" 
                                aria-label="Edit Name"
                            >
                                <i 
                                    id="editGroupName" 
                                    class="fal fa-lg fa-edit text-success"
                                    v-on:click="() => editGroupName()"
                                ></i>
                            </a>
                        </div>
                    </div>
                    <div v-else class="form-group row">
                        <input 
                            type="text" 
                            id="editGroupName"
                            v-model="groupName"
                            class="form-control offset-3 col-md-6"
                            v-bind:class="{'is-invalid': hasEditGroupNameError}"
                        >
                        <a 
                            href="javascript:void(0)" 
                            class="col-md-1 text-center"
                            aria-label="Save"
                        >
                            <i 
                                id="saveEditedGroupName" 
                                class="fal fa-2x fa-check text-success"
                                title="Save"
                                v-on:click="() => saveEditedGroupName()"
                            ></i>
                        </a>
                        <a 
                            href="javascript:void(0)" 
                            class="col-md-1 text-center" 
                            aria-label="Cancel"
                        >
                            <i 
                                id="cancelEditGroupName" 
                                class="fal fa-2x fa-times text-secondary"
                                title="Cancel"
                                v-on:click="() => cancelEditGroupName()"
                            ></i>
                        </a>
                        <span role="alert" class="invalid-feedback"><strong>{{editGroupNameError}}</strong></span>
                    </div>
                </div>
            </div>
        </div>
        <list-reporting-areas
            v-if="selectedVue == 'list'"
            :user="user"
            :data="data"
        ></list-reporting-areas>
        <edit-group-area
            v-if="selectedVue == 'editGroup'"
            :user="user"
            :data="data"
        ></edit-group-area>
        <edit-tra-area
            v-if="selectedVue == 'editTra'"
            :user="user"
            :data="data"
        ></edit-tra-area>
        <edit-apn-area
            v-if="selectedVue == 'editApn'"
            :user="user"
            :data="data"
        ></edit-apn-area>
        <edit-empty-area
            v-if="selectedVue == 'editEmptyArea'"
            :user="user"
            :data="data"
        ></edit-empty-area>
        <create-reporting-area
            v-if="selectedVue == 'create'"
            :user="user"
            :data="data"
        ></create-reporting-area>
    </div>
</template>
<script>
    import {displayAlert} from '../../../mixins/handle-error'
    export default {
        props: {
            user: Object,
            data: Object,
        },
        data() {
            return {
                alert: Object(),
                propMessages: this.data.messages,
                propPagination: this.data.pagination,
                propTools: this.data.tools,
                messages: this.$store.getters.messages,
                pagination: this.$store.getters.pagination,
                tools: this.$store.getters.tools,
                editingGroupName: false,
                editGroupNameError: '',
                groupName: '',
            };
        },
        created() {
            this.$store.commit('updateMessages', {messages: this.data.propMessages});            
            this.$store.commit('updatePagination', {pagination: this.data.propPagination});            
            this.$store.commit('updateTools', {tools: this.data.propTools});            
        },
        computed: { 
            hasParentAreaData() {
                return this.$store.getters.areaData !== null;
            },
            parentArea() {
                if (this.hasParentAreaData) {
                    return this.$store.getters.areaData;
                }
                return null;
            },
            isDefinedByGRP() {
                if (this.hasParentAreaData) {
                    return this.parentArea.area_defined_by == 'GRP';
                }
                return null;
            },
            showAreaNameInTitle() {
                return (this.selectedVue != 'editEmptyArea') &&  this.hasParentAreaData;
            },
            titleFirstLine() {
                if (this.hasParentAreaData) {
                    if (this.parentArea.group_type == 'area') {
                        return 'Add and Remove ' + this.parentArea.group_name;
                    }
                    if (this.parentArea.group_type == 'tra') {
                        return 'Add and Remove Tax Rate Area Codes';
                    }
                    if (this.parentArea.group_type == 'apn') {
                        return 'Add and Remove Parcel Numbers';
                    }
                }
                return '';
            },
            selectedVue() {
                return this.$store.getters.selectedVue;
            },
            listAreaButton() {
                if (this.selectedVue == 'list') {
                    return 'btn-outline-secondary disabled';
                }
                return 'btn-outline-primary';
            },
            createAreaButton() {
                if (this.selectedVue == 'create') {
                    return 'btn-outline-secondary disabled';
                }
                return 'btn-outline-primary';
            },
            hasEditGroupNameError() {
                return this.editGroupNameError.length > 0;
            },
        },
        methods: {
            editGroupName()
            {
                if (this.$store.getters.hasLinkableAreas == false
                    || this.parentArea.group_item_count == 0) {
                    this.selectVue('editEmptyArea', this.parentArea);
                    this.$store.commit('updateMessages', {messages: {}});
                    return;
                }
                this.groupName = this.$store.getters.areaData.area_name_full;
                this.editingGroupName = true;
            },
            selectVue(name, area) {
                this.$store.commit('updateSelectedVue', {
                    selectedVue: name, 
                    areaData: area
                })
            },
            saveEditedGroupName()
            {
                this.editGroupNameError = '';
                if (this.groupName == this.parentArea.area_name_full) {
                    this.editGroupNameError = '';
                    this.editingGroupName = false;
                    return;
                }
                if (this.groupName.length < 5) {
                    this.editGroupNameError = 'The new name must be at least four characters long';
                    return;
                }
                
                this.updateGroupName();
            },
            cancelEditGroupName()
            {
                this.groupName == this.parentArea.area_name_full;
                this.editGroupNameError = '';
                this.editingGroupName = false;
            },
            updateGroupName() {
                let url = '/areas/update-user-area-name';
                axios.get(url, {
                    params: {
                        areaId: this.parentArea.id,
                        areaName: this.groupName,
                    }
                })
                .then(response => {
                    if (response.data.status.status_code != 200) {
                        this.editGroupNameError = response.data.status.status_message;
                        return;
                    }
                    else {
                        this.editingGroupName = false;
                        this.$store.commit('updateAreaData', {areaData: response.data.userArea});
                    }
                })
                .catch (error => {
                    this.editingGroupName = false;
                    this.alert = displayAlert(error);
                });

            },            
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('manage-reporting-areas component mounted.')
            }
        }
    }
</script>


