<template>
    <div v-if="displayAlerts" 
        class="navbar navbar-dark navbar-expand-lg toolbar-standalone-alert " 
        :class="backgroundColor"
    >
        <toolbar-alert :messages="toolbarMessages"></toolbar-alert>
    </div>
</template>
<script>
    export default {
        props: {
            alerts: Object,
        },
        data() {
            return {
            }
        },
        created() {
        },
        computed: {
            toolbarMessages() {
                return this.alerts;
            },
            displayAlerts() {
                return  this.hasAlerts;
            },
            hasAlerts() {
                if (this.alerts === null) {
                    return false;
                }
                return (typeof this.alerts !== 'undefined') && Object.keys(this.alerts).length !== 0;
            },
            backgroundColor() {
                if (this.alerts === null || Object.keys(this.alerts) == 0) {
                    return '';
                }

                let msgKey = Object.keys(this.alerts)[0];
                const arr = (msgKey.indexOf('_') == -1 ? msgKey : msgKey.split('_'));
                if (Array.isArray(arr)) {
                    return 'toolbar-alert-' + arr[0];
                }
                return 'toolbar-alert-' + arr;
            },
        },
        methods: {
            methodTemplate() {
                return;
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('toolbar component mounted.')
            }
        }
    }
</script>
