<template>
    <div v-if="displayToolbar" 
        class="navbar navbar-dark navbar-expand-lg toolbar-report d-flex">
        <div v-if="showAddAreaToggle" class="flex-fill w-33">
            <portfolio-area-toggle  
                :fullAreaId="fullAreaId" 
                :isAreaInPortfolio="isAreaInPortfolio" 
            ></portfolio-area-toggle>
        </div>
        <div v-else  class="flex-fill w-33">
            <toolbar-alert :messages="messages" ></toolbar-alert>
        </div>
        <div v-if="hasSearchBox"  class="flex-fill w-33">
            <area-search-dropdown :user="user"></area-search-dropdown>
            
            <!-- <component :is="searchBoxComponent" :searchBox="searchBox" :user="user"></component> -->
        </div>
        <div class="flex-fill w-15">
            <toolbar-paginator v-if="hasPaginator" :paginator="paginator"></toolbar-paginator>
        </div>
        <div class="flex-fill w-15">
            <toolbar-tools v-if="hasToolbar === true" :tools="tools"></toolbar-tools>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            portfolioAreaToggle: {
                type: Object,
                required: false,
            },
            user: {
                type: Object,
                required: false,
            },
            paginator: {
                type: Object,
                required: false,
            },
            searchBox: {
                type: Object,
                required: false,
            },
            messages: {
                type: Object | Array,
                required: false,
            },
            tools: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
            }
        },
        created() {
        },
        computed: {
            displayToolbar() {
                return this.hasToolbar || this.hasAlerts || this.hasPaginator || this.hasSearchBox;
            },
            hasToolbar() {
                if (this.tools === undefined) {
                    return false;
                }
                return this.tools.hasToolbar && this.tools.tools && this.tools.tools.length !== 0;
            },
            hasSearchBox() {
                if (this.searchBox === undefined) {
                    return false;
                }
                return this.searchBox && this.searchBox.vue_component !== undefined;
            },
            hasPaginator() {
                if (this.paginator === undefined) {
                    return false;
                }
                return this.paginator.paginate && this.paginator.length !== 0;
            },
            hasAlerts() {
                if (this.messages === undefined) {
                    return false;
                }
                if (typeof this.messages === 'object') {
                    return Object.keys(this.messages).length !== 0;
                }
                if (typeof this.messages === 'array') {
                    return this.messages.length !== 0;
                }
                return false;
            },
            hasAddPortfolioAreaToggle() {
                return this.portfolioAreaToggle !== undefined
            },
            showAddAreaToggle() {
                return ((false === this.hasAlerts) && this.hasAddPortfolioAreaToggle);
            },
            fullAreaId() {
                if (this.hasAddPortfolioAreaToggle) {
                    return this.portfolioAreaToggle.fullAreaId;
                }
                return '';
            },
            isAreaInPortfolio() {
                if (this.hasAddPortfolioAreaToggle) {
                    return this.portfolioAreaToggle.isAreaInPortfolio;
                }
                return false;
            },
            searchBoxComponent() {
                if (!this.hasSearchBox) {
                    return '';
                }

                return this.searchBox.vue_component;
            }  

        },
        methods: {
            methodTemplate() {
                return;
            },
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('toolbar component mounted.')
            }
        }
    }
</script>


