<template>
    <div class="row">
        <div class="offset-2 col-8">
            <div class="pricing-card shadow border-secondary h-100">
                <div v-if="isOnTrial == true" class="card-body">
                    <div class="top-half">
                        <h1>Sample Analytic Reports</h1>
                        <div class="price-quote-no-border">
                            <div class="row price-block">
                                <div class="col-10 offset-1 text-left">
                                    <h4>Analytic reports are provided for random areas during a free trial.  You will be viewing data for <strong>{{trial_area_name}}</strong></h4>
                                </div>
                            </div>
                            <div class="row price-block">
                                <div class="col-10 offset-1 text-left">
                                    <h6>Professional plan subscribers may purchase and view analytic reports for an area for {{areaReportsLength}} {{areaReportsInterval}}s.  Enterprise plan subscribers receive full access to analytic reports as part of the subscription plan.</h6>
                                </div>
                            </div>
                            <div class="row price-block">
                                <div class="col-4 offset-4">
                                    <button @click="viewTrialReport" class="btn btn-success btn-lg btn-block"
                                    >
                                        <span v-if="processing">
                                            <i class="fal fa-lg fa-spinner"></i>
                                        </span>
                                        <span v-else>Continue</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else class="card-body">
                    <div class="top-half">
                        <h1>Purchase Analytic Reports For</h1>
                        <h1>{{fullAreaName}}</h1>
                        <div class="price-quote">
                            <div class="row price-block">
                                <div class="col-5 offset-2 text-left">
                                    <h4>{{areaReportsLength}}-{{areaReportsInterval}} Access</h4>
                                </div>
                                <div class="col-3 text-right">
                                    <h4>{{areaReportsPrice}}</h4>
                                </div>
                            </div>
                            <div class="row price-block">
                                <div class="col-12 text-left">
                                    <h6><span v-if="statusMessage">{{statusMessage}}<br/></span>
                                    Analytic reports are available for this area for {{areaReportsLength}} {{areaReportsIntervalPlural}}.</h6>
                                    <h6 v-if="hasParentArea">You will also have access to analytic reports for the parent area and other sub-areas belonging to this area.</h6>
                                    <h6 v-if="hasSubAreas">You will also have access to analytic reports for the sub-areas of this area.</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="bottom-half billing-plan row">
                        <div id="cc-instructions">
                            <toolbar-alert 
                                v-if="hasAlert" 
                                :messages="alert" 
                                :standalone=true  
                            ></toolbar-alert>
                            <h5>Please enter your payment details. Your card will be charged {{areaReportsPrice}}.</h5>
                            <h5>Access will expire at the end of {{areaReportsLength}} {{areaReportsIntervalPlural}}.  You may renew it at any time prior to the end of the period.</h5>
                            <h5 class="pad-top">Have a billing code or note to identify this purchase? Enter it below.</h5>
                            <textarea 
                                v-model="message" 
                                class="col-12"
                                placeholder="Information will appear on the invoice"
                            ></textarea>
                            <stripe-element-card
                                ref="cardElement"
                                :pk="stripe_key"
                                :testMode="useTestMode"
                                @token="tokenCreated"
                            ></stripe-element-card>
                            <div class="row">
                                <div class="col-12">
                                <button @click="submit" class="btn btn-success btn-lg btn-block"
                                    data-secret="intent.client_secret"
                                >
                                    <span v-if="processing">
                                        <i class="fal fa-lg fa-spinner"></i>
                                    </span>
                                    <span v-else>Purchase</span>
                                </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {handleError} from '../../mixins/handle-error'
    // import { StripeElementCard } from '@vue-stripe/vue-stripe';
    import { loadStripe } from '@stripe/stripe-js';
    export default {
        props: {
            data: {Object, required: true},
        },
        components: {
            loadStripe,
        },
        data() {
            return {
                alert: Object(),
                customer: this.data.customer,
                errorMsg: '',
                intent: this.data.intent,
                isOnTrial: this.data.user.isOnTrial,
                message: '',
                paymentMethodId: '',
                plan: this.data.plan,
                price: 0,
                price_id: this.data.products.items.area_reports.price.stripe_id,
                processing: false,
                products: this.data.products,
                reports: this.data.products.items,
                setup_intent: null,
                showPriceDetail: false,
                slug_request: this.data.slug_request,
                statusMessage: this.data.status,
                stripe: Stripe(process.env.MIX_STRIPE_KEY),
                stripe_key: process.env.MIX_STRIPE_KEY,
                trial_area_id: this.data.trial_area_id,
                trial_area_name: this.data.trial_area_name,
                trial_pareaid: '0' + this.data.trial_area, 
                user: this.data.user,
            }
        },
        created() {
        },
        computed: {
            hasAlert() {
                return Object.keys(this.alert).length > 0;
            },
            reportItem() {
                return this.reports.area_reports;
            },
            reportTrial() {
                return this.reports.area_reports_trial;
            },
            reportType() {
                return this.reportItem.display_name;
            },
            reportPriceID() {
                return this.reportItem.price.stripe_id;
            },
            reportTrialPriceID() {
                return this.reportTrial.price.stripe_id;
            },
            reportPrice() {
                this.price = this.reportItem.price.amount;
                this.price_id = this.reportPriceID;
                return this.formatCurrency(this.price);
            },
            areaReportsPrice() {
                return this.formatCurrency(this.reportItem.price.amount);
            },
            areaReportsLength() {
                return this.reportItem.price.interval_count;
            },
            areaReportsInterval() {
                return this.reportItem.price.interval;
            },
            areaReportsIntervalPlural() {
                return this.areaReportsInterval + (this.areaReportsLength == 1 ? '' : 's');
            },
            areaReportsLookupKey() {
                return this.reportItem.price.lookup_key;
            },
            areaReportsTrialLookupKey() {
                return this.reportTrial.price.lookup_key;
            },
            reportPrices() {
                return this.products.items;
            },
            hasParentArea() {
                return this.user.parentAreaId != 0;
            },
            hasSubAreas() {
                return this.user.hasSubAreas;
            },
            areaID() {
                if (this.user.parentAreaId != 0) {
                    return this.user.parentAreaId;
                }
                return this.user.areaId;
            },
            fullAreaName() {
                if (this.user.parentAreaName.length == 0) {
                    return this.user.areaName + " (" + this.user.countyName + " County, " + this.user.stateAbbr + ")";
                }
                return this.user.parentAreaName + " (" + this.user.countyName + " County, " + this.user.stateAbbr + ")";
            },
            useTestMode() {
                return this.data.environment == 'local';
            },
        },
        methods: {
            displayAlert(error) {
                this.alert = handleError({
                    status: (error.status ? error.status :'warning'), 
                    message: 'Unable to create a new area subscription. '
                        + (error.message ? error.message : ''), 
                    detail: ((error.response 
                            && error.response.error 
                            && error.response.error.message) 
                        ? error.response.error.message : ''),
                    key: (error.key ? error.key : 'urbicsError'),
                    response: error.response,
                    request: error.request,
                });
            },
            formatCurrency(value) {
                return '$' + Number(value/100).toFixed(0).toLocaleString();
            },
            togglePriceDetail() {
                this.showPriceDetail = !this.showPriceDetail;
            },
            redirectToPage(slug) {
                let url = (slug == 'home' ? '/home' : '/reports/' + slug);
                let err = (this.errorMsg != '' ? '?error=' + this.errorMsg : '');
                window.location.replace(url + err);
            },
            submit() {
                this.processing = true;
                this.$refs.cardElement.submit();
                this.processing = false;
            },
            tokenCreated (token) {
                this.processing = true;
                let result = this.stripe.createPaymentMethod({
                    type: 'card',
                    card: {token: token.id},
                    billing_details: {
                        name: this.customer.name,
                    },
                })
                .then((result) => {
                    if (result.error) {
                        this.processing = false;
                        this.displayAlert({
                            message: result.error.message,
                        });
                    } else {
                        this.paymentMethodId = result.paymentMethod.id;
                        this.createSubscription();
                    }
                })
                .catch(error => {
                    this.processing = false;
                    this.displayAlert({
                        message: error.message,
                    });
                })
            },
            createSubscription() {
                this.processing = true;
                axios.post('/subscription/create-area-report-access', {
                    lookupKey: this.areaReportsLookupKey,
                    message: this.message,
                    areaText: this.fullAreaName,
                    areaId: this.areaID,
                    custId: this.customer.id,
                    paymentMethodId: this.paymentMethodId,
                    priceId: this.reportPriceID
                })
                .then(response => {
                    this.processing = false;
                    if (response.data.status_code == 200) {
                        this.redirectToPage(this.slug_request);
                        return;
                    }
                    this.displayAlert({
                        message: response.data.status_message,
                    });
                })
                .catch(error => {
                    this.processing = false;
                    this.displayAlert({
                        message: error.message,
                    });
                });
            },
            cancel() {
                this.redirectToPage('home');
            },
            viewTrialReport() {
                this.processing = true;
                axios.post('/subscription/create_trial_area_access', {
                    areaId: this.trial_area_id,
                    lookupKey: this.areaReportsTrialLookupKey,
                    custId: this.customer.id,
                    priceId: this.reportTrialPriceID
                })
                .then(response => {
                    this.processing = false;
                    this.redirectToPage(this.slug_request);
                })
                .catch(error => {
                    this.processing = false;
                    this.displayAlert({
                        message: error.message,
                    });
                });
            }
        },
        watch: {
            watchTemplate() {
            	return null;
            }
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>