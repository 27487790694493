<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12">
                <div class="card card-base">
                    <div class="card-header">
                        <div class="settings-container">
                            <div class="row">
                                <div class="col-8 text-md-left">Your Current Area is:</div>
                                <div v-if="isAdmin" class="col-4 text-md-right">
                                    <a :href="currentAreaUrl">
                                        <i title="Manage Area" class="fal fa-cog fa-sm"></i>
                                    </a>
                                </div>
                            </div>
                            <div class="row" v-if="hasParentArea">
                                <div class="col-12 text-md-center">{{ parentAreaName }}</div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-md-center">{{ areaName }}</div>
                            </div>
                            <div class="row">
                                <div class="col-12 text-md-center">({{ fullCountyName }})</div>
                            </div>
                            <div class="row justify-content-evenly card-item">
                                <div class="offset-1 col-10 text-md-center">
                                    <em>Include this area in my portfolio</em>
                                    <a class="pad-left align-middle">
                                    <i id="addAreaToggleIcon" 
                                        v-bind:class="addAreaIcon" 
                                        v-on:click="toggleAddArea"
                                    ></i>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="settings-container">
                            <div class="row justify-content-evenly card-item">
                                <div class="col-12 text-md-left">
                                    Summary Data For This Area:
                                </div>
                            </div>
                            <div v-if="fetchingData" class="row">
                                <div class="col-12 text-md-center">
                                    <i id="fetchingDataSpinner" 
                                        class="shim-y-2 fal fa-2x fa-circle-notch fa-spin text-info" 
                                    ></i>
                                </div>
                            </div>
                            <div v-else class="row">
                                <div class="table-responsive">
                                    <table cellspacing="0" cellpadding="0" width="100%" class="table report-table">
                                        <tbody>
                                            <tr
                                                v-for="(item, index) in summaryData" 
                                            >
                                                <td v-if="item.type == 'percent'"
                                                    class="item-label-em pad-left-triple">
                                                    {{ item.label }}
                                                </td>
                                                <td v-else class="item-label pad-left-more">
                                                    {{ item.label }}
                                                </td>
                                                <td v-if="item.type == 'percent'"
                                                    class="item-label-em pad-right">
                                                    {{ item.value }}
                                                </td>
                                                <td v-else class="item-label pad-right">
                                                    {{ item.value }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: 'AreaSummary',
        props: {
            user: { Object, required: true },
        },
        data: function() {
            return {
                areaName: this.user.areaName,
                areaId: 0,
                parentAreaName: this.user.parentAreaName,
                fullCountyName: this.user.fullCountyName,
                isAdmin: this.user.isAdmin,
                addingArea: false,
                isAreaInPortfolio: this.user.areaInPortfolio,
                summaryData: [],
                fetchingData: true,
                testData: [],
            }
        },
        computed: {
            hasParentArea() {
                return (this.user.parentAreaName.length > 0 
                    && this.user.parentAreaName != this.user.areaName);
            },
            currentAreaUrl() {
                return "/admin/areas/edit/" + this.user.areaId;
            },
            fullAreaID() {
                return this.user.parentAreaId + '_' + this.user.areaId;
            },
            addAreaIcon() {
                return (this.addingArea ? "fal fa-spinner fa-spin fa-lg"
                        : (this.isAreaInPortfolio ? "fal fa-toggle-on fa-lg text-success" 
                        : "fal fa-toggle-off fa-lg text-success"));
            },
            dataArray() {
                return this.summaryData[0];
            }
        },
        methods: {
            toggleAddArea() {
                const url = '/areas/' + (this.isAreaInPortfolio ? 'remove-from' : 'add-to') + '-portfolio/' + this.fullAreaID;
                var self = this;
                axios.post(url)
                .then(response => {
                    self.isAreaInPortfolio = (!self.isAreaInPortfolio);
                })
                .catch (response => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        this.errorMessage += ' The error message is: ' + response.data;
                    }
                })
            },
            updateSummaryData() {
                this.fetchingData = true;
                axios.get('/reports/summary/raw', {
                })
                .then(response => {
                    if (response.data.length == 0) {
                        this.summaryData = [];
                    }
                    else {
                        this.summaryData = this.extractSummaryData(response.data[0]);
                    }
                    this.fetchingData = false;
                })
                .catch (error => {
                    this.errorMessage = 'An error occurred while processing this page.';
                    if (process.env.NODE_ENV !== 'production') {
                        if (error !== 'undefined') {
                            this.errorMessage += ' The error message is: ' 
                                + error;
                        }
                        console.log(this.errorMessage);
                    }
                    this.fetchingData = false;
                })
            },
            extractSummaryData(items) {
                let result = {};
                let labels = {
                    'current_net_value': {
                        label: 'Net Taxable Value, FY' + this.user.fiscalYear,
                        type: '',
                    },
                    'percent_change': {
                        label: 'Percent Change Over Prior Year',
                        type: 'percent',
                    },
                    'largest_owner_name': {
                        label: 'Largest Owner by Value',
                        type: '',
                    },
                    'owner_share_value':  {
                        label: 'Share of Net Taxable Value',
                        type: 'percent',
                    },
                    'primary_land_use_classification': {
                        label: 'Primary Land Use',
                        type: '',
                    },
                    'land_use_share_value': {
                        label: 'Share of Net Taxable Value',
                        type: 'percent',
                    }
                };
                items.forEach(item => {
                    if (item.meta.field_name in labels) {
                        result[item.meta.field_name] = {
                            label: labels[item.meta.field_name].label,
                            type: labels[item.meta.field_name].type,
                            value: item.data[item.meta.field_name]
                        };
                    }
                });

                return result;
            },
        },
        mounted() {
            this.areaId = this.user.areaId;
            this.updateSummaryData();
            if (process.env.NODE_ENV !== 'production') {            
                console.log('Component mounted.')
            }
        }
    }
</script>
