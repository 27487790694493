<template>
    <div>
        <div class="row">
            <area-quick-search 
                :user="user"
            ></area-quick-search>
        </div>
        <div class="row shim-top">
            <div class="form-group col-4 quick-search">
                <a href="/areas/select-area" name="btn_area_search" class="btn btn-success btn-lg">Advanced</a>
            </div>
            <areas-recent-related
                :user="user"
            ></areas-recent-related>
            <areas-portfolio
                :user="user"
            ></areas-portfolio>
        </div>
        <div class="row quick-search">
            <area-summary
                :user="user"
            >
        </area-summary>
        </div>
    </div>
</template>
<script>
    import AreaSummary from './area-summary';
    import AreaQuickSearch from './area-quick-search';
    import AreasRecentRelated from './areas-recent-related';
    export default {
        name: 'AreaSelectionTools',
        props: {
            user: { Object, required: true },
        },
        components: {
            AreaSummary,
            AreaQuickSearch,
            AreasRecentRelated,
        },

        data: function() {
            return {
            }
        },
    }
</script>


