<template>
    <i 
        aria-hidden="true" 
        aria-label="values.label" 
        :class="[iconSize, iconName, iconColor]"
        @click="toggleField"
    ></i>
</template>
<script>
    import {mapGetters, mapMutations} from 'vuex';

    export default {
        props: {values: Object},
        data() {
            return {
                processing: false,
                available: 0,
                reportID: 0,
                userID: 0,
            }
        },
        created() {
            this.available = this.values.available;
            this.reportID = this.values.report_id;
            this.userID = this.values.user_id;
            this.addReportForUser({
                modelID: this.values.model_id, 
                reportID: this.values.report_id,
                userID: this.values.user_id,
                visible: this.values.visible,
                available: this.values.available
            })
        },
        watch: {
        },
        methods: {
            toggleField: function () {
                if (this.processing == true) {
                    return;
                }
                if (this.available == -1) {
                    return;
                }
                var self = this;
                var url = (this.modelID == 0) ? this.insertUrl : this.deleteUrl;
                this.processing = true;
                axios.post(url, {
                    model_id: this.modelID,
                    user_id: this.userID,
                    report_id: this.reportID,
                    visible: 1
                })
                .then(function (response) {
                    if (self.modelID == 0) {
                        self.updateReport({
                            modelID: response.data.model_id,
                            reportID: response.data.report_id, 
                            userID: response.data.user_id, 
                            visible: response.data.visible,
                            available: 1
                        });
                    }
                    else {
                        self.updateReport({
                            modelID: 0,
                            reportID: self.reportID, 
                            userID: self.userID,
                            visible: -1,
                            available: 0
                        });
                    }
                })
                .catch(function (error) {
                    if (process.env.NODE_ENV !== 'production') {            
                        console.log(error.response.data)
                    }
                })
                .finally( function (response) {
                    self.processing = false;
                    self.available = self.$store.getters.reportAvailable(self.values.report_id)
                });                
            },
            ...mapMutations(['updateReport', 'addReportForUser']),
        },
        computed: {
            iconSize: function () {
                return this.values.icon_size;
            },
            iconColor: function () {
                if(this.available !== -1) {
                    return 'text-success';
                }
                return 'text-secondary';
            },
            iconName: function () {
                if (this.processing == true) {
                    return 'fal fa-circle-notch fa-spin';
                }
                if (this.available === 1) {
                    return 'fal fa-toggle-on';
                }
                if (this.available === -1) {
                    return 'fal fa-minus'
                }
                return 'fal fa-toggle-off';
            },
            modelID: function () {
                return this.$store.getters.reportModelID(this.values.report_id)
            },
            insertUrl: function () {
                return this.values.route_insert
            },
            deleteUrl: function () {
                return this.values.route_delete + '/' + this.modelID
            },
        },
        mounted() {
            // if (process.env.NODE_ENV !== 'production') {            
            //     console.log('Component mounted.')
            // }
        }
    }
</script>
