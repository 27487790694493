// batchCheckbox store

export default {
	state: {
		selectAll: false,
		children: 0,
		checkedChildren: 0,
	},

	getters: {
	    selectAllChecked(state) {
			return state.selectAll;
	    },
	    someChildrenChecked(state) {
	    	return ((state.checkedChildren != 0) && (state.checkedChildren != state.children));
	    },
	    allChildrenChecked(state) {
	    	return (state.checkedChildren == state.children);
	    },
	    noChildrenChecked(state) {
	    	return (state.checkedChildren == 0);
	    }
	},

	actions: {

	},

	mutations: {
		toggleSelectAll(state, payload) {
			state.selectAll = payload.checked;
		},
		childAdded(state, payload) {
			state.children = state.children + 1;
		},	
		childrenChecked(state, payload) {
			state.checkedChildren = state.checkedChildren + (payload.checked ? 1 : -1);
		}
	},
}
