<!-- Notifications Modal -->
<template>
    <div>
        <div class="modal docked docked-right" style="display: block" id="modal-notifications" tabindex="-1" role="dialog">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header text-center">
                      <span class="group-header">Notifications</span>
                    </div>

                    <div class="modal-body">
                        <!-- Informational Messages -->
                        <div class="notification-container" v-if="loadingNotifications">
                            <div><i class="fa fa-btn fa-spinner fa-spin"></i> Loading Notifications</div>
                        </div>

                        <div class="notification-container" v-if=" ! loadingNotifications && notifications.length == 0">
                            <div class="alert alert-warning">
                                We don\'t have anything to show you right now! But when we do, we\'ll be sure to let you know. Talk to you soon!
                            </div>
                        </div>

                        <!-- List Of Notifications -->
                        <div class="notification-container" v-if="showingNotifications && hasNotifications">
                            <div class="notification" v-for="notification in notifications">

                                <!-- Notification Icon -->
                                <figure>
                                    <img src="/img/urbics_icon.png" class="" alt="Urbics" />
                                </figure>

                                <!-- Notification -->
                                <div class="notification-content">
                                    <div class="meta">
                                        <p class="title">
                                            <span v-if="notification.data.from">
                                                {{ notification.data.from.name }}
                                            </span>

                                            <span v-else>
                                                Urbics
                                            </span>
                                        </p>

                                        <div class="date">
                                            {{ notification.data.created_at | relative }}
                                        </div>
                                    </div>

                                    <div class="notification-body">
                                      {{ notification.data.body }}
                                    </div>

                                    <!-- Notification Action -->
                                    <a :href="notification.data.action_url" class="btn btn-primary" v-if="notification.data.action_text">
                                        {{ notification.data.action_text }}
                                    </a>

                                </div>
                            </div>
                        </div>

                    </div>

                    <!-- Modal Actions -->
                    <div class="modal-footer">
                        <button type="button" class="btn btn-default" @click="markAsRead" data-dismiss="modal">Close</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    props: {
      user: Object,
    },
    data: function() {
      return {
          displayNotifications: true,
          fetchingNotifications: false,
          unreadNotifications: this.user.unreadNotifications,
          notifications: [],
      }
    },
    created() {
      this.notifications = this.user.notifications;
    },
    computed: {
      showingNotifications() {
        return this.displayNotifications;
      },
      loadingNotifications() {
        return this.fetchingNotifications;
      },
      activeNotifications() {
        return this.notifications;
      },
      showNotifications() {
        return true;
      },
      hasNotifications() {        
        return this.unreadNotifications != 0;
      }
    },
    methods: {
      markAsRead() {
            axios.put('/notifications/read', {
                userId: this.user.userId,
            })
            .then(response => {
            })
            .catch (response => {
                this.errorMessage = 'An error occurred while processing this page.';
                if (process.env.NODE_ENV !== 'production') {
                    this.errorMessage += ' The error message is: ' + response.data;
                }
            });
      }
    },
    mounted() {
      if (process.env.NODE_ENV !== 'production') {            
          console.log('Component mounted.')
      }
    }
  }
</script>
