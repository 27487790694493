<template>
    <h5>Thanks for subscribing to Urbics.  You will be directed to our main page momentarily.
    </h5>
</template>
<script>
    export default {
        props: {
            data: {Object, required: true},
        },
        data() {
            return {
            }
        },
        components: {
        },
        created() {
        },
        computed: {
        },
        methods: {
        },
        mounted() {
            if (process.env.NODE_ENV !== 'production') {            
                console.log('payment-success component mounted.')
            }
        }
    }
</script>
